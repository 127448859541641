import React from 'react';
import PropTypes from 'prop-types';

import { Clock } from '../../utils';
import { ga } from '../../ga';

import PreviewIcon from './preview-icon.jsx';

class Highlights extends React.Component {
  render() {
    const { highlights } = this.props;

    if (highlights && highlights.length) {
      return (
        <div className="boxcast-highlights">
          {this.props.title === null ? <span /> : <h4>{this.props.title || 'Highlights'}</h4>}
          <ul>{highlights.map((h) => this.renderHighlight(h))}</ul>
        </div>
      );
    } else {
      return <span></span>;
    }
  }

  renderHighlight(highlight) {
    const { CurrentChannelStore, HighlightListStore, SelectedBroadcastStore } = this.context.model;
    let channelId = CurrentChannelStore.id || HighlightListStore.channelId;
    let selected = SelectedBroadcastStore.selectedHighlight;
    let className = 'boxcast-selectable';
    if (highlight === selected) className += ' boxcast-selected';
    //const highlightUrl = `https://boxcast.tv/highlights/${channelId}/${highlight.broadcast_id}/${highlight.id}`;
    return (
      <li key={highlight.id} className={className}>
        <PreviewIcon
          image={highlight.preview}
          duration={Clock.durationFormatted(highlight.duration)}
          onClick={(e) => this.playHighlight(e, highlight)}
        />
        <h5>
          <a href="#" onClick={(e) => this.playHighlight(e, highlight)}>
            {highlight.name}
          </a>
        </h5>
        <p>({highlight.duration} sec)</p>
      </li>
    );
  }

  playHighlight(e, highlight) {
    const { Actions } = this.context.model;
    e.preventDefault();
    Actions.PlayHighlight(highlight);
    ga('send', 'event', 'highlight', 'playHighlight');
  }
}

Highlights.contextTypes = {
  model: PropTypes.object,
};

export default Highlights;
