import React from 'react';

export default function Paginator(props) {
  const { pageCount, currentPage, onChangePage } = props;

  if (pageCount <= 1) {
    return null;
  }

  return (
    <div className="boxcast-page-controls">
      <a
        href="#"
        disabled={currentPage == 1}
        onClick={(e) => {
          e.preventDefault();
          onChangePage(currentPage - 1);
        }}
      >
        Previous Page
      </a>
      <span>
        Page {currentPage} of {pageCount}
      </span>
      <a
        href="#"
        disabled={currentPage == pageCount}
        onClick={(e) => {
          e.preventDefault();
          onChangePage(currentPage + 1);
        }}
      >
        Next Page
      </a>
    </div>
  );
}
