// The PasswordBlock store deals with password-protected broadcasts.
import Reflux from 'reflux';
import { BrowserStorage, Util } from '../utils';
const sha512 = require('js-sha512');

export var PasswordBlockStoreFactory = (Actions, UserArgsStore, CurrentChannelStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.cryptblock = UserArgsStore.cryptblock || {};
      this.isRestricted = false;
      this.isAttemptInvalid = false;
      this.lastPassword = this.cryptblock.userPass;
      this._check();
    },

    // Action bindings
    onPasswordBlockUnlock: function (password) {
      this.lastPassword = password;
      this._check();
      this.isAttemptInvalid = this.isRestricted; //only set invalid flag on explicit attempt
    },

    onContentSettingsChanged: function (newArgs) {
      var newCryptblock = newArgs.cryptblock || {};
      if (newCryptblock == {} && this.cryptblock != {}) {
        // Switching to no password required from existing password configuration
        this.cryptblock = {};
        this.isRestricted = false;
        this.isAttemptInvalid = false;
        this.trigger();
      } else if (newCryptblock) {
        // Enabling password protection
        if (Util.areAnyChanged(this.cryptblock, newCryptblock)) {
          this.cryptblock = newCryptblock;
          this._check();
        }
      }
    },

    // Private methods
    _check: function () {
      if (Util.areAnyChanged({}, this.cryptblock) && this.cryptblock.crypt) {
        this.isRestricted = !this._isAllowed(
          this.lastPassword,
          this.cryptblock.crypt,
          this.cryptblock.salt
        );
        this.trigger();
        if (this.isRestricted && CurrentChannelStore.callbacks.onCryptBlockRestricted) {
          // Call this outside our stack to avoid client exceptions bubbling into here
          setTimeout(() => CurrentChannelStore.callbacks.onCryptBlockRestricted(), 0);
        }
      } else {
        this.isRestricted = false;
        this.trigger();
      }
    },

    _isAllowed: function (password, crypt, salt) {
      var digest = (password || '') + (salt || '');
      for (var i = 0; i < 20; i++) {
        digest = sha512(digest);
      }
      return digest == crypt;
    },
  });
