import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import SocialButtons from '../library/social-buttons.jsx';

var HighlightEndedOverlay = createClass({
  displayName: 'HighlightEndedOverlay',

  contextTypes: {
    model: PropTypes.object,
  },

  render: function () {
    const { showSocialShare, broadcast, channelId } = this.props;
    const { Actions } = this.context.model;
    return (
      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <div style={{ background: 'rgba(0,0,0,0.8)', padding: '15px' }}>
          {showSocialShare ? (
            <div style={{ marginBottom: '10px' }}>
              <SocialButtons />
            </div>
          ) : (
            <span />
          )}
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '70px',
                marginRight: '10px',
                height: '40px',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                backgroundImage: `url("${broadcast.preview}")`,
              }}
            ></div>
            <div style={{ flex: 1 }}>
              <p style={{ color: '#ccc' }}>
                <em>From {broadcast.name}</em>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default HighlightEndedOverlay;
