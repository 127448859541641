import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import { ga } from '../../ga';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';
import BroadcastList from './broadcast-list.jsx';
import Paginator from './paginator.jsx';

export default createClass({
  displayName: 'Playlist',

  contextTypes: {
    model: PropTypes.object,
    m: PropTypes.func,
    env: PropTypes.func,
    userArgs: PropTypes.object,
  },

  refluxModelListeners: {
    SelectedBroadcastStore: 'onStoreChanged',
    PlaylistStore: 'onStoreChanged',
  },

  mixins: [RefluxModelListenerMixin],

  getInitialState: function () {
    const { PlaylistStore, SelectedBroadcastStore } = this.context.model;
    return {
      broadcasts: PlaylistStore.broadcasts,
      selectedBroadcast: SelectedBroadcastStore.broadcast,
      filter: PlaylistStore.filter,
      currentPage: PlaylistStore.currentPage,
      pageCount: PlaylistStore.pageCount(),
      pagination: PlaylistStore.pagination,
      loading: PlaylistStore.loading,
      pageSize: PlaylistStore.pageSize,
    };
  },

  onStoreChanged: function () {
    this.setState(this.getInitialState());
  },

  applyFilter: function () {
    const { Actions } = this.context.model;
    Actions.PlaylistSearch(this.refs.search.value.toLowerCase());
  },

  paginate: function (page) {
    const { Actions } = this.context.model;
    Actions.PlaylistPaginate(page);
  },

  render: function () {
    const title = this.context.m('related_title');
    const { userArgs, defaultPoster } = this.props;

    return (
      <div className="boxcast-playlist boxcast-well">
        <div className="boxcast-well-title">
          <div style={{ float: 'left' }}>
            <h3>{title}</h3>
          </div>
          {this.state.filter || this.state.pageCount > 1 ? (
            <div style={{ float: 'right' }}>{this.renderSearchForm()}</div>
          ) : null}
          <br style={{ clear: 'both' }} />
        </div>
        <BroadcastList
          loading={this.state.loading}
          broadcasts={this.state.broadcasts}
          pageSize={this.state.pageSize}
          selectedBroadcast={this.state.selectedBroadcast}
          onSelect={(broadcast, autoplay) => this.changeVideo(broadcast, autoplay)}
          defaultPoster={defaultPoster}
        />
        <Paginator
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          onChangePage={(p) => this.paginate(p)}
        />
      </div>
    );
  },

  renderSearchForm: function () {
    return (
      <div className="boxcast-playlist-search">
        <input
          ref="search"
          type="text"
          placeholder="search..."
          aria-label="Search Playlist"
          onBlur={() => this.applyFilter()}
          onKeyDown={(evt) => evt.keyCode == 13 && this.applyFilter()}
        />
      </div>
    );
  },

  changeVideo: function (broadcast, autoplay) {
    const { Actions } = this.context.model;
    Actions.SelectBroadcast(broadcast, {
      autoplay: autoplay,
      fromPlaylist: true,
    });
    ga('send', 'event', 'playlist', 'changeVideo');
  },
});
