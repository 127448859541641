// The Player React component wraps our interactions with clappr.js video playback library,
// which in turn hosts the hls.js (or flash if necessary) library.
//
// Because Clappr is not natively React-centric and takes control of the DOM within
// it, we need to stop React from trying to do anything by returning false in
// `shouldComponentUpdate`, which tells React to look no deeper.
//
// Usage:
//
//    <Player broadcast={ }
//            broadcastView={ }
//            autoplay={ }
//            loop={ }
//            customPoster={ }
//            />
//
//
import React from 'react';
import Reflux from 'reflux';
import Clappr from '@clappr/player';
import { Poster, DVRControls } from '@clappr/plugins';
import ChromecastPlugin from 'clappr-chromecast-plugin';
import Promise from 'bluebird';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import RefluxModelListenerMixin from '../mixins/reflux-model-listener-mixin';
import Config from '../config';
import { Logger, Util, dom } from '../utils';
import { PlayerAnalytics } from './analytics';
import { Watchdog } from './watchdog';

import { initHlsJsPlayback } from './clappr-helper';

// TODO: combine all sub-plugins inside the custom media control object
import MediaControl from './plugins/media-control-plugin.js';
import ClapprSettingsPlugin from './plugins/clappr-settings-plugin';
import ClosedCaptionsPlugin from './plugins/closed-captions-plugin';
import ScrubThumbnailsPlugin from './plugins/clappr-thumbnail-scrubber-plugin';
import ClapprSocialPlugin from './plugins/clappr-social-plugin';
import WaterMarkPlugin from './plugins/watermark-plugin';
import FullscreenPlaceholderPlugin from './plugins/fullscreen-placeholder-plugin';
import CuePointsPlugin from './plugins/cue-points';
import TapToUnmuteButton from './plugins/tap-to-unmute';

const logger = Logger.getInstance('player');
const MAX_DVR_IDLE_TIME_COMPENSATION = 10; //max number of seconds we should try to rewind
const BOXCAST_CHROMECAST_MESSAGE_NAMESPACE = 'boxcast';
class AccessiblePosterPlugin extends Poster {
  get template() {
    return Clappr.template(
      '<button type="button" aria-label="Play Video" id="boxcast-big-play-button" class="play-wrapper" data-poster></button>'
    );
  }
}
AccessiblePosterPlugin.type = 'container';

export default createClass({
  propTypes: {
    broadcast: PropTypes.object,
    broadcastView: PropTypes.object,
    autoplay: PropTypes.oneOf([true, false, 'try-muted']),
    loop: PropTypes.bool,
  },

  contextTypes: {
    userArgs: PropTypes.object,
    model: PropTypes.object,
    env: PropTypes.func,
  },

  refluxModelListeners: {
    Actions: [
      { on: 'WatchBroadcastAgain', trigger: 'onWatchBroadcastAgain' },
      { on: 'OpenPaymentModal', trigger: 'onOpenPaymentModal' },
      { on: 'SeekTo', trigger: 'onSeekTo' },
      { on: 'ContentSettingsChanged', trigger: 'onContentSettingsChanged' },
    ],
  },

  mixins: [RefluxModelListenerMixin],

  lastPlaylist: null, //keep track of what was played last to assist with state transitions
  //(since broadcastView.playlist could be mutated, we need a copy)

  shouldComponentUpdate: function (nextProps, nextState) {
    // There is the chance that the metadata refreshing from the API would
    // cause a broadcast to change its playlist mid-stream.
    // Since Clappr mutates the DOM (which would freak React out), we need to
    // manually change the player source out and tell React to move right along.
    let samePlaylist = false;
    if (nextProps.broadcastView && this.lastPlaylist) {
      samePlaylist = nextProps.broadcastView.playlist == this.lastPlaylist;
    }

    this.props = nextProps;
    this.state = nextState;
    if (!samePlaylist) {
      logger.log(
        'Switching playlists from ',
        this.lastPlaylist,
        'to',
        (this.props.broadcastView || {}).playlist
      );
      this.changeBroadcast(nextProps.broadcast, nextProps.broadcastView);
    }
    this.lastPlaylist = (this.props.broadcastView || {}).playlist;

    return false;
  },

  componentDidMount: function () {
    logger.log('Player#componentDidMount()', this.props);
    this.changeBroadcast(this.props.broadcast, this.props.broadcastView);
    this.lastPlaylist = (this.props.broadcastView || {}).playlist;
  },

  componentWillUnmount: function () {
    logger.log('Player#componentWillUnmount()');
    this.unmounted = true; //avoid race condition
    if (this.seekTimeout) {
      clearTimeout(this.seekTimeout);
    }
    try {
      this.destroyPlayer();
    } catch (e) {
      // XXX: Firefox raises an error here when changing videos
      // See https://github.com/clappr/clappr/issues/826
      logger.error(e);
    }
  },

  destroyPlayer: function () {
    logger.log('Player#destroyPlayer()');
    const { SelectedBroadcastStore } = this.context.model;
    if (this.player && this.player.destroy) {
      try {
        this.analytics.detach();
      } catch (e) {
        console.error(e);
      }
      try {
        this.watchdog.detach();
      } catch (e) {
        console.error(e);
      }
      try {
        this.player.destroy();
      } catch (e) {
        console.error(e);
      }
    }
    this.player = null;
    SelectedBroadcastStore.playing = false;
  },

  changeBroadcast: function (broadcast, view) {
    const { SelectedBroadcastStore, PlaybackStore } = this.context.model;

    logger.log('Player#changeBroadcast()', broadcast.name, view.playlist);

    if (this.player) {
      this.destroyPlayer();
      this.player = null;
    }

    if (!broadcast || !view || !view.playlist) {
      logger.warn('No broadcast to load, not creating player');
      return;
    }

    var autoplay = this.props.autoplay;
    var isLive = view.status == 'live';
    if (SelectedBroadcastStore.autoplay) {
      autoplay = true;
    } else if (this.props.autoplay === false) {
      autoplay = false; //component prop explicit override should ignore "live" logic
    } else if (isLive) {
      autoplay = true;
    }

    var audioOnly = broadcast.transcoder_profile == 'audio';
    var hideMediaControl = !audioOnly; //don't auto-hide scrubber bar for audio-only broadcasts
    // this was created to serve the recording trimmer UI, where we want to be able to seek all the way to the end of the broadcast, but not show any "Thank You" screen
    var staticMode = !!this.context.userArgs.staticMode;
    if (this.context.userArgs.alwaysShowMediaControl) {
      hideMediaControl = false;
    }
    var plugins = {
      core: [
        ClapprSettingsPlugin,
        MediaControl,
        TapToUnmuteButton,
        ClosedCaptionsPlugin,
        DVRControls,
      ],
      container: [AccessiblePosterPlugin],
    };
    if (!audioOnly && !isLive && !this.context.userArgs.chromeless) {
      // Only show thumbnails on video recordings
      plugins.core.push(ScrubThumbnailsPlugin);
    }
    if (this.context.userArgs.showSocialShare && !broadcast.is_private) {
      plugins.core.push(ClapprSocialPlugin);
    }
    if (this.context.userArgs.plugins && this.context.userArgs.plugins.fullscreenPlaceholder) {
      plugins.core.push(FullscreenPlaceholderPlugin);
    }
    if (this.context.userArgs.clapprPlugins) {
      plugins.core = plugins.core.concat(this.context.userArgs.clapprPlugins);
    }
    if (this.context.model.UserArgsStore.embed_branded || (view.settings || {}).embed_branded) {
      plugins.core.push(WaterMarkPlugin);
    }
    plugins.core.push(CuePointsPlugin);
    var cuePointsPlugin = {
      tooltipBottomMargin: 25,
      cues: (view.settings || {}).cues || [],
    };
    var poster = '';
    // if the broadcast is in the past, attempt to load its post_thumbnail.
    if (broadcast.timeframe === 'past' && (broadcast.content_settings || {}).post_thumbnail) {
      const type = broadcast.content_settings.post_thumbnail.type
      
      if (!broadcast.content_settings.post_thumbnail.url){
        if (type === 'frame'){
          // type frame and no url, use poster
          poster = broadcast.poster
        } else if (type === 'pre'){
          // type pre and no url, use broadcast preview if any, then use broadcast poster 
          poster = broadcast.preview ? broadcast.preview : broadcast.poster;
        }
      }
      else {
        // url exists, use the url
        poster = broadcast.content_settings.post_thumbnail.url 
      }
    } else if (this.props.customPoster) {
      poster = this.props.customPoster;
    } else if (audioOnly) {
      poster = broadcast.preview;
    } else if ((broadcast.content_settings || {}).preview) {
      poster = broadcast.content_settings.preview;
    } else {
      poster = broadcast.poster;
    }
    var options = {
      parent: this.refs.player,
      source: view.playlist,
      width: '100%',
      height: '100%',
      cuePointsPlugin: cuePointsPlugin,
      closedCaptionsConfig: {
        defaultOn: this.context.userArgs.closedCaptionsDefaultOn,
      },
      // If the arg is NOT set to be false, then we want to apply the brand color
      // But make sure there's a brand color set on the account as well
      mediacontrol:
        (this.context.userArgs.applyBrandColor === undefined ||
          this.context.userArgs.applyBrandColor == true) &&
        view.settings &&
        view.settings.color
          ? {
              seekbar: view.settings.color,
              buttons: '#fff',
            }
          : undefined,
      playback: {
        playInline: this.context.userArgs.playInline ? 'true' : undefined, //<-- available in iOS10+
        poster: poster,
        preload: 'metadata',
        hlsjsConfig: {
          // See https://github.com/dailymotion/hls.js/blob/master/API.md
          enableWorker: true,
          manifestLoadingMaxRetry: 50,
          levelLoadingMaxRetry: 50,
          fragLoadingMaxRetry: 50,
          // Make sure we can show the first still frame of video
          autoStartLoad: true,
          startFragPrefetch: true,
          // We used to need a large (~25s) maxBufferHole and maxSeekHole to allow hls.js to
          // jump over a gap in the audio/video track (https://github.com/dailymotion/hls.js/issues/306),
          // but Justin submitted a PR to make sure the tracks got stretched so there were no longer
          // large gaps.
          // Do we still have times (during live playback) where a track gets cut short during a
          // discontinuity and playback stalls, then needs to jump far to play back?
          maxBufferHole: 2.0,
          maxSeekHole: 2.0,
          stretchShortVideoTrack: false, // XXX: We originally built this option to solve a fraglooploading error, but it's since regressed to cause other issues
          // Avoid unnecessarily high levels
          capLevelToPlayerSize: true,
          capLevelOnFPSDrop: true,
          // Avoid loading way too many segments on initial render
          maxBufferLength: 30, //30 seconds default (3 segments)
          maxBufferSize: 3 * 1000 * 1000, //this is *min* # of *bytes* to load. we don't care about that, so keep it low-ish to avoid fetching too many segments
        },
      },
      baseUrl: this.context.env('assetBase'),
      poster: poster,
      chromeless: this.context.userArgs.chromeless,
      //autoPlay: autoplay, //<-- never use Clappr auto-play directly because we need to handle dailymotion/hls.js/issues/586
      loop: this.props.loop,
      audioOnly: audioOnly,
      hideMediaControl: hideMediaControl,
      autoSeekFromUrl: false, //by default this uses a naive regex to look for ?t=100 in url
      disableVideoTagContextMenu: true,
      exitFullscreenOnEnd: true, //needed on mobile to avoid stranding user (e.g. Spectrum webview)
      playbackNotSupportedMessage: '', //handled separately,
      scrubThumbnails: {
        backdropHeight: 190 / 4,
        spotlightHeight: 190 / 2,
        maxThumbs: 72, //1 every 10px on a 720px wide viewport (note: increasing effect load time)
        thumbnailUrlTemplate: broadcast.thumbnail_url_template,
      },
      socialSharing: {
        onShow: () => {
          this.context.model.Actions.ShowShareMenu();
          this.player && this.player.pause();
        },
      },
      fullscreenPlaceholder: (this.context.userArgs.plugins || {}).fullscreenPlaceholder,
      plugins: plugins,
      staticMode,
    };

    if (SelectedBroadcastStore.timeLimitedPreview) {
      logger.log('Time-limited preview. Disabling Chromecast.');
    } else if (!SelectedBroadcastStore.view.playlist) {
      logger.log('View playlist not avaiable. Disabling Chromecast.');
    } else {
      options.plugins.core.push(ChromecastPlugin);
      options.chromecast = {
        appId: this.context.env('chromecastReceiverAppID'),
        customNamespace: BOXCAST_CHROMECAST_MESSAGE_NAMESPACE,
        media: {
          title: broadcast.name || '',
          subtitle: broadcast.description || '',
        },
        customData: {
          type: 'BOXCAST_METADATA',
          data: this.getChromecastMetadata(),
        },
        // poster: 'http://localhost:8080/static/chromecast-playing.png', // <--- for localhost testing...
        poster: `${this.context.env('assetBase')}/chromecast-playing.png`, // <--- for actual deploy...
      };
    }

    if (this.context.userArgs.debug) {
      Clappr.Log.setLevel(Clappr.Log.LEVEL_WARN); // LEVEL_DEBUG has way too much redundant with hlsjs
      options.playback.hlsjsConfig.debug = true;
    }

    if (this.context.userArgs.dvr) {
      if (this.context.userArgs.greedyBuffer) {
        // See https://github.com/dailymotion/hls.js/blob/master/API.md
        logger.warn('Turning on DVR mode hls.js settings');
        options.playback.hlsjsConfig.maxBufferLength = 600; //default is 30 seconds; we allow 10 minutes of buffer
        options.playback.hlsjsConfig.manifestLoadingMaxRetry = 600;
      }

      if (
        PlaybackStore.isSameAsLastPlayed(broadcast) &&
        PlaybackStore.getStateForBroadcast(broadcast, 'dvrInUse')
      ) {
        // Support DVR transition from live to recorded
        let lastTime = PlaybackStore.getStateForBroadcast(broadcast, 'lastTime') || 0;
        let lastDuration = PlaybackStore.getStateForBroadcast(broadcast, 'totalTime') || 0;
        let lastTimeUpdatedAt =
          PlaybackStore.getStateForBroadcast(broadcast, 'lastTimeUpdatedAt') || 0;
        logger.info(
          'Will transition live DVR',
          { lastTime, lastDuration, lastTimeUpdatedAt },
          broadcast
        );
        delete options.poster;
        autoplay = true;
        this.player = new Clappr.Player(options);
        this.player.core.getCurrentPlayback().once('playback:loadedmetadata', () => {
          // The new transition point should be relative to the end of the broadcast because longer
          // broadcasts can have segments fall off the beginning since the origin server maxes the
          // duration of a live broadcast.
          // Also, since we know the viewers might have missed some of the live broadcast, we can
          // rewind them a bit while they were idle.
          let currentDuration = this.player.getDuration();
          if (lastTime && lastDuration && currentDuration) {
            let prevDistanceFromEdge = lastDuration - lastTime;
            let idleTimeCompensation = Math.min(
              (new Date().getTime() - lastTimeUpdatedAt) / 1000,
              MAX_DVR_IDLE_TIME_COMPENSATION
            );
            this.player.seek(
              Math.max(0, currentDuration - prevDistanceFromEdge - idleTimeCompensation)
            );
          } else if (lastTime) {
            this.player.seek(lastTime);
          }
        });
      }
    }

    // Support external clappr/hls.js configuration options
    if (this.context.userArgs.clapprConfig) {
      options = Util.mergeDict(options, this.context.userArgs.clapprConfig);
    }
    if (this.context.userArgs.hlsjsConfig) {
      options.playback.hlsjsConfig = Util.mergeDict(
        options.playback.hlsjsConfig,
        this.context.userArgs.hlsjsConfig
      );
    }

    if (!this.player) {
      // NOTE: tests were failing here – wrapping it in a try/catch for now.
      try {
        const player = new Clappr.Player(options);
        this.onPlayerReady(player, autoplay, isLive);
      } catch {}
    } else {
      this.onPlayerReady(this.player, autoplay, isLive);
    }
  },

  onPlayerReady(player, autoplay, isLive) {
    // Check for race condition where component is unmounted async
    if (this.unmounted) {
      try {
        player.destroy();
      } catch (e) {
        console.error(e);
      }
      return;
    }

    // Check for race condition where playlist changed out async
    if (player.options.source !== this.props.broadcastView.playlist) {
      try {
        player.destroy();
      } catch (e) {
        console.error(e);
      }
      return;
    }

    const { SelectedBroadcastStore } = this.context.model;

    this.player = player;

    initHlsJsPlayback(this.player, {
      autoplay: autoplay,
      startAtHighestQuality: SelectedBroadcastStore.selectedHighlight, //prefer HD for highlights, otherwise use bandwidth estimate
      isLive: isLive,
    });

    // Attach PlayerAnalytics and Watchdog
    // NOTE: the detach functions unregister events globally so call them all together
    if (this.analytics) {
      this.analytics.detach();
    }
    if (this.watchdog) {
      this.watchdog.detach();
    }
    this.analytics = new PlayerAnalytics(this.player, this.context.model, this.context.env);
    this.watchdog = new Watchdog(this.player, this.context.model);

    // Attach objects to `boxcast.model` for debugging
    this.context.model.player = this.player;
    this.context.model.analytics = this.analytics;
    this.context.model.watchdog = this.watchdog;

    // Bind custom events
    if (this.context.userArgs.onPlayerStateChanged) {
      ['play', 'seek', 'pause', 'stop', 'ended'].forEach((evtName) => {
        this.player.on(evtName, (x) => this.context.userArgs.onPlayerStateChanged(evtName, x));
      });
      ['error', 'buffering', 'dvr'].forEach((evtName) => {
        player.core
          .getCurrentPlayback()
          .on(`playback:${evtName}`, (x) => this.context.userArgs.onPlayerStateChanged(evtName, x));
      });
    }
    if (this.context.userArgs.onLoadPlayer) {
      // Call this outside our stack to avoid client exceptions bubbling into here
      setTimeout(() => this.context.userArgs.onLoadPlayer(this.player), 0);
    }

    /**
     * In static mode, the media controls should be enabled by default.
     * Without this, the user has to click the player to enable the media controls.
     * With this, the controls can be shown on page load without requiring any interaction from the user
     */
    if (!!this.context.userArgs.staticMode) {
      player.core.mediaControl.enable();
    }
  },

  getChromecastMetadata: function () {
    const { SelectedBroadcastStore, CurrentChannelStore, UserArgsStore } = this.context.model;

    let s = SelectedBroadcastStore.view.status || '';
    let isLive = s.indexOf('live') >= 0 || s.indexOf('stalled') >= 0 || s.indexOf('prepared') >= 0;

    let info = {
      account_id: SelectedBroadcastStore.broadcast.account_id,
      is_live: isLive,
      channel_id: CurrentChannelStore.id,
      ticket_id: SelectedBroadcastStore.ticket || CurrentChannelStore.ticket || null,
      remote_ip: Config.get('clientRemoteIP'),
      host: UserArgsStore.hostname || window.location.hostname,
    };
    if (SelectedBroadcastStore.selectedHighlight) {
      info.highlight_id = SelectedBroadcastStore.selectedHighlight.id;
    } else {
      info.broadcast_id = SelectedBroadcastStore.broadcast.id;
    }
    return info;
  },

  onSeekTo: function (position) {
    if (!this.player) {
      logger.warn('Player is not ready; cannot seek');
      return;
    }
    this.player.seek(position);
    this.player.play();
    // Flash the media control on programmatic seek
    this.player.core.mediaControl.show();
  },

  onOpenPaymentModal: function () {
    if (this.player) {
      this.player.pause();
    }
  },

  onWatchBroadcastAgain: function (forcefullyDestroy) {
    if (forcefullyDestroy) {
      logger.log('Attempting to forcefully destroy and re-watch broadcast with same time');
      this.changeBroadcast(this.props.broadcast, this.props.broadcastView);
    } else {
      this.player.play();
    }
  },

  onContentSettingsChanged: function (newArgs) {
    if (this.player) {
      this.player.core.trigger('boxcast:cue-points:update-cues', newArgs.cues || []);
    }
  },

  render: function () {
    return (
      <div>
        <div ref="player"></div>
      </div>
    );
  },
});
