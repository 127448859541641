import React from 'react';
import { duration, timeframe } from '../../utils/broadcast';
import PreviewIcon from '../library/preview-icon.jsx';
import BroadcastStartStopTime from '../library/broadcast-start-stop-time.jsx';

export default function BroadcastListItem(props) {
  const { broadcast, selected, onSelect, defaultPoster} = props;
  const className = `boxcast-playlist-item boxcast-selectable ${
    selected ? 'boxcast-selected' : ''
  }`;

  const pre_thumbnail = broadcast.preview || defaultPoster;
  const posterOrPreview = broadcast.poster ? broadcast.poster : pre_thumbnail
  const post_thumbnail = broadcast && broadcast.post_thumbnail  && broadcast.post_thumbnail.url
  ? broadcast.post_thumbnail.url 
  : posterOrPreview;
  const ImagePreview = broadcast.timeframe === 'past' ? post_thumbnail : pre_thumbnail
  
  return (
    <li className={className}>
      <PreviewIcon
        image={ImagePreview}
        duration={duration(broadcast)}
        timeframe={timeframe(broadcast)}
        onClick={() => onSelect(broadcast, true)}
      />
      <div className="boxcast-playlist-item-meta">
        <h3>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onSelect(broadcast);
            }}
          >
            {broadcast.name}
          </a>
        </h3>
        <p>{broadcast.description}</p>
        <p>
          <BroadcastStartStopTime broadcast={broadcast} mode="inline" />
        </p>
      </div>
    </li>
  );
}
