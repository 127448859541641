import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import { Logger } from '../../utils';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';

const logger = Logger.getInstance('components');

var PasswordEntry = createClass({
  displayName: 'PasswordEntry',

  contextTypes: {
    model: PropTypes.object,
    m: PropTypes.func,
  },

  mixins: [RefluxModelListenerMixin],

  refluxModelListeners: {
    PasswordBlockStore: 'onStoreChanged',
  },

  getInitialState: function () {
    const { isRestricted, isAttemptInvalid, lastPassword } = this.context.model.PasswordBlockStore;
    return { isRestricted, isAttemptInvalid, lastPassword };
  },

  onStoreChanged: function () {
    this.setState(this.getInitialState());
  },

  onTryPassword: function (e) {
    e.preventDefault();
    const { Actions } = this.context.model;
    Actions.PasswordBlockUnlock(this.refs.password.value);
  },

  render: function () {
    const { isRestricted, isAttemptInvalid, lastPassword } = this.state;

    if (isRestricted) {
      return (
        <div
          style={{
            background: 'rgba(0,0,0,0.8)',
            height: '100%',
            padding: '15px',
          }}
        >
          <form action="" onSubmit={(e) => this.onTryPassword(e)}>
            <h3 style={{ color: '#fff', textAlign: 'center' }}>
              {this.context.m('password_required_title')}
            </h3>
            {isAttemptInvalid ? (
              <p style={{ color: '#fff' }}>{this.context.m('password_invalid_title')}</p>
            ) : (
              <span />
            )}
            <div className='half-width'>
              <div className="boxcast-form-field half-width">
                <input
                  className={isAttemptInvalid ? 'boxcast-form-error' : ''}
                  style={{ color: '#000', backgroundColor: '#fff' }}
                  ref="password"
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  aria-label="Enter Password"
                />
              </div>
              <div className="boxcast-form-field quarter-width">
                <button
                  type="submit"
                  className="boxcast-button"
                  style={{ color: '#fff', backgroundColor: '#00acc7' }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return <span>Password accepted.</span>;
    }
  },
});

export default PasswordEntry;
