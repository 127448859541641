import React, { useEffect, useRef } from 'react';

type StripeElementsCardProps = {
  card: {
    mount: (element: any | HTMLElement) => void;
  };
};

export default function StripeElementsCard({ card }: StripeElementsCardProps) {
  const el = useRef<HTMLDivElement>();
  useEffect(() => card.mount(el.current));
  return <div ref={el} />;
}
