import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

const Slot = createClass({
  contextTypes: {
    model: PropTypes.object,
  },
  propTypes: {
    name: PropTypes.string,
  },
  render: function () {
    const { SlotStore } = this.context.model;
    const { name } = this.props;
    if (!SlotStore.has(name)) {
      return null;
    }
    return (
      <div
        className={'boxcast-slot boxcast-slot-' + name}
        dangerouslySetInnerHTML={{ __html: SlotStore.get(name) }}
      ></div>
    );
  },
});

export default Slot;
