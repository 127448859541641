// The `TopThirdOverlay` component is able to observe the state of the entire
// playback experience and determine what should be shown to the user at any
// given time.  It manages the priorities in the wake of multiple errors (which
// is presented first) and should try to guide the user through any possible
// next steps.

import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import { Clock, Logger } from '../../utils';
import { ga } from '../../ga';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';
import { timeframe } from '../../utils/broadcast';

import { WAIT_PREFIX } from '../../i18n';

import BuyTicketButton from '../monetization/buy-ticket-button.jsx';
import CountdownTimer from './countdown-timer.jsx';
import TicketedSecondsRemaining from './ticketed-seconds-remaining.jsx';
import SocialButtons from '../library/social-buttons.jsx';

const logger = Logger.getInstance('components');

export default createClass({
  displayName: 'TopThirdOverlay',

  contextTypes: {
    model: PropTypes.object,
    m: PropTypes.func,
  },

  mixins: [RefluxModelListenerMixin],

  refluxModelListeners: {
    CurrentChannelStore: 'onStoreChanged',
    SelectedBroadcastStore: 'onStoreChanged',
    TicketStore: 'onStoreChanged',
    SocialStore: 'onStoreChanged',
    PlaylistStore: 'onStoreChanged',
    UserArgsStore: 'onStoreChanged',
    GeoBlockStore: 'onStoreChanged',
  },

  getInitialState: function () {
    const {
      CurrentChannelStore,
      SelectedBroadcastStore,
      TicketStore,
      SocialStore,
      PlaylistStore,
      UserArgsStore,
      GeoBlockStore,
    } = this.context.model;
    return {
      broadcast: SelectedBroadcastStore.broadcast,
      selectedHighlight: SelectedBroadcastStore.selectedHighlight,
      view: SelectedBroadcastStore.view,
      isLoading: !!(
        SelectedBroadcastStore.loading ||
        CurrentChannelStore.loading ||
        TicketStore.loading ||
        PlaylistStore.loading
      ),
      timeLimitedPreview: SelectedBroadcastStore.timeLimitedPreview,
      freeVariant: SelectedBroadcastStore.freeVariant,
      ended: SelectedBroadcastStore.ended,
      playbackError: SelectedBroadcastStore.playbackError,
      channelError: CurrentChannelStore.error || PlaylistStore.error,
      shareMenuVisible: SocialStore.shareMenuVisible,
      showCountdown: UserArgsStore.showCountdown,
      isGeoBlockLoading: GeoBlockStore.isLoading,
      isGeoBlockResticted: GeoBlockStore.isRestricted,
    };
  },

  onStoreChanged: function () {
    this.setState(this.getInitialState());
  },

  render: function () {
    const {
      broadcast,
      view,
      isLoading,
      timeLimitedPreview,
      freeVariant,
      ended,
      channelError,
      playbackError,
      shareMenuVisible,
      selectedHighlight,
      showCountdown,
      isGeoBlockLoading,
      isGeoBlockResticted,
    } = this.state;
    const { Actions, UserArgsStore } = this.context.model;
    const { m } = this.context;

    const notRecorded = broadcast.do_not_record;
    const staticMode = !!UserArgsStore.staticMode;

    if (shareMenuVisible) {
      // Case 0: Sharing menu takes precendence over everything
      return (
        <ErrorDisplay title="" description="">
          <ShareMenuOverlay onClose={Actions.HideShareMenu} />
        </ErrorDisplay>
      );
    } else if (isGeoBlockLoading) {
      // Case 1A - geoblock check in progress
      return (
        <ErrorDisplay
          title={m(`geoblock_loading_title`)}
          description={m(`geoblock_loading_subtitle`)}
        />
      );
    } else if (isGeoBlockResticted) {
      // Case 1B - geoblock failed
      return (
        <ErrorDisplay
          title={m(`geoblock_restricted_title`)}
          description={m(`geoblock_restricted_subtitle`)}
        />
      );
    } else if (view && view.error) {
      // Case 1C: API returned explicit error
      var title = m(`player_${view.error}_title`) || `An Error Occurred (${view.error})`;
      return <ErrorDisplay title={title} description={view.error_description} />;
    } else if (view && view.status == 'processing_recording') {
      if (selectedHighlight) {
        // Case 2a: Highlight just got captured
        return (
          <ErrorDisplay
            title={m('player_processing_highlight_title')}
            description={m('player_processing_highlight_subtitle', view.progress)}
          />
        );
      } else {
        // Case 2b: Live broadcast has ended; no media to be shown while processing the recording
        return (
          <ErrorDisplay
            title={m('player_processing_recording_title')}
            description={m('player_processing_recording_subtitle', view.progress)}
          />
        );
      }
    } else if (view && !view.playlist && !isLoading) {
      // Case 3: Recording isn't available in the API for some reason.
      return (
        <PlaylistNotAvailable
          m={m}
          view={view}
          broadcast={broadcast}
          channelError={channelError}
          showCountdown={showCountdown}
        />
      );
    } else if (playbackError) {
      // Case 4: The video player encountered an error of some sort during playback
      return (
        <PlaybackError
          m={m}
          error={playbackError.error || {}}
          onReload={() => Actions.WatchBroadcastAgain(true)}
        />
      );
    } else if (freeVariant) {
      // Case 5: Viewer is watching the free low quality (after high res expired)
      return (
        <ErrorDisplay small={true} title={m('watching_free_variant_title')}>
          <div className="boxcast-errors-detail">
            <BuyTicketButton>
              <a>{m('payment_flex_title')}</a>
            </BuyTicketButton>
            .
          </div>
        </ErrorDisplay>
      );
    } else if (timeLimitedPreview) {
      // Case 6: Viewer is watching a time-limited preview of the ticketed recording
      return (
        <ErrorDisplay title={m('watching_preview_title')}>
          <div className="boxcast-errors-detail">
            <TicketedSecondsRemaining broadcast={broadcast} />
          </div>
        </ErrorDisplay>
      );
    } else if (ended && !notRecorded && !staticMode) {
      // Case 7: Video playback ended
      // TODO: consider adding a "watch next" screen if there's a playlist with next video available
      return (
        <ErrorDisplay title={m('broadcast_ended_title')}>
          <p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                Actions.WatchBroadcastAgain();
              }}
            >
              {m('broadcast_ended_watch_again_description')}
            </a>
          </p>
        </ErrorDisplay>
      );
    } else if (ended && notRecorded) {
      // Case 8: Video playback ended
      return <ErrorDisplay title={m('broadcast_ended_title')}></ErrorDisplay>;
    } else {
      // Either loading state (handled separately) or good to go
      return null;
    }
  },
});

function ErrorDisplay(props) {
  var { title, description, small } = props;
  if (typeof description == 'object') {
    description = JSON.stringify(description);
  }
  var className = small ? 'boxcast-errors-small' : 'boxcast-errors';
  var titleClassName = (title || '').indexOf(WAIT_PREFIX) == 0 ? 'animated-ellipsis-after' : '';
  var descrClassName =
    (description || '').indexOf(WAIT_PREFIX) == 0 ? 'animated-ellipsis-after' : '';
  return (
    <div className={className}>
      <h3 className={titleClassName}>{title}</h3>
      {description ? <p className={descrClassName}>{description}</p> : <span />}
      {props.children}
    </div>
  );
}

function ShareMenuOverlay(props) {
  return (
    <div style={{ pointerEvents: 'all' }}>
      <a
        style={{
          position: 'absolute',
          top: '5px',
          right: '15px',
          border: 'none',
          fontSize: '25px',
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.onClose && props.onClose();
        }}
      >
        &times;
      </a>
      <div style={{ textAlign: 'center', zoom: '150%' }}>
        <SocialButtons />
      </div>
    </div>
  );
}

function PlaylistNotAvailable(props) {
  const { m, view, broadcast, channelError, showCountdown } = props;
  const starts = Clock.parse(broadcast.starts_at);
  const showCountdownTimer =
    (view.status == 'upcoming' || view.status == 'preparing') && showCountdown;
  const reason = view.reason || view.status || (channelError && channelError.error_description);
  const formatArgs = [
    Clock.formatRelative(starts),
    Clock.formatTime(starts),
    view.progress,
    reason || 'Unknown error loading playlist',
    broadcast.name,
  ];
  const title = findMessage(
    m,
    [
      `player_${view.reason}_title`,
      `player_${view.status}_title`,
      `player_${channelError && channelError.error_description}_title`,
      'player_genericreason_title',
    ],
    formatArgs
  );
  const description = findMessage(
    m,
    [
      `player_${view.reason}_subtitle`,
      `player_${view.status}_subtitle`,
      `player_${channelError && channelError.error}_subtitle`,
      'player_genericreason_subtitle',
    ],
    formatArgs
  );
  return (
    <ErrorDisplay title={title} description={description}>
      {showCountdownTimer ? <CountdownTimer date={starts} /> : ''}
    </ErrorDisplay>
  );
}

function PlaybackError(props) {
  const { m } = props;
  let { message, code, error, data, stuck_on_discontinuity } = props.error;
  message = message || 'Unknown playback error';
  if (error && error.code) {
    // Clappr internal error
    code = error.code;
  }
  // hls.js error type mapping (see hls.js/src/errors.js)
  if (data && data.type == 'networkError') {
    code = 4; //MEDIA_ERR_NETWORK
  }
  const title = m(`player_error_code_${code}_title`) || m('player_error_unknown_title');
  let description =
    m(`player_error_code_${code}_subtitle`) || m('player_error_unknown_subtitle', message, code);
  if (code == 'buffering_too_long' && stuck_on_discontinuity) {
    // view endpoint won't tell us this, but watchdog knows about hls.js to inform us
    description = m(`player_error_code_buffering_too_long_stalled_subtitle`);
  }
  return (
    <ErrorDisplay title={title} description={description}>
      <p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onReload && props.onReload();
          }}
        >
          Click to reload the broadcast
        </a>
      </p>
    </ErrorDisplay>
  );
}

function findMessage(m, keys, formatArgs) {
  for (let i = 0; i < keys.length; i++) {
    var result = m(keys[i], ...formatArgs);
    if (result) {
      return result;
    }
  }
}
