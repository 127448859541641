import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { Logger, Util } from '../../utils';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';

import { gaEvent } from '../../ga';
import Modal from '../library/modal.jsx';
import PaymentComplete from './payment-complete.jsx';
import { OrderSummaryLarge, OrderSummarySmall } from './order-summary.jsx';
import StripeElementsCard from './stripe-elements-card.tsx';

const logger = Logger.getInstance('components');

const MIN_DONATION = 10;
const DONATION_FEE_PCT = 6;

var PaymentModal = createClass({
  displayName: 'PaymentModal',

  contextTypes: {
    model: PropTypes.object,
    env: PropTypes.func,
    m: PropTypes.func,
  },

  mixins: [RefluxModelListenerMixin],

  refluxModelListeners: {
    TicketStore: 'onStoreChanged',
    Actions: [
      { on: 'TicketPurchaseCompleted', trigger: 'onTicketPurchaseCompleted' },
      { on: 'DonationCompleted', trigger: 'onDonationCompleted' },
      { on: 'PreviewTicketCompleted', trigger: 'onPreviewTicketCompleted' },
    ],
  },

  getInitialState: function () {
    const { TicketStore } = this.context.model;
    return {
      modalOpen: TicketStore.modalOpen,
      submitting: false,
      completed: false,
      invalidFormFields: {},
      showAddressFields: false,
      formError: false,
      donationAmount: 0,
      donationRoundUp: false,
      consent: false,
      hidePostalCode: false,
      previewing: false,
      taxAmount: null,
    };
  },

  componentDidMount: function () {
    const { Actions } = this.context.model;
    Actions.LoadStripeJs(this.context.env('stripeKey'));
    Actions.LoadCountries();
  },

  onStoreChanged: function () {
    const { TicketStore } = this.context.model;

    if (TicketStore.modalOpen) {
      this.setState({ modalOpen: true });
    } else {
      // side effect of closing modal is to reset the submitting state.
      // NOTE: this is necessary for example if token successfully acquired from stripe
      // but it is rejected by our API.
      this.setState({
        modalOpen: false,
        submitting: false,
        invalidFormFields: {},
        formError: false,
        completed: false,
        consent: false,
        hidePostalCode: false,
        previewing: false,
        taxAmount: null,
      });
    }

    if (TicketStore.stripeLoadError) {
      this.setState({ formError: this.context.m('stripe_not_loaded') });
    }
  },

  hideModal: function () {
    const { Actions } = this.context.model;
    Actions.ClosePaymentModal();
  },

  render: function () {
    const { modalType } = this.context.model.TicketStore;
    return (
      <Modal
        onClickOverlay={() => this.hideModal()}
        visible={this.state.modalOpen}
        opacity={0.85}
        className="boxcast-payment-modal"
      >
        {this.state.completed ? (
          this.renderCompleted()
        ) : (
          <div className="boxcast-row">
            <div className="boxcast-col-60 boxcast-col-xs-100" style={{ textAlign: 'left' }}>
              {modalType == 'donate'
                ? this.renderInfoFormForDonation()
                : this.renderInfoFormForTicket()}
              {this.renderCreditCardForm()}
            </div>
            <div className="boxcast-col-40 boxcast-hidden-xs boxcast-order-summary">
              {this.renderOrderSummaryLg()}
            </div>
          </div>
        )}
      </Modal>
    );
  },

  renderCompleted: function () {
    const { m, model } = this.context;

    let description = m(`payment_modal_ticket_complete`);
    let cta = 'Watch Broadcast';
    if (model.TicketStore.modalType == 'donate') {
      description = m(`payment_modal_donate_complete`);
      cta = 'Return To Broadcast';
    }

    return (
      <PaymentComplete description={description} cta={cta} onDismiss={() => this.hideModal()} />
    );
  },

  // Submit your purchase or donation to Stripe
  onSubmit: function (e) {
    e.preventDefault();

    gaEvent(`${modalType}:submitted`, '', 'ticket');

    const { Actions, CurrentChannelStore, SelectedBroadcastStore, TicketStore, UserArgsStore } =
      this.context.model;
    const { modalType } = TicketStore;
    const form = this.refs.ccForm;

    if (!TicketStore.stripe) {
      // Alert user that Stripe couldn't be loaded. This can be due to older versions of Kasperski.
      this.setState({
        invalidFormFields: {},
        formError: this.context.m('stripe_not_loaded'),
      });
      // Might as well try to load it again
      Actions.LoadStripeJs(this.context.env('stripeKey'));
      gaEvent(`${modalType}:error`, 'stripe-undefined', 'ticket');
      return;
    }

    // Validate that user has at least provided some data; let Stripe validate correctness
    let invalidFormFields = {};
    const formFields = [
      'name',
      'number',
      'expiry',
      'cvc',
      'postalCode',
      'email',
      'confirmEmail',
      'addressLine',
      'city',
      'state',
      'zip',
      'country',
    ];
    formFields.forEach((field) => {
      if (
        (!!form[field] && !form[field].value) ||
        (field === 'email' && !Util.isValidEmail(form.email.value)) ||
        (field === 'confirmEmail' && form.email.value !== form.confirmEmail.value) ||
        (field === 'postalCode' && form.postalCode && !Util.isValidZipCode(form.postalCode.value))
      ) {
        invalidFormFields[field] = true;
      }
    });

    if (Object.keys(invalidFormFields).length) {
      this.setState({
        invalidFormFields: invalidFormFields,
        formError: 'Please enter all required fields.',
      });
      gaEvent(`${modalType}:invalid`, invalidFormFields, 'ticket');
      return;
    }

    if (!this.state.consent) {
      this.setState({
        invalidFormFields: invalidFormFields,
        formError: 'You must review and agree to the consent before submitting.',
      });
      gaEvent(`${modalType}:invalid`, 'consent_not_checked', 'ticket');
      return;
    }

    // Validate donation (only when in donation mode)
    let amount = null;
    if (modalType == 'donate') {
      amount = this._getDonationAmount();
      if (isNaN(amount) || amount < MIN_DONATION) {
        this.setState({
          formError: `Please enter a donation amount of at least $${MIN_DONATION}.`,
        });
        gaEvent(`${modalType}:invalid`, 'donation-too-low', 'ticket');
        return;
      }
    }

    // Add extra data for ease in customer support
    let tokenData = {
      email: form.email.value,
      name: form.name.value,
      address_country: form.country.value,
    };
    const postalCode = UserArgsStore.billingZip || (form.postalCode || {}).value;
    if (postalCode) {
      tokenData.address_zip = postalCode;
    }

    this.setState({ formError: null, invalidFormFields: {}, submitting: true });
    TicketStore.stripe.createToken(TicketStore.stripeCard, tokenData).then((result) => {
      logger.log('Stripe response:', result);
      if (result.error) {
        this.setState({
          submitting: false,
          formError: 'The credit card could not be processed. ' + result.error.message,
        });
        gaEvent(`${modalType}:error`, result.error.message, 'ticket');
      } else if (result.card && result.card.address_zip_check == 'fail') {
        this.setState({
          submitting: false,
          formError:
            'The credit card could not be processed. The billing zip code verification failed.',
        });
        gaEvent(`${modalType}:error`, 'billing-zip-mismatch', 'ticket');
      } else {
        // Now send to BoxCast API to process charge
        var charge = {
          id: result.token.id,
          email: form.email.value,
          name: form.name.value,
          country: form.country.value,
        };
        // Send the address fields if the user has opted in. Ensure the
        // modal is a donation modal, we reuse this component for both
        // purchases and donations.
        if (this.state.showAddressFields && modalType == 'donate') {
          charge.address = form.addressLine.value;
          charge.city = form.city.value;
          charge.state = form.state.value;
          charge.zip = form.zip.value;
        }
        if (amount) {
          charge.amount = amount; //<-- only for donations
        }
        if (postalCode) {
          charge.postal_code = postalCode;
        }
        Actions.StripeTokenAcquired(
          SelectedBroadcastStore.broadcast,
          CurrentChannelStore.isTicketed,
          charge
        );
        gaEvent(`${modalType}:stripe_complete`, '', 'ticket');
      }
    });

    return false;
  },

  onTicketPurchaseCompleted: function () {
    this.setState({ completed: true });
    gaEvent(`ticket:success`, '', 'ticket');
  },

  onDonationCompleted: function () {
    this.setState({ completed: true });
    gaEvent(`ticket:success`, '', 'ticket');
  },

  onPreviewTicketCompleted: function (taxAmount) {
    this.setState({ previewing: false, taxAmount: taxAmount });
  },

  getChannelInfo: function () {
    const { CurrentChannelStore, SelectedBroadcastStore, TicketStore } = this.context.model;
    const { modalType } = TicketStore;
    const { broadcast } = SelectedBroadcastStore;

    var channelId, channelTicket, ticketPrice, description;
    if (CurrentChannelStore.isTicketed) {
      channelId = CurrentChannelStore.id;
      channelTicket = true;
      ticketPrice = CurrentChannelStore.ticketPrice;
      description = `Ticket for ${CurrentChannelStore.name} channel`;
    } else {
      channelId = SelectedBroadcastStore.broadcast.channel_id;
      channelTicket = false;
      ticketPrice = broadcast.ticket_price;
      description = `Ticket for ${broadcast.name}`;
    }

    if (modalType == 'donate') {
      ticketPrice = this._getDonationAmount();
      description = this.context.m('payment_modal_donate_item_name');
      channelTicket = false;
    }

    return { broadcast, channelId, channelTicket, ticketPrice, description };
  },

  onCountryChange: function (event) {
    const country = event.target.value;
    if (country == 'US') {
      this.setState({ hidePostalCode: false });
    } else {
      this.setState({ hidePostalCode: true, taxAmount: 0 });
    }
  },

  onPostalCodeChange: function (event) {
    const { Actions, SelectedBroadcastStore, CurrentChannelStore } = this.context.model;
    const { modalType } = this.context.model.TicketStore;
    const postalCode = event.target.value;
    if (modalType == 'donate') {
      return;
    }
    if (postalCode.trim().length == 5) {
      this.setState({ previewing: true });
      Actions.PreviewTicket(
        SelectedBroadcastStore.broadcast,
        CurrentChannelStore.isTicketed,
        postalCode
      );
    }
  },

  renderInfoFormForTicket: function () {
    return (
      <div className="boxcast-payment-instructions" style={{ textAlign: 'left' }}>
        <div className="boxcast-payment-title">Purchase a Ticket</div>
        {this.renderOrderSummarySm()}
      </div>
    );
  },

  renderInfoFormForDonation: function () {
    const { SelectedBroadcastStore, CurrentChannelStore, UserArgsStore } = this.context.model;
    const accountName = SelectedBroadcastStore.broadcast.account_name;
    const channelId = CurrentChannelStore.id;
    return (
      <div className="boxcast-payment-instructions" style={{ textAlign: 'left' }}>
        <div className="boxcast-payment-subtitle">Donate to {accountName}</div>
        {UserArgsStore.donateMessage ? (
          <div className="boxcast-payment-details">{UserArgsStore.donateMessage}</div>
        ) : (
          <span />
        )}
        <div className="boxcast-payment-label" style={{ marginTop: '20px' }}>
          Select a Donation Amount
        </div>
        <div className="boxcast-button-group">
          {[10, 20, 50, 100].map((amt) => {
            return (
              <button
                key={'btn-' + amt}
                type="button"
                className={'boxcast-button' + (this.state.donationAmount == amt ? ' active' : '')}
                onClick={() => this.setState({ showOtherAmount: false, donationAmount: amt })}
              >
                ${amt}
              </button>
            );
          })}
          <button
            type="button"
            className="boxcast-button"
            onClick={() => this.setState({ showOtherAmount: true, donationAmount: 0 })}
          >
            Other
          </button>
        </div>
        {this.state.showOtherAmount ? (
          <div className="boxcast-form-field">
            <input
              onChange={() => this.setState({ donationAmount: this.refs.otherAmount.value })}
              ref="otherAmount"
              type="number"
              aria-label="Other Amount"
            />
          </div>
        ) : (
          <span />
        )}
        <label className="boxcast-payment-label" style={{ margin: '10px 0 20px 0' }}>
          <input
            type="checkbox"
            ref="donationRoundUp"
            onChange={() =>
              this.setState({
                donationRoundUp: this.refs.donationRoundUp.checked,
              })
            }
          />{' '}
          Round up donation to cover processing fees*
        </label>
        {this.renderOrderSummarySm()}
      </div>
    );
  },

  renderOrderSummaryLg: function () {
    const { modalType } = this.context.model.TicketStore;
    const { broadcast, ticketPrice, channelTicket, description } = this.getChannelInfo();
    const { taxAmount } = this.state;
    return (
      <OrderSummaryLarge
        description={description}
        accountName={broadcast.account_name}
        channelTicket={channelTicket}
        ticketPrice={ticketPrice}
        disclaimer={this.context.m(`payment_modal_${modalType}_disclaimer_text`, DONATION_FEE_PCT)}
        taxAmount={taxAmount}
        modalType={modalType}
      />
    );
  },

  renderOrderSummarySm: function () {
    const { modalType } = this.context.model.TicketStore;
    const { broadcast, ticketPrice, channelTicket, description } = this.getChannelInfo();
    const { taxAmount } = this.state;
    return (
      <OrderSummarySmall
        description={description}
        accountName={broadcast.account_name}
        channelTicket={channelTicket}
        ticketPrice={ticketPrice}
        taxAmount={taxAmount}
        modalType={modalType}
      />
    );
  },

  renderAddressFormForDonation: function () {
    const { submitting, formError, invalidFormFields } = this.state;
    return (
      <div className="boxcast-donation-address" style={{ textAlign: 'left' }}>
        <div className="boxcast-form-field">
          <input
            className={invalidFormFields.addressLine ? 'boxcast-form-error' : ''}
            disabled={submitting}
            ref="addressLine"
            name="addressLine"
            type="text"
            placeholder="Address"
            aria-label="Address"
          />
        </div>
        <div className="boxcast-form-field">
          <input
            className={invalidFormFields.city ? 'boxcast-form-error' : ''}
            disabled={submitting}
            ref="city"
            name="city"
            type="text"
            placeholder="City"
            aria-label="City"
          />
        </div>
        <div>
          <div className="boxcast-form-field half-width">
            <input
              className={invalidFormFields.state ? 'boxcast-form-error' : ''}
              disabled={submitting}
              ref="state"
              name="state"
              type="text"
              placeholder="State/Province"
              aria-label="State/Province"
            />
          </div>
          <div className="boxcast-form-field half-width">
            <input
              className={invalidFormFields.zip ? 'boxcast-form-error' : ''}
              disabled={submitting}
              ref="zip"
              name="zip"
              type="number"
              placeholder="Zip/Postal Code"
              aria-label="Zip/Postal Code"
            />
          </div>
        </div>
      </div>
    );
  },

  renderCountryOptions: function () {
    const { countries } = this.context.model.TicketStore;
    return countries.map((c) => (
      <option key={c.code} value={c.code}>
        {c.name}
      </option>
    ));
  },

  renderCreditCardForm: function CreditCardForm() {
    const { modalType, stripeCard, countries } = this.context.model.TicketStore;
    const { UserArgsStore } = this.context.model;
    const { submitting, formError, invalidFormFields, hidePostalCode, previewing, taxAmount } =
      this.state;
    const { ticketPrice } = this.getChannelInfo();
    var emailHelp = this.context.m(`payment_modal_${modalType}_email_text`);
    var buttonLabel = this.context.m(
      `payment_modal_${modalType}_button_text`,
      Util.formatCurrency(ticketPrice + taxAmount)
    );
    return (
      <div className="boxcast-payment-cc">
        <form ref="ccForm" onSubmit={(e) => this.onSubmit(e)}>
          {formError ? <div className="boxcast-form-error">{formError}</div> : <span />}
          <div className="boxcast-form-field">
            <div className="boxcast-payment-label">Full Name</div>
            <input
              className={invalidFormFields.name ? 'boxcast-form-error' : ''}
              disabled={submitting}
              placeholder=""
              type="text"
              name="name"
              aria-label="Full name"
            />
          </div>
          {stripeCard && countries.length ? (
            <div>
              <div className="boxcast-form-field">
                <div className="boxcast-payment-label">Credit Card</div>
                <StripeElementsCard card={stripeCard} />
              </div>
              <div className={`boxcast-form-field${hidePostalCode ? '' : ' half-width'}`}>
                <select
                  id="countries"
                  name="country"
                  disabled={submitting}
                  defaultValue="US"
                  onChange={this.onCountryChange}
                >
                  {this.renderCountryOptions()}
                </select>
              </div>
              {!UserArgsStore.billingZip && !hidePostalCode ? (
                <div className="boxcast-form-field half-width">
                  <input
                    className={invalidFormFields.postalCode ? 'boxcast-form-error' : ''}
                    disabled={submitting}
                    placeholder="Zip code"
                    type="text"
                    name="postalCode"
                    aria-label="Postal/Zip code"
                    onChange={this.onPostalCodeChange}
                    maxLength="5"
                  />
                </div>
              ) : (
                <span />
              )}
            </div>
          ) : (
            'Loading Credit Card Form...'
          )}
          {UserArgsStore.billingZip ? (
            <div className="boxcast-form-field">
              <p className="boxcast-disclaimer">
                {this.context.m('ticket_zip_code_match', UserArgsStore.billingZip)}
              </p>
            </div>
          ) : (
            <span />
          )}
          <div className="boxcast-form-field">
            <div className="boxcast-payment-label">Email Address</div>
            <input
              className={invalidFormFields.email ? 'boxcast-form-error' : ''}
              disabled={submitting}
              placeholder=""
              type="text"
              name="email"
              aria-label="Email address"
            />
          </div>
          <div className="boxcast-form-field">
            <div className="boxcast-payment-label">Confirm Email Address</div>
            <input
              className={invalidFormFields.confirmEmail ? 'boxcast-form-error' : ''}
              disabled={submitting}
              placeholder=""
              type="text"
              name="confirmEmail"
              aria-label="Confirm Email address"
            />
            <div className="boxcast-payment-label-sm" style={{ marginTop: '3px' }}>
              {emailHelp}
            </div>
          </div>
          {modalType == 'donate' ? this.renderAddressFormReveal() : <span />}
          <div className="boxcast-form-field">
            <label className="boxcast-payment-label">
              <input
                type="checkbox"
                value="consent"
                onClick={(e) => this.setState({ consent: !this.state.consent })}
              />{' '}
              I have reviewed and expressly consent to the BoxCast{' '}
              <a href="https://www.boxcast.com/legal/privacy" target="_blank">
                Privacy Policy
              </a>
              ,{' '}
              <a href="https://www.boxcast.com/legal/terms" target="_blank">
                Terms of Use
              </a>
              , and{' '}
              <a
                href="https://boxcast.zendesk.com/hc/en-us/articles/208593826-What-is-the-BoxCast-refund-policy-"
                target="_blank"
              >
                Refund Policy
              </a>
              .
            </label>
          </div>
          <div className="boxcast-form-field">
            <button className="boxcast-ticket-button-solid" disabled={submitting || previewing}>
              {previewing ? 'Calculating total ...' : buttonLabel}
            </button>
          </div>
        </form>
      </div>
    );
  },

  renderAddressFormReveal: function () {
    return (
      <div className="boxcast-form-field" id="boxcast-donation-address">
        <label className="boxcast-payment-label">
          <input
            type="checkbox"
            value="donation_address_checkbox"
            onClick={(e) =>
              this.setState({
                showAddressFields: !this.state.showAddressFields,
              })
            }
          />{' '}
          Include mailed statement of donation
        </label>
        {this.state.showAddressFields ? this.renderAddressFormForDonation() : null}
      </div>
    );
  },

  _getDonationAmount: function () {
    var donationAmount = Math.max(0, parseFloat(this.state.donationAmount, 10) || 0);
    if (this.state.donationRoundUp) {
      donationAmount = donationAmount / (1 - DONATION_FEE_PCT / 100.0);
    }
    return donationAmount;
  },
});

export default PaymentModal;
