import Reflux from 'reflux';
import Promise from 'bluebird';

import API from '../api';
import Config from '../config';
import { BrowserStorage, Logger } from '../utils';
import { timeframe } from '../utils/broadcast';

const PURCHASED_KEY = 'bx:tkt';
const logger = Logger.getInstance('models');

function getTicket(channelId) {
  // XXX: need to duplicate this function in TicketStore to avoid a circular dependency
  return BrowserStorage.getFromRingBuffer(PURCHASED_KEY, '' + channelId);
}
function setTicket(broadcast, ticket) {
  BrowserStorage.addToRingBuffer(
    PURCHASED_KEY,
    '' + broadcast.id,
    ticket,
    Config.dict.purchasedBufferSize
  );
}

export var CurrentChannelStoreFactory = (Actions) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.id = null;
      this.name = null;
      this.description = null;
      this.isTicketed = false;
      this.ticketPrice = 0;
      this.freeVariant = null;
      this.ticket = null;
      this.loading = false;
      this.error = null;
      this.args = {};
      this.callbacks = {};
    },

    // Action bindings
    onLoadChannel: function (id, args) {
      logger.log('CurrentChannelStore#onLoadChannel', id, args);

      // Reset state of store
      this.init();
      this.callbacks.onLoadBroadcast = args.onLoadBroadcast;
      this.callbacks.onLoadHighlight = args.onLoadHighlight;
      this.callbacks.onTicketPurchaseCompleted = args.onTicketPurchaseCompleted;
      this.callbacks.onRefresh = args.onRefresh;
      this.callbacks.onGeoBlockRestricted = args.onGeoBlockRestricted;
      this.callbacks.onCryptBlockRestricted = args.onCryptBlockRestricted;
      this.callbacks.onTokenInvalid = args.auth && args.auth.onTokenInvalid;
      this.id = id;
      this.loading = true;
      this.args = args;
      if (args.ticketId) {
        this.ticket = args.ticketId;
        setTicket({ id: id }, args.ticketId);
      } else {
        this.ticket = getTicket(id);
      }
      this.trigger();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      // Hit API
      return this._getChannelPromise()
        .then((channel) => {
          if (!isThisRequestStillValid()) return null;
          channel = channel || {};
          if (channel.error == 'not_found') {
            // this is likely a single broadcast channel (or the channel has been deleted).
            // don't throw an exception because it's really not that bad.
          } else if (channel.error) {
            Actions.LoadChannelError(channel.error);
          }
          this.name = channel.name;
          this.description = channel.description;
          this.isTicketed = channel.is_ticketed;
          this.ticketPrice = channel.ticket_price;
          this.freeVariant = channel.free_variant;
          this.loading = false;
          this.trigger(); // pass along to listeners
        })
        .catch((error) => {
          if (!isThisRequestStillValid()) return null;
          this.error = error;
          Actions.LoadChannelError(error);
          this.loading = false;
          this.trigger(); // pass along to listeners
        });
    },

    _getChannelPromise() {
      if (API.hasPreloadAvailable('channel')) {
        return API.fromPreload('channel');
      } else {
        return API.get(`/channels/${this.id}`);
      }
    },
  });
