import { Mediator, Events, Browser } from '@clappr/player';
import { MediaControl } from '@clappr/plugins';

export default MediaControl.extend({
  bindContainerEvents() {
    if (!this.container) return;
    this.listenTo(this.container, Events.CONTAINER_PLAY, this.changeTogglePlay);
    this.listenTo(this.container, Events.CONTAINER_PAUSE, this.changeTogglePlay);
    this.listenTo(this.container, Events.CONTAINER_STOP, this.changeTogglePlay);
    this.listenTo(this.container, Events.CONTAINER_TIMEUPDATE, this.onTimeUpdate);
    this.listenTo(this.container, Events.CONTAINER_PROGRESS, this.updateProgressBar);
    this.listenTo(this.container, Events.CONTAINER_SETTINGSUPDATE, this.settingsUpdate);
    this.listenTo(this.container, Events.CONTAINER_PLAYBACKDVRSTATECHANGED, this.settingsUpdate);
    this.listenTo(this.container, Events.CONTAINER_HIGHDEFINITIONUPDATE, this.highDefinitionUpdate);
    this.listenTo(this.container, Events.CONTAINER_MEDIACONTROL_DISABLE, this.disable);
    this.listenTo(this.container, Events.CONTAINER_MEDIACONTROL_ENABLE, this.enable);
    this.listenTo(this.container, Events.CONTAINER_ENDED, this.ended);
    this.listenTo(this.container, Events.CONTAINER_VOLUME, this.onVolumeChanged);
    this.listenTo(this.container, Events.CONTAINER_OPTIONS_CHANGE, this.setInitialVolume);
    if (this.container.playback.el.nodeName.toLowerCase() === 'video') {
      // wait until the metadata has loaded and then check if fullscreen on video tag is supported
      this.listenToOnce(
        this.container,
        Events.CONTAINER_LOADEDMETADATA,
        this.onLoadedMetadataOnVideoTag
      );
    }

    if (this.allowNativeFullscreen) {
      this.listenTo(this.container, Events.CONTAINER_DBLCLICK, this.toggleFullscreen);
    }

    // Custom Boxcast logic
    var video = this.container.playback.el;
    if (video.nodeName.toLowerCase() === 'video') {
      // wait until the metadata has loaded and then check if fullscreen on video tag is supported
      this.listenToOnce(
        this.container,
        Events.CONTAINER_LOADEDMETADATA,
        this.onLoadedMetadataOnVideoTag
      );

      // on touch devices, always show controls if they tap through to video
      video.addEventListener('touchstart', () => this.show());

      // watch for user to come out of fullscreen and remove the native controls
      // that iOS tends to add in for some reason.
      video.addEventListener(
        'webkitendfullscreen',
        function () {
          video.removeAttribute('controls');
        },
        false
      );
    }
  },

  get allowNativeFullscreen() {
    // If using the fullscreen placeholder plugin, do not allow native fullscreen functionality
    if (this.options.fullscreenPlaceholder) {
      return false;
    }
    return true;
  },

  get events() {
    var e = {
      // play/pause/stop
      'click [data-play]': 'play',
      'click [data-pause]': 'pause',
      'click [data-playpause]': 'togglePlayPause',
      'click [data-stop]': 'stop',
      'click [data-playstop]': 'togglePlayStop',

      // volume
      'click .bar-container[data-volume]': 'onVolumeClick',
      'click .drawer-icon[data-volume]': 'toggleMute',
      'mouseenter .drawer-container[data-volume]': 'showVolumeBar',
      'mouseleave .drawer-container[data-volume]': 'hideVolumeBar',
      'mousedown .bar-container[data-volume]': 'startVolumeDrag',
      'mousemove .bar-container[data-volume]': 'mousemoveOnVolumeBar',

      // seek
      'click .bar-container[data-seekbar]': 'clickOrTapToSeek',
      'mousedown .bar-scrubber[data-seekbar]': 'startSeekDrag',
      'mousemove .bar-container[data-seekbar]': 'mousemoveOnSeekBar',
      'mouseleave .bar-container[data-seekbar]': 'mouseleaveOnSeekBar',

      // show/hide when mouse over
      'mouseenter .media-control-layer[data-controls]': 'setUserKeepVisible',
      'mouseleave .media-control-layer[data-controls]': 'resetUserKeepVisible',
    };

    // Seek with touch events is needed on iOS, but not Android, which tends to emulate them
    if (Browser.isiOS) {
      e['touchstart .bar-scrubber[data-seekbar]'] = 'startSeekDrag';
      e['touchmove .bar-container[data-seekbar]'] = 'touchMoveOnSeekBar';
      e['touchend .bar-container[data-seekbar]'] = 'touchEndOnSeekbar';
    }

    // If using the fullscreen placeholder plugin, do not allow native fullscreen functionality
    if (this.allowNativeFullscreen) {
      e['click [data-fullscreen]'] = 'toggleFullscreen';
    }

    return e;
  },

  clickOrTapToSeek: function (event) {
    this.seek(event);

    // Make sure to also hide the hover indicator if it were there on touch devices
    this.mouseleaveOnSeekBar(event);
  },

  touchMoveOnSeekBar: function (event) {
    this.mousemoveOnSeekBar(event);

    // If we hid the seek dot on touchEnd from last time, make sure to restore it
    // so you can see where you're dragging
    this.$seekBarHover.css({ opacity: 1 });
  },

  touchEndOnSeekbar: function (event) {
    // Touch event is a little different than mouse hover, in that we actually
    // want to perform a seek when they're done dragging.  For mouse, we let
    // them hover to see the scrub thumbnail but require a separate click to seek.
    this.mouseleaveOnSeekBar(event);

    // Pretend this was part of the grab/drag functionality with mouse-based interaction,
    // which will perform a seek when done dragging.
    this.draggingSeekBar = true;
    this.stopDrag(event);
    event.preventDefault();

    // Also, that silly `bar-hover` dot is useless for touch, and gets stuck being displayed
    // when iOS decides to keep the :hover selector on. Just get rid of it.
    this.$seekBarHover.css({ opacity: 0 });
  },

  show: function (event) {
    if (this.disabled) return;

    const timeout = 2000;
    let mousePointerMoved =
      event && event.clientX !== this.lastMouseX && event.clientY !== this.lastMouseY;
    if (!event || mousePointerMoved || navigator.userAgent.match(/firefox/i)) {
      clearTimeout(this.hideId);
      this.$el.show();
      this.trigger(Events.MEDIACONTROL_SHOW, this.name);
      this.container && this.container.trigger(Events.CONTAINER_MEDIACONTROL_SHOW, this.name);
      this.$el.removeClass('media-control-hide');
      this.hideId = setTimeout(() => this.hide(), timeout);
      if (event) {
        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;
      }
    }
    const showing = true;
    this.updateCursorStyle(showing);

    if (!this.allowNativeFullscreen) {
      this.$el.find('[data-fullscreen]:not(.placeholder)').hide();
    }
  },
});
