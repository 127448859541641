import Config from '../config';
import Util from './util';

// Support an offset to compensate for client/server clocks being out-of-sync.
// Note that server time can only come in whole second increments,
// so we only adjust if we're off by more than about 10 seconds
const MIN_TOLERABLE_CLOCK_DRIFT_MS = 10 * 1000;
var clientCompensation = 0;
var adjustedFromAPIHeaders = false;

// Clock provides an interface for working with the system time and
// locale-specific date formatting.
export default class Clock {
  // now returns the current system time. by default, it attempts to utilize
  // the API server time to correct any client clock fallacies.
  static now(compensateForClientOffset = true) {
    let date = new Date();
    if (compensateForClientOffset) {
      date.setTime(date.getTime() + clientCompensation);
    }
    return date;
  }

  // keep track of how far apart we have drifted from the server time.
  static updateCompensationFromServerTime(serverTimeString) {
    let diffMS = Clock.diff(Clock.parse(serverTimeString), false);
    if (Math.abs(diffMS - clientCompensation) > MIN_TOLERABLE_CLOCK_DRIFT_MS) {
      Clock._checkAndSetClockCompensation(diffMS);
    }
  }

  // return a difference in milliseconds between now and the given date object
  // if diff < 0, passed-in date is in the past
  // if diff > 0, passed-in date is in the future
  static diff(dateObj, compensateForClientOffset = true) {
    if (!dateObj) {
      return null;
    }
    return dateObj.getTime() - Clock.now(compensateForClientOffset).getTime();
  }

  // parse the date string and return a Date object.
  static parse(dString) {
    return new Date(dString);
  }

  // format returns a concise string of the given time, such as
  // "1/2/06 3:04pm".
  static format(d, withTime = true) {
    let mm = 1 + d.getMonth();
    let dd = d.getDate();
    let yy = `${d.getFullYear()}`.substr(-2);
    if (withTime) {
      return `${mm}/${dd}/${yy} ${time(d)}`;
    } else {
      return `${mm}/${dd}/${yy}`;
    }
  }

  static formatTime(d) {
    return time(d);
  }

  // format returns a short string of the given time, such as
  // "1/2/2006 3:04pm MST".
  static formatShort(d) {
    let mm = 1 + d.getMonth();
    let dd = d.getDate();
    let yyyy = d.getFullYear();
    return `${mm}/${dd}/${yyyy} ${time(d)} ${tz(d)}`;
  }

  // format returns a full string of the given time, such as
  // "Monday, January 2nd, 2006 at 3:04pm MST".
  static formatFull(d) {
    let dow = days[d.getDay()];
    let month = months[d.getMonth()];
    let day = Util.ordinalize(d.getDate());
    let year = d.getFullYear();
    return `${dow}, ${month} ${day}, ${year} at ${time(d)} ${tz(d)}`;
  }

  // parses a x:y:z formatted timestamp into a number of seconds
  static formattedToSeconds(s) {
    var parts = s.split(':');
    var seconds = 0;
    var j = 0;
    for (var i = parts.length - 1; i >= 0; --i) {
      seconds += parseInt(parts[i], 10) * Math.pow(60, j++);
    }
    return isNaN(seconds) ? 0 : seconds;
  }

  // format returns a relative string of the given time, such as
  // "about 1 hour ago".
  static formatRelative(d) {
    if (!d) return;
    let today = new Date();
    let delta = (d.getTime() - this.now().getTime()) / 1000;
    let wrap = (s) => `in ${s}`;
    if (delta < 0) {
      delta = -delta;
      wrap = (s) => `${s} ago`;
    }
    if (delta < 30) {
      return wrap('less than a minute');
    } else if (delta < 90) {
      return wrap('1 minute');
    } else if (delta < 59 * 60 + 30) {
      return wrap(`${Math.round(delta / 60)} minutes`);
    } else if (delta < 24 * 3600 - 30 && today.getDate() == d.getDate()) {
      return 'today';
    } else if (delta < 48 * 3600 - 30 && today.getDate() == d.getDate() - 1) {
      return 'tomorrow';
    } else {
      return Clock.format(d, false);
    }
  }

  // returns the h:mm:ss formatted duration from the given amount of seconds
  static durationFormatted(diffSec, mode = 'hh:mm:ss') {
    let diffMin = diffSec / 60 || 0;
    let ss = parseInt(diffSec % 60 | 0, 10);
    if (ss == 60) {
      ss = 59; //avoid rounding up at end of minute
    }
    let mm = parseInt(diffMin % 60 | 0, 10);
    if (mm == 60) {
      mm = 59; //avoid rounding up at end of hour
    }
    let h = (diffMin / 60) | 0;
    if (mode == 'hh:mm:ss') {
      if (ss < 10) ss = `0${ss}`;
      if (mm < 10) mm = `0${mm}`;
      if (h) {
        return `${h}:${mm}:${ss}`;
      } else {
        return `${mm}:${ss}`;
      }
    } else {
      if (h) {
        return `${h}h ${mm}m ${ss}s`;
      } else if (diffSec > 90) {
        return `${mm}m ${ss}s`;
      } else {
        return `${diffSec} sec`;
      }
    }
  }

  static _checkAndSetClockCompensation(currentDiffMS) {
    // If we've already adjusted from the API headers in this session, don't do it again, as it's unlikely
    // that the local clock has radically changed; and even if it has, the viewer analytics service will just
    // "bound" it into a rational window.
    if (adjustedFromAPIHeaders) {
      return;
    }

    let cacheBustHeaders = new Headers();
    cacheBustHeaders.append('pragma', 'no-cache');
    cacheBustHeaders.append('cache-control', 'no-cache');
    const url = `${
      Config.dict.apiRoot
    }?reason=clock-compensation&diffMS=${currentDiffMS}&cb=${Math.random()}`;
    fetch(url, { headers: cacheBustHeaders })
      .then((resp) => {
        if (!resp.ok) {
          console.warn('Failed to get API root:', resp);
          return resp;
        }

        var serverDate = resp.headers.get('Date');
        if (!serverDate) {
          return resp;
        }

        let diffMS = Clock.diff(Clock.parse(serverDate), false);
        if (Math.abs(diffMS - clientCompensation) > MIN_TOLERABLE_CLOCK_DRIFT_MS) {
          clientCompensation = diffMS;
        }
        adjustedFromAPIHeaders = true;
        return resp;
      })
      .catch((err) => {
        console.warn('Failed to get API root:', err);
      });
  }
}

const days = 'Sunday Monday Tuesday Wednesday Thursday Friday Saturday'.split(' ');
const months = 'January February March April May June July August September October November December'.split(
  ' '
);

function time(d) {
  let hour = d.getHours() % 12 || 12;
  let minute = `0${d.getMinutes()}`.substr(-2);
  let meridian = d.getHours() < 12 ? 'am' : 'pm';
  return `${hour}:${minute}${meridian}`;
}

function tz(d) {
  let s = d.toString();
  let m = s.match(/\([^\)]+\)/);
  if (m) {
    m = m[0].match(/[A-Z]/g);
    if (m) {
      return m.join('');
    }
  }
  m = s.match(/([A-Z]{3,4})(\W*\d{4})?/);
  if (m) {
    if (m[1] === 'GMT') {
      return m[0];
    } else {
      return m[1];
    }
  }
  return 'Local';
}
