// Context represents a load point in the DOM for a widget. It is used to
// select a single DOM element and then load a widget. Multiple widgets
// cannot be loaded into the same DOM element without first unloading any
// previously loaded widget.

import React from 'react';
import ReactDOM from 'react-dom';
import Promise from 'bluebird';

import { ModelFactory } from './models';
import { gaSafely } from './ga';
import Config from './config';
import BoxOfficeLayout from './layouts/box-office.jsx';
import HighlightsLayout from './layouts/highlights.jsx';
import Redirects from './redirects';

const importLowLatencyLayout = () => import('./layouts/low-latency.tsx');

import './styles/boxcast.scss';

export default class Context {
  constructor(selector, boxcast) {
    this._selector = selector;
    this._boxcast = boxcast;
    this._model = null;
    this._layout = null;
    this._initialHtml = null;
  }

  // loadChannel takes arguments to initialize a new channel and returns a
  // promise to load the channel widget.
  loadChannel(channelId, args) {
    return gaSafely('context.loadChannel', [channelId, args], () => {
      if (this._model && this._layout && this._el) {
        console.warn('Trying to load channel on top of existing channel. Will clear out original.');
        this.unload();
      }

      args = mungeArgs(args);
      Config.seed(args);

      ({ channelId, args } = Redirects.applyRedirectRulesOnLoad(channelId, args));

      this._model = ModelFactory(channelId, args, 'channel');

      // Support debuggability from console devtools
      if (args.debug) {
        this._boxcast.debug = true;
      }
      this._boxcast.model = this._model;

      var promise = this.load(BoxOfficeLayout, {
        userArgs: args || {},
        model: this._model,
      });

      promise.then(() => this._model && this._model.Actions.LoadChannel(channelId, args));
      return promise;
    });
  }

  // loadChannel takes arguments to initialize a new highlight view of a channel
  // and returns a promise to load the highlight widget.
  loadHighlights(channelId, args) {
    return gaSafely('context.loadHighlights', [channelId, args], () => {
      if (this._model && this._layout && this._el) {
        console.warn('Trying to load channel on top of existing channel. Will clear out original.');
        this.unload();
      }

      args = mungeArgs(args);
      Config.seed(args);

      this._model = ModelFactory(channelId, args, 'highlights');

      // Support debuggability from console devtools
      if (args.debug) {
        this._boxcast.debug = true;
      }
      this._boxcast.model = this._model;

      var promise = this.load(HighlightsLayout, {
        userArgs: args || {},
        model: this._model,
        showList: true,
      });

      promise.then(() => this._model && this._model.Actions.LoadHighlights(channelId, args));
      return promise;
    });
  }

  // loadHighlight takes arguments to initialize a new view of a single highlight
  // and returns a promise to load the highlight widget.
  loadHighlight(highlightId, args) {
    return gaSafely('context.loadHighlight', [highlightId, args], () => {
      if (this._model && this._layout && this._el) {
        console.warn('Trying to load channel on top of existing channel. Will clear out original.');
        this.unload();
      }

      args = mungeArgs(args);
      Config.seed(args);

      this._model = ModelFactory(highlightId, args, 'highlight');

      // Support debuggability from console devtools
      if (args.debug) {
        this._boxcast.debug = true;
      }
      this._boxcast.model = this._model;

      var promise = this.load(HighlightsLayout, {
        userArgs: args || {},
        model: this._model,
        showList: false,
      });

      promise.then(() => this._model && this._model.Actions.LoadHighlight(highlightId, args));
      return promise;
    });
  }

  // loadLowLatency takes arguments to initialize a new view of a single broadcast
  loadLowLatency(rtmpUrl, args) {
    return gaSafely('context.loadLowLatency', [rtmpUrl, args], () => {
      if (this._model && this._layout && this._el) {
        console.warn('Trying to load channel on top of existing channel. Will clear out original.');
        this.unload();
      }

      args = mungeArgs(args);
      Config.seed(args);
      this._model = {};

      if (args.debug) {
        this._boxcast.debug = true;
      }
      this._boxcast.model = this._model;

      var props = {
        rtmpUrl: rtmpUrl,
        userArgs: args || {},
        model: this._model,
      };
      return importLowLatencyLayout().then(({ default: LowLatencyLayout }) => {
        return this.load(LowLatencyLayout, props);
      });
    });
  }

  // Deprecated functions from v2.
  loadScoreboard(...args) {
    console.error(
      'WARNING: boxcast(...).loadScoreboard has been removed. Please contact support@boxcast.com for assistance.'
    );
  }

  // load returns a promise to render the given React layout into the DOM
  load(reactComponent, props) {
    var el = document.querySelectorAll(this._selector);

    if (el.length <= 0) {
      return Promise.reject(`selector "${this._selector}" did not match any elements`);
    } else if (el.length > 1) {
      return Promise.reject(`selector "${this._selector}" matched more than one element`);
    } else if ((el = el[0]).className.indexOf('boxcast-widget') >= 0) {
      return Promise.reject(`selector "${this._selector}" has already been loaded`);
    }

    this._el = el;
    this._initialHtml = el.innerHTML;
    this._layout = reactComponent;

    return new Promise((resolve, reject) => {
      var layout = React.createElement(reactComponent, props);
      ReactDOM.render(layout, el, resolve);
    });
  }

  // unload removes any previously loaded widget and restores the DOM to its
  // previous state.
  unload() {
    if (this._layout) {
      ReactDOM.unmountComponentAtNode(this._el);
      this._layout = undefined;
    }

    // dispose of reflux actions/stores
    if (this._model) {
      this._model = undefined;
    }

    if (this._el) {
      this._el.innerHtml = this._initialHtml;
      this._el.className = this._el.className.replace('boxcast-widget', '');
      this._el = undefined;
    }
  }
}

function mungeArgs(args) {
  // Clean up defaults in the args to be consistent with how the rest of the application
  // expects them (e.g. missing args that default to true)
  if (typeof args.dvr == 'undefined') {
    args.dvr = true;
  }
  // Fix deprecated `showAgenda`
  if (args.showAgenda) {
    args.showDocuments = true;
    args.showIndex = true;
  }
  return args;
}
