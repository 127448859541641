import React from 'react';
import NonBreakingSpace from '../library/nbsp.jsx';

export default function BroadcastListSkeleton(props) {
  const { pageSize } = props;
  const className = 'boxcast-playlist-item boxcast-selectable';
  const items = [];
  for (var i = 0; i < pageSize; i++) {
    items.push(i + 1);
  }
  return (
    <div className="boxcast-playlist-list boxcast-playlist-loading-skeleton">
      <ul>
        {items.map((i) => (
          <li className={className} key={i}>
            <div className="boxcast-preview-icon"></div>
            <div className="boxcast-playlist-item-meta">
              <h3 style={{ width: '50%' }}>
                <NonBreakingSpace />
              </h3>
              <p style={{ width: '100%' }}>
                <NonBreakingSpace />
              </p>
              <p style={{ width: '25%' }}>
                <NonBreakingSpace />
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
