//
//  <BroadcastStartStopTime broadcast={broadcast} />
//
import React from 'react';
import PropTypes from 'prop-types';

import { Clock } from '../../utils';
import { duration, timeframe } from '../../utils/broadcast';

class BroadcastStartStopTime extends React.Component {
  render() {
    const { broadcast, mode } = this.props;
    const t = timeframe(broadcast);

    const start = Clock.format(Clock.parse(broadcast.starts_at));
    let stop = Clock.format(Clock.parse(broadcast.stops_at));
    if ((broadcast.stops_at || '').startsWith('9999')) {
      stop = '';
    }

    var text;
    if (t == 'current') {
      text = this.context.m('start_stop_description_current', start, stop);
    } else if (t == 'future') {
      text = this.context.m('start_stop_description_future', start, stop);
    } else if (broadcast.starts_at && broadcast.stops_at) {
      text = this.context.m('start_stop_description_past', start, stop);
    } else {
      return <span />;
    }

    // Handle broadcasts with indefinite stops_at (e.g. simulated live)
    if (text.endsWith(' - ')) {
      text = text.replace(' - ', '');
    } else if (text.endsWith(' (ending )')) {
      text = text.replace(' (ending )', '');
    }

    if (mode == 'inline') {
      return <span>{text}</span>;
    } else {
      return <p className="boxcast-start-stop">{text}</p>;
    }
  }
}

BroadcastStartStopTime.contextTypes = {
  m: PropTypes.func,
};

export default BroadcastStartStopTime;
