// The Geoblock store deals with region restrictions.
import Reflux from 'reflux';
import { GeoBlock, Util } from '../utils';
import { gaEvent } from '../ga';

export var GeoBlockStoreFactory = (Actions, UserArgsStore, CurrentChannelStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      GeoBlock.initGA(gaEvent);
      this.geoblock = UserArgsStore.geoblock || {};
      this.isLoading = false;
      this.isRestricted = false;
      this._check();
    },

    // Action bindings
    onContentSettingsChanged: function (newArgs) {
      var newGeoblock = newArgs.geoblock || {};
      if (newGeoblock == {} && this.geoblock != {}) {
        // Switching to no geoblock required from existing geoblock configuration
        this.geoblock = {};
        this.isRestricted = false;
        this.isLoading = false;
        this.trigger();
      } else if (newGeoblock) {
        // Enabling geoblock
        if (Util.areAnyChanged(this.geoblock, newGeoblock)) {
          this.geoblock = newGeoblock;
          this._check();
        }
      }
    },

    lastFound: function () {
      return GeoBlock.lastFound;
    },

    // Private methods
    _check: function () {
      if (Util.areAnyChanged({}, this.geoblock)) {
        this.isLoading = true;
        this.trigger();
        GeoBlock.isCurrentUserAllowed(this.geoblock)
          .then((isAllowed) => {
            this.isRestricted = !isAllowed;
            this.isLoading = false;
            this.trigger();
            if (this.isRestricted && CurrentChannelStore.callbacks.onGeoBlockRestricted) {
              // Call this outside our stack to avoid client exceptions bubbling into here
              setTimeout(() => CurrentChannelStore.callbacks.onGeoBlockRestricted(), 0);
            } else if (!this.isRestricted && CurrentChannelStore.callbacks.onGeoBlockAllowed) {
              // Call this outside our stack to avoid client exceptions bubbling into here
              setTimeout(() => CurrentChannelStore.callbacks.onGeoBlockAllowed(), 0);
            }
          })
          .catch((err) => {
            console.error(err);
            this.isRestricted = true;
            this.isLoading = false;
            this.trigger();
            if (this.isRestricted && CurrentChannelStore.callbacks.onGeoBlockRestricted) {
              // Call this outside our stack to avoid client exceptions bubbling into here
              setTimeout(() => CurrentChannelStore.callbacks.onGeoBlockRestricted(), 0);
            } else if (!this.isRestricted && CurrentChannelStore.callbacks.onGeoBlockAllowed) {
              // Call this outside our stack to avoid client exceptions bubbling into here
              setTimeout(() => CurrentChannelStore.callbacks.onGeoBlockAllowed(), 0);
            }
          });
      } else {
        this.isLoading = false;
        this.isRestricted = false;
      }
    },
  });
