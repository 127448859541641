import React from 'react';
import { Logger } from '../../utils';

const logger = Logger.getInstance('player');

export default class RatePicker extends React.Component {
  componentDidMount() {
    // on first mounting of this component, it's come into view so we should focus on it for
    // improved keyboard accessibility
    this.refs.backButton.focus();
  }
  render() {
    const { currentRate, rates } = this.props;
    return (
      <ul className="boxcast-clappr-menu">
        <li>
          <span className="boxcast-clappr-menu-title">Speed</span>
          <button
            ref="backButton"
            type="button"
            aria-label="Back"
            className="boxcast-unstyled-button boxcast-clappr-menu-icon-left"
            onClick={() => this.props.onBack()}
          >
            <i className="boxcast-icon-chevron-left"></i>
          </button>
        </li>
        {rates.map((rate) => {
          let selected = rate.id == currentRate;
          return (
            <li key={rate.id} className={selected ? 'link active' : 'link'}>
              <button
                type="button"
                aria-label={`Change rate to ${rate.label}`}
                className="boxcast-unstyled-button"
                onClick={() => this.props.onChangeRate(rate.id)}
              >
                <span className="boxcast-clappr-menu-label">
                  <span className={'boxcast-clappr-menu-rate-label'}>{rate.label}</span>
                  {rate.tag ? (
                    <span className={'boxcast-clappr-menu-rate-tag'}>{rate.tag}</span>
                  ) : (
                    ''
                  )}
                </span>
                <span className="boxcast-clappr-menu-icon-right">
                  <i className={selected ? 'boxcast-icon-check' : ''}></i>
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    );
  }
}
