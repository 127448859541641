import { Clock } from '../../utils';
import { UICorePlugin, Events, $ } from '@clappr/player';
import './cue-points.scss';

export default class CuePointsPlugin extends UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }

  static get Marker() {
    return StandardMarker;
  }

  get name() {
    return 'cue-points-plugin';
  }

  get attributes() {
    return { class: this.name };
  }

  constructor(core) {
    super(core);
    this._markers = [];
    this._duration = null;
    this._createInitialMarkers();
  }

  bindEvents() {
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_RENDERED,
      this._onMediaControlRendered
    );
    this.listenTo(
      this.core,
      Events.CORE_ACTIVE_CONTAINER_CHANGED,
      this._onMediaControlContainerChanged
    );
    this.listenTo(this.core, 'boxcast:cue-points:update-cues', this._onUpdateCues);
  }

  _bindContainerEvents() {
    if (this._container) {
      this.stopListening(this._container, Events.CONTAINER_TIMEUPDATE, this._onTimeUpdate);
      this.stopListening(
        this._container,
        Events.CONTAINER_MEDIACONTROL_SHOW,
        this._onMediaControlShow
      );
    }
    this._container = this.core.activeContainer;
    this.listenTo(this._container, Events.CONTAINER_TIMEUPDATE, this._onTimeUpdate);
    this.listenTo(this._container, Events.CONTAINER_MEDIACONTROL_SHOW, this._onMediaControlShow);
  }

  _getOptions() {
    if (!('cuePointsPlugin' in this.core.options)) {
      throw "'cuePointsPlugin' property missing from options object.";
    }
    return this.core.options.cuePointsPlugin;
  }

  // build a marker object for internal use from the provided Marker
  _buildInternalMarker(marker) {
    var $tooltip = marker.getTooltipEl();
    if ($tooltip) {
      $tooltip = $($tooltip);
    }
    return {
      source: marker,
      emitter: marker.getEmitter(),
      $marker: $(marker.getMarkerEl()),
      markerLeft: null,
      $tooltip: $tooltip,
      $tooltipContainer: null,
      tooltipContainerLeft: null,
      tooltipContainerBottom: null,
      tooltipChangedHandler: null,
      time: marker.getTime(),
      timeChangedHandler: null,
      onDestroy: marker.onDestroy,
    };
  }

  _createInitialMarkers() {
    var markers = (this._getOptions().cues || []).map(
      (c) => new StandardMarker(Clock.formattedToSeconds(c.playback_start_time_fmt), c.name)
    );
    this._markers = [];
    markers.forEach((a) => {
      this._markers.push(this._buildInternalMarker(a));
    });

    // append the marker elements to the dom
    this._markers.forEach((marker) => {
      this._createMarkerEl(marker);
    });
    this._renderMarkers();
  }

  _createMarkerEl(marker) {
    // marker
    var $marker = marker.$marker;
    marker.timeChangedHandler = () => {
      // fired from marker if it's time changes
      this._updateMarkerTime(marker);
    };
    marker.emitter.on('timeChanged', marker.timeChangedHandler);
    $marker.click((e) => {
      // when marker clicked seek to the exact time represented by the marker
      this._container.seek(marker.time);
      e.preventDefault();
      e.stopImmediatePropagation();
    });
    this._$markers.append($marker);

    // tooltip
    var $tooltip = marker.$tooltip;
    if ($tooltip) {
      // there is a tooltip
      let $tooltipContainer = $('<div />').addClass('tooltip-container');
      marker.$tooltipContainer = $tooltipContainer;
      $tooltipContainer.append($tooltip);
      this._$tooltips.append($tooltipContainer);
      marker.tooltipChangedHandler = () => {
        // fired from marker if it's tooltip contents changes
        this._updateTooltipPosition(marker);
      };
      marker.emitter.on('tooltipChanged', marker.tooltipChangedHandler);
      this._updateTooltipPosition(marker);
    }
  }

  _updateMarkerTime(marker) {
    marker.time = marker.source.getTime();
    this._renderMarkers();
  }

  // calculates and sets the position of the tooltip
  _updateTooltipPosition(marker) {
    if (!this._mediaControlContainerLoaded || !this._duration) {
      // renderMarkers() will be called when it has loaded, which will call this
      return;
    }
    var $tooltipContainer = marker.$tooltipContainer;
    if (!$tooltipContainer) {
      // no tooltip
      return;
    }
    var bottomMargin = this._getOptions().tooltipBottomMargin || 17;
    var width = $tooltipContainer.width();
    var seekBarWidth = this._$tooltips.width();
    var leftPos = seekBarWidth * (marker.time / this._duration) - width / 2;
    leftPos = Math.max(0, Math.min(leftPos, seekBarWidth - width));

    if (bottomMargin !== marker.tooltipContainerBottom || leftPos !== marker.tooltipContainerLeft) {
      $tooltipContainer.css({
        bottom: bottomMargin + 'px',
        left: leftPos + 'px',
      });
      marker.tooltipContainerBottom = bottomMargin;
      marker.tooltipContainerLeft = leftPos;
    }
  }

  _onMediaControlRendered() {
    this._appendElToMediaControl();
  }

  _updateDuration() {
    this._duration = this._container.getDuration() || null;
  }

  _onMediaControlContainerChanged() {
    this._bindContainerEvents();
    this._mediaControlContainerLoaded = true;
    this._updateDuration();
    this._renderMarkers();
  }

  _onTimeUpdate() {
    // need to render on time update because if duration is increasing
    // markers will need to be repositioned
    this._updateDuration();
    this._renderMarkers();
  }

  _onMediaControlShow() {
    this._renderMarkers();
  }

  _onUpdateCues(newCues) {
    this.destroy();
    this._$markers.remove();
    this._$tooltips.remove();
    this.render();
    this._getOptions().cues = newCues;
    this._createInitialMarkers();
  }

  _renderMarkers() {
    if (!this._mediaControlContainerLoaded || !this._duration) {
      // this will be called again once loaded, or there is a duration > 0
      return;
    }
    this._markers.forEach((marker) => {
      let $el = marker.$marker;
      let percentage = Math.min(Math.max((marker.time / this._duration) * 100, 0), 100);
      if (marker.markerLeft !== percentage) {
        $el.css('left', percentage + '%');
        marker.markerLeft = percentage;
      }
      this._updateTooltipPosition(marker);
    });
  }

  _appendElToMediaControl() {
    this.core.mediaControl.$el.find('.bar-container').first().append(this.el);
  }

  render() {
    this._$markers = $('<div />').addClass('cue-points-plugin-markers');
    this._$tooltips = $('<div />').addClass('cue-points-plugin-tooltips');
    var $el = $(this.el);
    $el.append(this._$markers);
    $el.append(this._$tooltips);
    this._appendElToMediaControl();
    return this;
  }

  destroy() {
    // remove any listeners and call onDestroy()
    this._markers.forEach((marker) => {
      if (marker.tooltipChangedHandler) {
        marker.emitter.clear();
      }
      marker.onDestroy();
    });
    this._markers = [];
  }
}

class EventEmitter {
  constructor() {
    this._subs = {};
  }
  on(event, handler) {
    if (!this._subs[event]) {
      this._subs[event] = [];
    }
    this._subs[event].push(handler);
  }
  emit(event) {
    var handlers = this._subs[event] || [];
    handlers.forEach((h) => setTimeout(h, 0));
  }
  clear() {
    this._subs = {};
  }
}

/*
 * This represents and Marker and should be extended.
 */
class Marker {
  constructor() {
    this._emitter = new EventEmitter({});
  }

  /*
   * Get the event emitter.
   * Used by the plugin and notifyTooltipChanged() method
   */
  getEmitter() {
    return this._emitter;
  }

  /*
   * Call this to notify the plugin that the time of the marker
   * has changed so that it's position can be recalculated and changed
   * if necessary.
   */
  notifyTimeChanged() {
    this._emitter.emit('timeChanged');
  }

  /*
   * Call this to notify the plugin that the contents of the tooltip
   * has changed so that it's position will be recalculated and changed
   * if necessary.
   */
  notifyTooltipChanged() {
    this._emitter.emit('tooltipChanged');
  }

  /*
   * Should return the time (in seconds) that this marker represents.
   */
  getTime() {
    throw 'Not implemented!';
  }

  /*
   * Should return the dom element which should represent the marker.
   * It will be inserted onto the seek bar and kept at the correct location.
   */
  getMarkerEl() {
    throw 'Not implemented!';
  }

  /*
   * Should return the dom element which is the tool tip,
   * or null if there is no tool tip for this marker.
   *
   * The tooltip will placed above the marker element, inside a container,
   * and this containers position will be managed for you.
   */
  getTooltipEl() {
    throw 'Not implemented!';
  }

  /*
   * Called when the marker is removed.
   */
  onDestroy() {
    // default to doing nothing
  }
}

class BaseMarker extends Marker {
  constructor(time) {
    super();
    this._time = time;
    this._$marker = this._buildMarkerEl();
    this._$tooltip = null;
  }

  /*
   * Should return the time (in seconds) that this marker represents.
   */
  getTime() {
    return this._time;
  }

  /*
   * Set a new time for this marker.
   */
  setTime(time) {
    this._time = time;
    this.notifyTimeChanged();
  }

  /*
   * Should return the dom element which should represent the marker.
   * It will be inserted onto the seek bar and kept at the correct location.
   */
  getMarkerEl() {
    return this._$marker;
  }

  /*
   * Should return the dom element which is the tool tip,
   * or null if there is no tool tip for this marker.
   *
   * The tooltip will placed above the marker element, inside a container,
   * and this containers position will be managed for you.
   */
  getTooltipEl() {
    return this._$tooltip;
  }

  _buildMarkerEl() {
    var $marker = $('<div />').addClass('standard-marker');
    $marker.append($('<div />').addClass('standard-marker-inner'));
    return $marker;
  }

  /*
   * Set the tooltip element for this marker.
   *
   * The tooltip will placed above the marker element, inside a container,
   * and this containers position will be managed for you.
   */
  _setTooltipEl($el) {
    if (this._$tooltip) {
      throw new Error('Tooltip can only be set once.');
    }
    this._$tooltip = $el;
    this._addListenersForTooltip();
  }

  _addListenersForTooltip() {
    if (!this._$tooltip) {
      return;
    }

    var $marker = this._$marker;
    var hovering = false;
    $marker.bind('mouseover', () => {
      if (hovering) {
        return;
      }
      hovering = true;
      this._$tooltip.attr('data-show', '1');
      this.notifyTooltipChanged();
    });

    $marker.bind('mouseout', () => {
      if (!hovering) {
        return;
      }
      hovering = false;
      this._$tooltip.attr('data-show', '0');
    });
  }
}

class StandardMarker extends BaseMarker {
  /*
   * time: the time in seconds that this marker represents
   * tooltipText: the text to be shown on the tooltip (optional)
   */
  constructor(time, tooltipText) {
    super(time);
    this._tooltipText = tooltipText || null;
    this._tooltipText && this._setTooltipEl(this._buildTooltipEl());
  }

  _buildTooltipEl() {
    return $('<div />').addClass('standard-tooltip').text(this._tooltipText);
  }
}
