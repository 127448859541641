import Reflux from 'reflux';
import Promise from 'bluebird';

import API from '../api';
import Config from '../config';
import { Logger, Util } from '../utils';
import { canProbablyPlayHLSByteranges } from '../utils/browser';

const logger = Logger.getInstance('models');
const IGNORE_REQUEST_ERROR = 'ignore_request_error';

export var SelectedBroadcastStoreFactory = (
  contextType,
  Actions,
  CurrentChannelStore,
  TicketStore,
  UserArgsStore
) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.id = null;
      this.broadcast = {};
      this.view = {};
      this.highlights = [];
      this.selectedHighlight = null;
      this.ticket = null;
      this.timeLimitedPreview = false;
      this.timeLimitedPreviewExpired = false;
      this.freeVariant = null;
      this.loading = false;
      this.playing = false;
      this.ended = false;
      this.playbackError = null;
    },

    // Public methods
    isFlex: function () {
      var freeVariant = this.freeVariant || this.broadcast.free_variant;
      return freeVariant && freeVariant != 'none';
    },

    getContentSettings: function () {
      return this.view.settings || this.broadcast.content_settings || {};
    },

    getDocuments: function () {
      var cs = this.getContentSettings();
      if (cs.documents) {
        return cs.documents;
      } else if (cs.agenda_url) {
        return [{ name: 'Agenda', url: cs.agenda_url }]; //backward compatability
      } else {
        return [];
      }
    },

    isBlocked() {
      const blockedAccountIDs = Config.get('blockedAccountIDs');
      if (blockedAccountIDs.indexOf(this.broadcast.account_id) >= 0) {
        return true;
      } else if (this.view.playlist && this.view.playlist.indexOf('boxcast.com') < 0) {
        return true;
      } else {
        return false;
      }
    },

    // Action bindings
    onLoadChannel: function () {
      logger.log('SelectedBroadcastStore#onLoadChannel');
      this.loading = true; //when you load a new channel, the selected broadcast store will need to start loading
      this.trigger();
    },
    onLoadChannelError: function () {
      logger.log('SelectedBroadcastStore#onLoadChannelError');
      this.loading = false;
      this.trigger();
    },
    onSelectBroadcast: function (newBroadcast, args) {
      logger.log('SelectedBroadcastStore#onSelectBroadcast', newBroadcast, args);

      newBroadcast = newBroadcast || {};
      args = args || {};

      if (args.force) {
        logger.log('Forcing replay of broadcast');
      } else if (
        this.id === newBroadcast.id &&
        args.timeLimitedPreview === this.timeLimitedPreview &&
        args.freeVariant === this.freeVariant
      ) {
        logger.log('No-op: broadcast is not changed');
        this.trigger();
        return null;
      } else if (!newBroadcast.id) {
        logger.log('No-op: broadcast is empty');
        this.trigger();
        return null;
      }

      // Reset state
      this.id = newBroadcast.id;
      this.broadcast = newBroadcast;
      this.timeLimitedPreview = !!args.timeLimitedPreview;
      this.timeLimitedPreviewExpired = !!args.timeLimitedPreviewExpired;
      this.freeVariant = args.freeVariant;
      this.view = {};
      this.highlights = [];
      this.selectedHighlight = null;
      this.loading = true;
      this.playing = false;
      this.playbackError = null;
      this.ended = false;
      this.trigger();
      this.ticket = TicketStore.getTicket(this.broadcast);
      this.autoplay = args.autoplay || this.autoplay;

      // Grab the full broadcast meta and playlist information
      var promise = this._getFullMetadataPromise();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      return promise
        .then((result) => {
          if (!isThisRequestStillValid()) throw IGNORE_REQUEST_ERROR;
          this.broadcast = result.broadcast;
          this.highlights = result.highlights || [];
          if (CurrentChannelStore.callbacks.onLoadBroadcast) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => CurrentChannelStore.callbacks.onLoadBroadcast(this.broadcast), 0);
          }
          return result.view;
        })
        .then((view) => {
          Actions.UpdateContentSettings(view.settings || {});
          return view;
        })
        .then((view) => {
          // Support selecting the current broadcast within the playlist from an
          // outside config.
          var selectedHighlight = null;
          if (args.selectedHighlightId) {
            this.highlights.forEach((h) => {
              if (h.id == args.selectedHighlightId) {
                selectedHighlight = h;
              }
            });
          }
          if (selectedHighlight) {
            Actions.PlayHighlight(selectedHighlight);
            return {};
          } else {
            return view;
          }
        })
        .then(this._ignorePlaylistIfNotYetReady)
        .then(this._saveViewOrRaiseIfError)
        .then(() => {
          this.loading = false;
          this.trigger();
        })
        .catch(this._handleViewError);
    },

    onRefreshSelectedBroadcastMeta: function () {
      // if there's no selected broadcast, don't try to get updated metadata
      if(!this.id) {
        // no broadcast has been selected
        logger.log('No broadcast has been selected. Aborting metadata sync request');
        return; 
      }
      
      // Grab the full broadcast meta and playlist information
      var promise = this._getFullMetadataPromise();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      return promise
        .then((result) => {
          if (!isThisRequestStillValid()) throw IGNORE_REQUEST_ERROR;
          this.broadcast = result.broadcast;
          this.highlights = result.highlights || [];
          return result.view;
        })
        .then((view) => {
          Actions.UpdateContentSettings(view.settings || {});
          return view;
        })
        .then(this._ignorePlaylistIfNotYetReady)
        .then(this._saveViewOrRaiseIfError)
        .then(() => {
          const callback = CurrentChannelStore.callbacks.onRefresh || UserArgsStore.onRefresh;
          if (callback) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => callback(this), 0);
          }
          this.trigger();
        })
        .catch(this._handleViewError);
    },

    onPlayHighlight: function (highlight) {
      logger.log('SelectedBroadcastStore#onPlayHighlight');

      this.selectedHighlight = highlight;
      this.view = {};
      this.loading = true;
      this.ended = false;
      this.timeLimitedPreview = false;
      this.timeLimitedPreviewExpired = false;
      this.freeVariant = null;
      this.trigger();

      // First, check if we need to load the broadcast meta
      var promise;
      if (!this.id || this.id != highlight.id) {
        promise = Promise.all([
          this._getViewPromise(),
          this._loadBroadcastMetaForHighlight(highlight),
        ]);
      } else {
        // Grab the full broadcast meta and playlist information
        promise = Promise.all([this._getViewPromise()]);
      }

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      return promise
        .then((viewAndMaybeBroadcast) => {
          if (!isThisRequestStillValid()) throw IGNORE_REQUEST_ERROR;
          return viewAndMaybeBroadcast[0];
        })
        .then(this._saveViewOrRaiseIfError)
        .then(() => {
          const callback =
            CurrentChannelStore.callbacks.onLoadHighlight || UserArgsStore.onLoadHighlight;
          if (callback) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => callback(this.selectedHighlight), 0);
          }
          this.loading = false;
          this.trigger();
        })
        .catch(this._handleViewError);
    },

    onReturnToBroadcastFromHighlight: function () {
      logger.log('SelectedBroadcastStore#onReturnToBroadcastFromHighlight');
      this.selectedHighlight = null;
      this.loading = true;
      this.ended = false;
      this.trigger();

      // Grab the full broadcast meta and playlist information
      var promise = this._getViewPromise();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      return promise
        .then((view) => {
          if (!isThisRequestStillValid()) throw IGNORE_REQUEST_ERROR;
          if (CurrentChannelStore.callbacks.onLoadBroadcast) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => CurrentChannelStore.callbacks.onLoadBroadcast(this.broadcast), 0);
          }
          return view;
        })
        .then(this._ignorePlaylistIfNotYetReady)
        .then(this._saveViewOrRaiseIfError)
        .then(() => {
          this.loading = false;
          this.trigger();
        })
        .catch(this._handleViewError);
    },

    onSwitchToPreview: function () {
      logger.log('SelectedBroadcastStore#onSwitchToPreview');
      return Actions.SelectBroadcast(this.broadcast, {
        timeLimitedPreview: true,
      });
    },

    onPreviewExpired: function () {
      logger.log('SelectedBroadcastStore#onPreviewExpired');
      if (!this.timeLimitedPreviewExpired) {
        this.timeLimitedPreviewExpired = true;
        if (this.broadcast.free_variant && this.broadcast.free_variant != 'none') {
          Actions.SelectBroadcast(this.broadcast, {
            timeLimitedPreview: this.timeLimitedPreview,
            timeLimitedPreviewExpired: this.timeLimitedPreviewExpired,
            freeVariant: this.broadcast.free_variant,
          });
        } else {
          this.trigger();
        }
      }
    },

    onTicketPurchaseCompleted: function (broadcast, channelTicket, ticket) {
      logger.log('SelectedBroadcastStore#onTicketPurchaseCompleted');
      if (broadcast.id == this.id) {
        this.timeLimitedPreviewExpired = false;
        this.ticket = ticket;
        if (UserArgsStore.autoOpenTicketWindow) {
          // disable preventative logic now that purchase is complete
          UserArgsStore.autoOpenTicketWindow = false;
          var result = Actions.SelectBroadcast(broadcast, {
            timeLimitedPreview: false,
          });
          if (result == null) {
            Actions.RefreshSelectedBroadcastMeta();
          }
        } else {
          this.timeLimitedPreview = false;
          Actions.RefreshSelectedBroadcastMeta();
          this.trigger();
        }
      } else {
        logger.warn(
          'Did broadcast change during ticket purchase?',
          this.broadcast,
          broadcast,
          ticket
        );
      }
    },

    onDonationCompleted: function () {
      if (UserArgsStore.autoOpenTicketWindow) {
        // disable preventative logic now that purchase is complete
        UserArgsStore.autoOpenTicketWindow = false;
        Actions.RefreshSelectedBroadcastMeta();
      }
    },

    onCurrentPlaybackEnded: function () {
      logger.warn('SelectedBroadcastStore#onCurrentPlaybackEnded');
      this.ended = true;
      this.trigger();
    },

    onPlaybackErrorOccurred: function (error) {
      logger.warn('SelectedBroadcastStore#onPlaybackErrorOccurred', error);
      // we need to store this separate from the `view` error model because
      // we don't know when we can remove it or keep track of the differences
      // between playback errors and server errors (e.g. ticket required)
      this.playbackError = error;
      this.trigger();
    },

    onPlaybackIsPlaying: function () {
      logger.log('SelectedBroadcastStore#onPlaybackIsPlaying');
      this.playbackError = null;
      this.ended = false;
      this.autoplay = true; // once we play the first time, all subsequent videos should autoplay
      this.trigger();
    },

    onRedirectToAllowedHost: function (url) {
      logger.log('SelectedBroadcastStore#onRedirectToAllowedHost', url);
      // If inside iframe, redirect the entire page (is this desired?)
      window.top.location.href = url;
      throw 'halt';
    },

    onWatchBroadcastAgain: function () {
      this.ended = false;
      Actions.RefreshSelectedBroadcastMeta();
    },

    // Private promise callback helpers
    _getFullMetadataPromise: function () {
      var promises = {
        broadcast: this._getBroadcastPromise(),
        view: this._getViewPromise(),
        highlights: this._shouldGetHighlights() ? this._getHighlightsPromise() : undefined,
      };
      return Promise.props(promises);
    },

    _shouldGetHighlights: function () {
      if (contextType == 'channel') {
        // If user is in normal Channel mode, we only want to check for highlights if
        // they've said they want them.
        return UserArgsStore.showHighlights;
      } else {
        // If we're in Highlights Grid or Single Highlight mode, we don't care about the
        // broadcast highlights (those are tracked elsewhere).
        return false;
      }
    },

    _getBroadcastPromise: function () {
      if (API.hasPreloadAvailable('broadcast')) {
        return API.fromPreload('broadcast');
      } else {
        return API.get(`/broadcasts/${this.id}`);
      }
    },

    _getHighlightsPromise: function () {
      if (API.hasPreloadAvailable('highlights')) {
        return API.fromPreload('highlights');
      } else {
        return API.get(`/broadcasts/${this.id}/highlights`, {
          s: UserArgsStore.highlightsSort || '-streamed_at',
        });
      }
    },

    _getViewPromise: function () {
      if (this._canUsePreloadedView()) {
        return API.fromPreload('view');
      } else if (this.selectedHighlight) {
        return API.get(
          `/highlights/${this.selectedHighlight.id}/view`,
          this._getQueryArgsForHighlightView()
        );
      } else {
        return API.get(`/broadcasts/${this.id}/view`, this._getQueryArgsForView());
      }
    },

    _canUsePreloadedView: function () {
      // XXX: The logic here roughly mirrors some of what is below, in _getQueryArgsForView. They should be kept in sync
      // when maintaining.

      // If there's no preloaded view available, then certainly not.
      if (!API.hasPreloadAvailable('view')) return false;

      // If there's auth stuff going on, then also no.
      if (UserArgsStore.auth) return false;

      // If there's trim stuff happening, sorry, no.
      if (UserArgsStore.trimSeconds || UserArgsStore.trim) return false;

      // Server-side (boxcast.tv) should have properly parsed and used the "dvr" parameter and ticket ID, so no need to
      // check those.

      // Also, timeLimitedPreview, timeLimitedPreviewExpired, and freeVariant shouldn't matter here, because boxcast.tv
      // should have gotten a 402 for any ticketed broadcast view request, and therefore shouldn't have pre-loaded a
      // view response.

      // Server-side (boxcast.tv) requests byterange playlists by default; so if this device probably _can't_ play
      // byterange playlists, then don't use the preloaded view.
      if (!canProbablyPlayHLSByteranges()) return false;

      // Otherwise... go for it!
      return true;
    },

    _getQueryArgsForView: function () {
      // TODO: decide if browser can support extended playlist (iOS, hls.js), in addition
      // to requiring the dvr setting on the embed widget

      // XXX: When updating logic in this function, also check to see if _canUsePreloadedView above needs to change.
      var query = {
        channel_id: CurrentChannelStore.id || this.broadcast.channel_id,
        host: UserArgsStore.hostname || window.location.host, //<-- NOTE: that `location.host` captures port when necessary
      };
      if (UserArgsStore.auth) {
        const { token, resource, requestor } = UserArgsStore.auth;
        if (token) query.auth_token = token;
        if (resource) query.auth_resource = resource;
        if (requestor) query.auth_requestor = requestor;
      }
      if (UserArgsStore.trimSeconds) {
        query.start_seconds = UserArgsStore.trimSeconds.start;
        query.stop_seconds = UserArgsStore.trimSeconds.stop;
      } else if (UserArgsStore.trim) {
        query.start_segment = UserArgsStore.trim.start;
        query.stop_segment = UserArgsStore.trim.stop;
      } else if (UserArgsStore.dvr) {
        query.extended = 'true';
      }
      if (this.timeLimitedPreview && !this.timeLimitedPreviewExpired) {
        query.preview = 'true';
      } else if (this.freeVariant) {
        query.variant = this.freeVariant;
      }
      var ticket = TicketStore.getTicket(this.broadcast);
      if (UserArgsStore.ticketId) {
        // passed-in (e.g. from boxcast.tv URL) always takes precendence
        query.ticket_id = UserArgsStore.ticketId;
      } else if (ticket) {
        query.ticket_id = ticket;
      }
      if (canProbablyPlayHLSByteranges()) {
        query.byteranges = 'true';
      }
      return query;
    },

    _getQueryArgsForHighlightView: function () {
      // Highlight's view request only supports a subset of the normal broadcast view request options
      var query = {
        host: UserArgsStore.hostname || window.location.host, //<-- NOTE: that `location.host` captures port when necessary
      };
      if (UserArgsStore.auth) {
        const { token, resource, requestor } = UserArgsStore.auth;
        if (token) query.auth_token = token;
        if (resource) query.auth_resource = resource;
        if (requestor) query.auth_requestor = requestor;
      }
      if (UserArgsStore.ticketId) {
        query.ticket_id = UserArgsStore.ticketId;
      }
      if (canProbablyPlayHLSByteranges()) {
        query.byteranges = 'true';
      }
      return query;
    },

    _ignorePlaylistIfNotYetReady: function (view) {
      if (view && view.playlist) {
        if (view.status.indexOf('live') < 0 && view.status.indexOf('recorded') < 0) {
          // Not yet ready; shouldn't start looking at this playlist.
          // If there was a previous playlist in place, keep it around so that
          // we can exhaust the playback buffer (especially important if in live dvr mode).
          logger.warn(
            'Playlist not yet ready; status is [',
            this.view.status,
            '] for ',
            view.playlist
          );
          view.playlist = this.view.playlist;
        }
      }
      return view;
    },

    _saveViewOrRaiseIfError: function (view) {
      this.view = view;
      if (this.view.error) {
        throw this.view;
      }
    },

    _loadBroadcastMetaForHighlight: function (highlight) {
      if (this.broadcast && this.broadcast.id == highlight.broadcast_id) {
        return; //no-op, already loaded
      }

      var promise = API.get(`/broadcasts/${highlight.broadcast_id}`);
      return promise.then((broadcast) => {
        this.id = broadcast.id;
        this.broadcast = broadcast;
        if (CurrentChannelStore.callbacks.onLoadBroadcast) {
          // Call this outside our stack to avoid client exceptions bubbling into here
          setTimeout(() => CurrentChannelStore.callbacks.onLoadBroadcast(this.broadcast), 0);
        }
      });
    },

    _handleViewError: function (viewError) {
      if (viewError == IGNORE_REQUEST_ERROR) {
        return;
      }
      viewError = viewError || {};
      logger.error(viewError, viewError.stack);

      if ((viewError.message || '').match(/Network request failed/i)) {
        viewError.error = 'client_disconnected';
        viewError.error_description = 'Your internet connection may have been lost.';
      } else {
        viewError.error = viewError.error || 'unhandled_exception';
        viewError.error_description =
          viewError.error_description ||
          'A server or network error occurred while loading the broadcast.';
      }
      viewError.playlist = this.view.playlist; //keep it around in case of network error so that video keeps exhausting buffer
      if (viewError.error == 'payment_required') {
        if (!this.timeLimitedPreview) {
          Actions.SwitchToPreview();
        } else {
          // Already switched previously; still catch here to avoid generic error handling behavior.
        }
        TicketStore.clearTicket(this.broadcast); //wipe out cached value if it's not working anymore
      } else if (viewError.error_description.indexOf('valid media token is required') >= 0) {
        if (CurrentChannelStore.callbacks.onTokenInvalid) {
          // Call this outside our stack to avoid client exceptions bubbling into here
          setTimeout(() => CurrentChannelStore.callbacks.onTokenInvalid(), 0);
        }
        this.view = viewError;
        this.loading = false;
        this.trigger();
      } else if (viewError.error_description.indexOf('<a href=') >= 0) {
        // API returned indication that a host restriction is in effect
        // Hold off on this redirect if we're in midst of trying to acquire a ticket as part of the
        // non-ssl redirect workflow
        if (UserArgsStore.autoOpenTicketWindow == 'ticket') {
          this.timeLimitedPreview = true;
          this.view = {
            error: `ticket_via_redirect`,
            error_description: 'Please complete the payment before proceeding.',
          };
          this.loading = false;
          this.trigger();
        } else if (UserArgsStore.autoOpenTicketWindow == 'donate' && TicketStore.modalOpen) {
          // Hold off on this redirect if we're in midst of trying to acquire donation
          this.view = {
            error: `donate_via_redirect`,
            error_description: 'Please complete the payment before proceeding.',
          };
          this.loading = false;
          this.trigger();
        } else {
          Actions.RedirectToAllowedHost(Util.getUrlFromLinkHtml(viewError.error_description));
        }
      } else {
        this.view = viewError;
        this.loading = false;
        this.trigger();
      }
    },
  });
