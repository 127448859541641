import React from 'react';
import PropTypes from 'prop-types';
import Redirects from '../../redirects';

class BuyTicketButton extends React.Component {
  constructor() {
    super();
    this.state = {
      isSSL: window.location.protocol == 'https:',
    };
  }

  render() {
    const { UserArgsStore } = this.context.model;
    const { env } = this.context;

    if (!this.state.isSSL) {
      if (env('allowNonSSLCheckout') || UserArgsStore.allowNonSSLCheckout) {
        return this.renderRealButton();
      } else {
        console.warn(
          'Current site is not SSL, will need to redirect to BoxCast to complete transaction'
        );
        return this.renderRedirectButton();
      }
    } else {
      return this.renderRealButton();
    }
  }

  redirectToPurchaseTicket() {
    alert(
      'You will now be redirected to the secure BoxCast web site to complete the ticket purchase...'
    );

    Redirects.redirectNowToPurchase({
      ticketPurchaseUrl: this.context.env('ticketPurchaseUrl'),
      channelId: this.getChannelInfo().channelId,
      ticketId: '~pay',
    });
  }

  renderRedirectButton() {
    return <span onClick={() => this.redirectToPurchaseTicket()}>{this.props.children}</span>;
  }

  showModal() {
    const { Actions } = this.context.model;
    Actions.OpenPaymentModal('ticket');
  }

  getChannelInfo() {
    const { CurrentChannelStore, SelectedBroadcastStore } = this.context.model;
    const { broadcast } = SelectedBroadcastStore;

    var channelId, channelTicket, ticketPrice, description;
    if (CurrentChannelStore.isTicketed) {
      channelId = CurrentChannelStore.id;
      channelTicket = true;
      ticketPrice = CurrentChannelStore.ticketPrice;
      description = `Ticket for ${CurrentChannelStore.name} channel`;
    } else {
      channelId = SelectedBroadcastStore.broadcast.channel_id;
      channelTicket = false;
      ticketPrice = broadcast.ticket_price;
      description = `Ticket for ${broadcast.name}`;
    }

    return { broadcast, channelId, channelTicket, ticketPrice, description };
  }

  renderRealButton() {
    return <span onClick={() => this.showModal()}>{this.props.children}</span>;
  }
}

BuyTicketButton.contextTypes = {
  model: PropTypes.object,
  env: PropTypes.func,
};

export default BuyTicketButton;
