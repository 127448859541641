///
/// This plugin is used by the Spectrum Android app, where they
/// are unable to convince the WebView to allow fullscreen and
/// instead need to handle it natively.
///
import { Events, UICorePlugin, Utils } from '@clappr/player';
import fullscreenIcon from '!svg-inline-loader!@clappr/plugins/src/icons/06-expand.svg';
import exitFullscreenIcon from '!svg-inline-loader!@clappr/plugins/src/icons/07-shrink.svg';

export default class FullscreenPlaceholderPlugin extends UICorePlugin {
  get name() {
    return 'fullscreen-placeholder';
  }

  get mediaControl() {
    return this.core.mediaControl;
  }

  bindEvents() {
    this.listenTo(this.mediaControl, Events.MEDIACONTROL_RENDERED, this.render);
  }

  onClickFullscreen() {
    const opts = this.options.fullscreenPlaceholder || {};
    try {
      opts.onClick && opts.onClick();
    } catch (e) {
      console.error(e);
    }
    this.setShrinkOrExpandIcon();
  }

  setShrinkOrExpandIcon() {
    const opts = this.options.fullscreenPlaceholder || {};
    try {
      const newState = opts.observeIsFullscreen && opts.observeIsFullscreen();
      this.btnFullscreen.html(newState ? exitFullscreenIcon : fullscreenIcon);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.mediaControl.$el.find('[data-fullscreen]:not(.fullscreen-placeholder)').length) {
      console.warn(
        'Fullscreen placeholder plugin requested, but native fullscreen found. Hiding native functionality.'
      );
      this.mediaControl.$el.find('[data-fullscreen]:not(.placeholder)').hide();
    }
    this.mediaControl.$el.find('.media-control-right-panel').prepend(`
      <button type="button" class="media-control-button media-control-icon placeholder" data-fullscreen aria-label="fullscreen"></button>
    `);
    this.btnFullscreen = this.mediaControl.$el.find('.media-control-right-panel [data-fullscreen]');
    this.btnFullscreen.on('click', () => this.onClickFullscreen());
    this.setShrinkOrExpandIcon();

    return this;
  }
}

FullscreenPlaceholderPlugin.type = 'core';
