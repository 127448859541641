import Config from './config';
import { Util, Clock, fetch } from './utils';

// API provides an access point for all AJAX interactions with the API server.
export default class API {
  static hasPreloadAvailable(key) {
    var p = (window.BOXCAST_PRELOAD || {})[key];
    return p && p.data;
  }

  static fromPreload(key, paginated = false) {
    if (API.hasPreloadAvailable(key)) {
      const object = Util.copyDict(window.BOXCAST_PRELOAD[key]);
      delete window.BOXCAST_PRELOAD[key];
      if (paginated) {
        return Promise.resolve({
          data: object.data,
          pagination: JSON.parse(object['X-Pagination'] || '{}'),
        });
      } else {
        return Promise.resolve(object.data);
      }
    } else {
      return Promise.reject(`${key} unavailable for preload`);
    }
  }

  // get returns a promise to fetch data from the API. It resolves with the
  // API's parsed response or rejects with an error object.
  static get(path, queryArgs, options) {
    var qs = queryArgs ? `?${Util.qsFromObj(queryArgs)}` : '';
    var args = {};
    if (Config.dict.apiAuthToken) {
      args.headers = {
        Authorization: `Bearer ${Config.dict.apiAuthToken}`,
      };
    }

    var promise = fetch(`${Config.dict.apiRoot}${path}${qs}`, args);
    if (options && options.onResponse) {
      //support custom behavior to transform the server response
      return promise.then(options.onResponse);
    } else {
      return promise.then(updateGlobalDataFromHeaders).then(parseJSON);
    }
  }

  // extract the pagination data out of the HTTP header and then separate
  // the normal response from the pagination
  static getWithPagination(path, queryArgs) {
    var options = {
      onResponse: function (response) {
        var pagination = response.headers.get('X-Pagination');
        return response.json().then((data) => {
          return {
            data: data,
            pagination: JSON.parse(pagination),
          };
        });
      },
    };
    return API.get(path, queryArgs, options);
  }

  // post returns a promise to send data to the API. It resolves with the
  // API's parsed response or rejects with an error object.
  static post(path, data) {
    var headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (Config.dict.apiAuthToken) {
      headers['Authorization'] = `Bearer ${Config.dict.apiAuthToken}`;
    }
    var args = {
      method: 'post',
      headers: headers,
      body: JSON.stringify(data),
    };

    return fetch(`${Config.dict.apiRoot}${path}`, args).then(parseJSON);
  }
}

// We use the API response headers for a few things
// 1. Synchronize client clock with the API, knowing many users are way out of whack
// 2. Grab the Remote IP address, which is echoed back from the server, for logging
function updateGlobalDataFromHeaders(response) {
  var serverDate = response.headers.get('Date');
  if (serverDate) {
    Clock.updateCompensationFromServerTime(serverDate);
  }
  var remoteIP = response.headers.get('boxcast-requester-address');
  if (remoteIP) {
    Config.add('clientRemoteIP', remoteIP);
  }
  return response;
}

function parseJSON(response) {
  return response.json();
}

/*
 * NOTE: We may not desire raising exceptions on non-200 responses.
 * For example, a 402 payment required actually contains the useful
 * error and error_description in the response body.
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
*/
