import React, { useState, useEffect } from 'react';

export default function CountdownTimer(props) {
  const calculateTimeLeft = () => {
    const totalSeconds = Math.max(0, props.date.getTime() - new Date().getTime()) / 1000;
    let delta = totalSeconds;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = Math.floor(delta % 60);
    return {
      totalSeconds: totalSeconds,
      seconds: seconds,
      minutes: minutes,
      hours: hours,
      days: days,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    return () => clearTimeout(timer);
  });

  if (timeLeft.totalSeconds < 60) {
    return (
      <div className="boxcast-countdown">
        <div className="boxcast-countdown-blocks">
          <div className="boxcast-countdown-message">
            Scheduled to start in less than one minute.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="boxcast-countdown">
      <div className="boxcast-countdown-blocks">
        <div className="boxcast-countdown-block">
          {timeLeft.days}
          <div className="boxcast-countdown-label">days</div>
        </div>
        <div className="boxcast-countdown-block">
          {timeLeft.hours}
          <div className="boxcast-countdown-label">hours</div>
        </div>
        <div className="boxcast-countdown-block">
          {timeLeft.minutes}
          <div className="boxcast-countdown-label">minutes</div>
        </div>
        <div className="boxcast-countdown-block">
          {timeLeft.seconds}
          <div className="boxcast-countdown-label">seconds</div>
        </div>
      </div>
    </div>
  );
}
