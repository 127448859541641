// The BrowserStorage class attempts to work around browser quirks to find
// the optimal storage medium for semi-persistent application data.  We prefer
// to use localStorage, but will fall back to session or memory as necessary.
//
// Some key things to consider:
// * Incognito mode handles storage differently on every browser. Sometimes
//   localStorage raises an exception, sometimes is fails silently.
// * Safari in iframe sandboxes the storage but makes it looks like
//   localStorage will work. This is an important anti-feature when trying to
//   handle non-ssl redirects where tickets are mediated by boxcast.tv but the
//   host site still wants to use an iframe.
// * All browsers have limits on the amount of data you can put in a given
//   storage medium. You might see a DOMQuotaExceeded if you violate it.
// * Limit the use of cookies to avoid unnecessary HTTP overhead.
import Basil from 'basil.js';

var basil = new Basil({
  namespace: '',
  storages: ['local', 'cookie', 'session', 'memory'],
  expireDays: 365,
});

export default class BrowserStorage {
  static get basil() {
    return basil;
  }

  // utilize proper storage class for user to grab a json-serialized item
  static getItem(key, defaultValue = {}) {
    var item = basil.get(key);
    if (item === undefined || item === null) {
      return defaultValue;
    } else {
      return item;
    }
  }

  // utilize proper storage class to store a json-serialized item
  static setItem(key, value) {
    basil.set(key, value);
    return value;
  }

  // for data that could grow unbounded, we use a ring buffer to limit
  // the possibility of storage quota/performance issues.
  static getFromRingBuffer(bufferName, key) {
    var buffer = this.getItem(bufferName, []);
    if (buffer && buffer.length) {
      return find(buffer, key);
    } else {
      return Basil.cookie.get(`${bufferName}-${key}`);
    }
  }

  // for data that could grow unbounded, we use a ring buffer to limit
  // the possibility of storage quota/performance issues.
  // this uses a list of tuples with LRU eviction
  static addToRingBuffer(bufferName, key, value, limitItems) {
    var buffer = this.getItem(bufferName, []);
    var currentValue = find(buffer, key);
    if (currentValue === undefined || currentValue === null) {
      buffer.push([key, value]); // TODO: move to tail of buffer to avoid it being purged?
    } else {
      replace(buffer, key, value);
    }
    if (buffer.length > limitItems) {
      buffer.shift();
    }
    basil.set(bufferName, buffer);

    // Also add to session cookie for Safari to avoid silently losing sandboxed value in iframe
    Basil.cookie.set(`${bufferName}-${key}`, value);
  }
}

function find(list, key) {
  var result;
  list.forEach((item) => {
    if (item[0] == key) {
      result = item[1];
    }
  });
  return result;
}

function replace(list, key, value) {
  list.forEach((item) => {
    if (item[0] == key) {
      item[1] = value;
    }
  });
}
