//
//  <PreviewIcon image={broadcast.preview}
//               timeframe={broadcast.timeframe}
//               duration={broadcast.duration}
//               onClick={() => {}} />
//
import React, { useState } from 'react';

export default function PreviewIcon(props) {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="boxcast-preview-icon"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={(evt) => props.onClick && props.onClick(evt)}
    >
      <ImagePreview image={props.image} hover={hover} />
      <PlayIcon hover={hover} />
      <Duration timeframe={props.timeframe} duration={props.duration} />
      <LiveNow timeframe={props.timeframe} />
    </div>
  );
}

function ImagePreview(props) {
  let imageStyle = {
    backgroundImage: `url("${props.image}")`,
    transform: `scale(${props.hover ? '1.1' : '1.0'})`,
  };
  return <div className="boxcast-preview-icon-preview" style={imageStyle}></div>;
}

function PlayIcon(props) {
  let playButtonStyle = {
    opacity: props.hover ? '1.0' : '0',
  };
  return (
    <div className="boxcast-preview-icon-play" style={playButtonStyle}>
      <i className="boxcast-icon-play"></i>
    </div>
  );
}

function Duration(props) {
  if (props.timeframe != 'current' && props.duration) {
    return <div className="boxcast-preview-duration">{props.duration}</div>;
  } else {
    return null;
  }
}

function LiveNow(props) {
  if (props.timeframe == 'current') {
    return <div className="boxcast-preview-timeframe">Live</div>;
  } else {
    return null;
  }
}
