//
//  <BroadcastTags broadcast={broadcast} />
//
import React from 'react';

import { tagsForBroadcast } from '../../utils/broadcast';

export default function BroadcastTags(props: { broadcast: any }) {
  return <aside>{renderTags(props.broadcast)}</aside>;
}

const renderTags = (broadcast) => {
  const queryParams = new URLSearchParams(window.location.search);
  const selectedTags = queryParams.get('tags')?.split(',') || [];

  return (
    <dl>
      {tagsForBroadcast(broadcast).map((tag) => (
        <span key={tag.label} className={tag.className}>
          <dt>{tag.label}</dt>
          <dd>{tag.value}</dd>
        </span>
      ))}
      {!!selectedTags.length && (
        <div className="broadcast-tags">
          {/* Create a tag icon for every tag we have */}
          {selectedTags.map((tag) => (
            <button key={tag} className="boxcast-button">
              <i className="fa fa-tag mr-1" />
              {tag}
            </button>
          ))}
        </div>
      )}
    </dl>
  );
};
