// Util provides a grab-bag of small handy conversion functions.
export default class Util {
  // basic sprintf-like support
  static stringFormat(string, ...args) {
    return string.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  }

  // ordinalize converts a positive integer into its ordinal representation.
  static ordinalize(n) {
    let s = ['th', 'st', 'nd', 'rd'];
    let v = n % 100;
    return `${n}${s[(v - 20) % 10] || s[v] || s[0]}`;
  }

  // turn a dictionary into a querystring
  static qsFromObj(obj) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  // copy a dictionary
  static copyDict(obj) {
    var newObj = {};
    Object.keys(obj).forEach(function (key) {
      newObj[key] = obj[key];
    });
    return newObj;
  }

  // returns new dictionary that is combination of arguments. last one wins.
  static mergeDict(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) {
      obj3[attrname] = obj1[attrname];
    }
    for (var attrname in obj2) {
      obj3[attrname] = obj2[attrname];
    }
    return obj3;
  }

  // compare 2 dictionaries for top-level key changes
  static areAnyChanged(origDict, newDict) {
    var result = false;
    // Check if any new/changed keys in new compared to original
    Object.keys(newDict).forEach((key) => {
      if (newDict[key] != origDict[key]) {
        result = true;
      }
    });
    // Now check in other direction
    Object.keys(origDict).forEach((key) => {
      if (newDict[key] != origDict[key]) {
        result = true;
      }
    });
    return result;
  }

  // uuid string generator
  static uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0;
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  // extract the URL inside href attribute from tag
  static getUrlFromLinkHtml(html) {
    var e = document.createElement('div');
    e.innerHTML = html;
    return e.firstChild.href;
  }

  // select m evenly spaced elements from an array of length n
  // https://en.wikipedia.org/wiki/Bresenham%27s_line_algorithm
  static sampleArray(sequence, num) {
    var length = sequence.length * 1.0;
    var result = [];
    for (var i = 0; i < num; i++) {
      result.push(sequence[Math.ceil((i * length) / num)]);
    }
    return result;
  }

  // validate email address
  static isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  // validate zip code
  static isValidZipCode(zip) {
    return zip && zip.trim().length == 5;
  }

  // formats a number as USD currency with two decimal places
  static formatCurrency(value) {
    if (isNaN(value)) {
      value = 0;
    }
    return "$" + parseFloat(Math.round(value * 100) / 100).toFixed(2);
  }
}
