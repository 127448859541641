// The BoxCastLinkback component is used to aid in SEO
import React from 'react';

export default function BoxCastLinkback() {
  const link = 'https://www.boxcast.com'

  return (
    <a className="boxcast-linkback" href={link} target="_blank">
      Powered by <strong>BoxCast</strong>
    </a>
  );
}
