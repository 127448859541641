import React from 'react';
import ReactDOM from 'react-dom';
import { Events, Playback, UICorePlugin } from '@clappr/player';

import { ga } from '../../ga';

class SocialButton extends React.Component {
  render() {
    // XXX: because of some z-index issues, we can't readily render the share
    // menu over top of the player window; instead, we just communicate back
    // to the bigger boxcast.js app to let it know to raise the share screen.
    return (
      <div>
        <button
          type="button"
          className="boxcast-clappr-button"
          data-social-button
          aria-label="Share via Social Media"
          onClick={() => this.props.onShow()}
        >
          <i className="boxcast-icon-share"></i>
        </button>
      </div>
    );
  }
}

export default class ClapprSocialPlugin extends UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }

  get name() {
    return 'clappr_social';
  }
  get attributes() {
    return { class: 'social', 'data-social-select': '' };
  }

  bindEvents() {
    this.listenTo(this.core.mediaControl, Events.MEDIACONTROL_RENDERED, this.render);
  }

  render() {
    this.core.mediaControl.$('.media-control-right-panel').append(this.el);
    setTimeout(() => {
      // XXX: try to work around IE11 issue with DOM mutations
      let component = React.createElement(SocialButton, this.reactComponentProps);
      ReactDOM.render(component, this.el);
    }, 0);
    return this;
  }

  destroy() {
    super.destroy();
    ReactDOM.unmountComponentAtNode(this.el);
  }

  get reactComponentProps() {
    return {
      onShow: this.core.options.socialSharing.onShow,
      onHide: this.core.options.socialSharing.onHide,
    };
  }
}

ClapprSocialPlugin.type = 'core';
