import React from 'react';
import ReactDOM from 'react-dom';
import { duration, timeframe } from './utils/broadcast';
import PreviewIcon from './components/library/preview-icon.jsx';

export default class Renderer {
  constructor() {}

  previewIcon(domNode, args) {
    var props = {
      image: args.broadcast.preview,
      duration: duration(args.broadcast),
      timeframe: timeframe(args.broadcast),
      onClick: () => args.onClick && args.onClick(args.broadcast),
    };
    var element = React.createElement(PreviewIcon, props);
    ReactDOM.render(element, domNode);
  }
}
