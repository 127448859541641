// The SocialStore remembers the state of social sharing options.
// It really doesn't need to be a Reflux store but is for consistency.
import Reflux from 'reflux';

export var SocialStoreFactory = (Actions, UserArgsStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.shareMenuVisible = false;
    },

    // Reflux action handlers
    onShowShareMenu: function () {
      this.shareMenuVisible = true;
      this.trigger();
    },
    onHideShareMenu: function () {
      this.shareMenuVisible = false;
      this.trigger();
    },
  });
