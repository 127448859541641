import { UICorePlugin, Events, $ } from '@clappr/core';
import { Clock, Util } from '../../utils';

import './clappr-thumbnail-scrubber-plugin.scss';

const MIN_NUM_THUMBS_TO_SHOW_BACKDROP = 12;

export default class ScrubThumbnailsPlugin extends UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }

  get name() {
    return 'scrub-thumbnails';
  }

  get attributes() {
    return {
      class: this.name,
    };
  }

  constructor(core) {
    super(core);
    this._loaded = false;
    this._show = false;
    // proportion into seek bar that the user is hovered over 0-1
    this._hoverPosition = 0;
    // each element is {x, y, w, h, imageW, imageH, url, time, duration}
    // one entry for each thumbnail
    this._thumbs = [];
    this._$backdropCarouselImgs = [];
  }

  bindEvents() {
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_CONTAINERCHANGED,
      this.containerChanged
    );
    this.listenTo(this.core.mediaControl, Events.MEDIACONTROL_MOUSEMOVE_SEEKBAR, this._onMouseMove);
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_MOUSELEAVE_SEEKBAR,
      this._onMouseLeave
    );
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_RENDERED,
      this._onMediaControlRendered
    );

    // We don't have access to the player's top-level event context so it's difficult
    // to detect when the playback is ready. However, we know it just takes a single "tick"
    // of the event loop before we have the hls.js playback if that's what it's going to be.
    //
    // XXX: TODO: This is very brittle code that relies on the internals
    // of the Clappr hls playback (src/playbacks/hls/hls.js).  If they
    // change the name of any variables/functions or how they tee up the
    // hls.js objec, our thumbnail generation code could break.
    setTimeout(() => {
      var playback = this.core.getCurrentPlayback();
      if (playback && typeof playback._setup == 'function') {
        //console.log('thumb: settings up hls');
        if (!playback._hls) {
          playback._setup();
        }
        // The hls.js LEVEL_UPDATED event is what gives us the list of all .ts segments
        // mapped to their timestamps.  We can use this along with our knowledge of the
        // pattern of how we name thumbnail previews to generate them.
        playback._hls.on('hlsLevelUpdated', (evt, data) => {
          var fragments = data.details.fragments;
          if (data.details.live) {
            // NOTE: we need to ignore the last ~3 fragments to avoid trying
            // to get preview images before they make their way to S3
            fragments = fragments.slice(0, fragments.length - 3 > 0 ? fragments.length - 3 : 0);
          }
          this._init(fragments);
        });
      }
    }, 0);
  }

  containerChanged() {
    this.stopListening();
    this.bindEvents();
  }

  _thumbsRaw(fragments) {
    var thumbnailUrlTemplate = this._getOptions().thumbnailUrlTemplate;
    if (!thumbnailUrlTemplate) {
      return [];
    }

    function urlFor(ts) {
      // Generate a preview JPG URL for the given segment of the playlist.
      // We use the sequence number of this fragment from the m3u8 manifest,
      // (left-padded) to replace the image filename that was used for the preview.
      //var filename = ts.url.match(/\d+\.ts/)[0].replace('.ts', '.jpg');
      var position = ts.url.indexOf('.ts');
      var filename = ts.url.substring(position - 8, position) + '.jpg';
      return thumbnailUrlTemplate.replace('%08d.jpg', filename);
    }

    // NOTE: we have "fragments" for every ts file (~every 10 seconds), but that resolution
    // is not necessary for a 3+ hour recording.  Sample it to get them down to a more reasonable
    // number (e.g. 100);
    var fragmentsSample = fragments;
    if (this._getOptions().maxThumbs && fragmentsSample.length > this._getOptions().maxThumbs) {
      fragmentsSample = Util.sampleArray(fragmentsSample, this._getOptions().maxThumbs);
    }

    return fragmentsSample.map((f) => {
      return { time: f.start, url: urlFor(f), h: 190, w: 320 };
    });
  }

  _init(fragments) {
    // preload all the thumbnails in the browser
    if (this._initInFlight) {
      // prevent race condition
      return;
    }
    this._initInFlight = true;
    this._loadThumbnails(this._thumbsRaw(fragments), (thumbs) => {
      // all thumbnails now preloaded
      if (!thumbs.length) return;
      this._thumbs = thumbs;
      this._loadBackdrop();
      this._loaded = true;
      this._initInFlight = false;
      this._renderPlugin();
    });
  }

  _getOptions() {
    if (!('scrubThumbnails' in this.core.options)) {
      throw "'scrubThumbnails property missing from options object.";
    }
    return this.core.options.scrubThumbnails;
  }

  _appendElToMediaControl() {
    // insert after the background
    var sibling = this.core.mediaControl.$el.find('.media-control-background');
    if (!sibling.length) {
      return false;
    }
    sibling.first().after(this.$el);
    return true;
  }

  _onMediaControlRendered() {
    this._appendElToMediaControl();
  }

  _onMouseMove(e) {
    this._calculateHoverPosition(e);
    this._show = true;
    this._renderPlugin();
  }

  _onMouseLeave() {
    this._show = false;
    this._renderPlugin();
  }

  _calculateHoverPosition(e) {
    var offset = e.pageX - this.core.mediaControl.$seekBarContainer.offset().left;
    // proportion into the seek bar that the mouse is hovered over 0-1
    this._hoverPosition = Math.min(
      1,
      Math.max(offset / this.core.mediaControl.$seekBarContainer.width(), 0)
    );
  }

  // download all the thumbnails
  _loadThumbnails(thumbs, onLoaded) {
    var cachedImageUrls = [];
    var thumbsToLoad = thumbs.length;
    var finalThumbs = [];
    if (thumbsToLoad === 0) {
      onLoaded(finalThumbs);
      return;
    }

    for (let i = 0; i < thumbs.length; i++) {
      let thumb = thumbs[i];
      if (!thumb) continue;
      //finalThumbs.push(null)

      let next = i < thumbs.length - 1 ? thumbs[i + 1] : null;
      // the duration this thumb lasts for
      // if it is the last thumb then duration will be null
      let duration = next ? next.time - thumb.time : null;

      // preload each thumbnail
      let $img = $('<img alt="Preview Thumbnail" aria-hidden="true" />');

      let onImgLoaded = () => {
        // put image in dom to prevent browser removing it from cache
        if (cachedImageUrls.indexOf(thumb.url) === -1) {
          // not been cached
          this._$imageCache.append($img);
          cachedImageUrls.push(thumb.url);
        }
        let imageW = $img[0].width;
        let imageH = $img[0].height;
        finalThumbs[i] = {
          imageW: imageW, // actual width of image
          imageH: imageH, // actual height of image
          x: thumb.x || 0, // x coord in image of sprite
          y: thumb.y || 0, // y coord in image of sprite
          w: thumb.w || imageW, // width of sprite
          h: thumb.h || imageH, // height of sprite
          url: thumb.url,
          time: thumb.time, // time this thumb represents
          duration: duration, // how long (from time) this thumb represents
        };

        if (--thumbsToLoad === 0) {
          onLoaded(finalThumbs);
        }
      };
      $img
        .one('load', onImgLoaded)
        .one('error', (err) => {
          if (--thumbsToLoad === 0) {
            onLoaded(finalThumbs);
          }
        })
        .attr('src', thumb.url);
    }
  }

  // builds a dom element which represents the thumbnail
  // scaled to the provided height
  _buildImg(thumb, height) {
    var scaleFactor = height / thumb.h;
    var $img = $('<img alt="Preview Thumbnail" aria-hidden="true" />')
      .addClass('thumbnail-img')
      .attr('src', thumb.url);

    // the container will contain the image positioned so that the correct sprite
    // is visible
    var $container = $('<div />').addClass('thumbnail-container');
    $container.width(thumb.w * scaleFactor);
    $container.height(height);
    $img.css({
      height: thumb.imageH * scaleFactor,
      left: -1 * thumb.x * scaleFactor,
      top: -1 * thumb.y * scaleFactor,
    });
    $container.append($img);
    return $container;
  }

  _loadBackdrop() {
    if (!this._getOptions().backdropHeight) {
      // disabled
      return;
    }

    if (this._thumbs.length < MIN_NUM_THUMBS_TO_SHOW_BACKDROP) {
      this._$backdrop.hide();
    } else {
      this._$backdrop.show();
    }

    // append each of the thumbnails to the backdrop
    this._$backdropCarousel.empty();
    this._$backdropCarouselImgs = [];
    this._thumbs.forEach((thumb) => {
      let $img = this._buildImg(thumb, this._getOptions().backdropHeight);
      this._$backdropCarousel.append($img);
      this._$backdropCarouselImgs.push($img);
    });
  }

  // calculate how far along the carousel should currently be slid
  // depending on where the user is hovering on the progress bar
  _updateCarousel() {
    if (!this._getOptions().backdropHeight) {
      // disabled
      return;
    }

    var hoverPosition = this._hoverPosition;
    var videoDuration = this.core.activeContainer.getDuration();

    // the time into the video at the current hover position
    var hoverTime = videoDuration * hoverPosition;
    var backdropWidth = this._$backdrop.width();
    var carouselWidth = this._$backdropCarousel.width();

    // slide the carousel so that the image on the carousel that is above where the person
    // is hovering maps to that position in time.
    // Thumbnails may not be distributed at even times along the video
    var thumbs = this._thumbs;

    // assuming that each thumbnail has the same width and is evenly spaced according to time
    var thumbWidth = carouselWidth / thumbs.length;

    // determine which thumbnail applies to the current time
    var thumbIndex = this._getThumbIndexForTime(hoverTime);
    var thumb = thumbs[thumbIndex];
    var thumbDuration = thumb.duration;
    if (thumbDuration === null) {
      // the last thumbnail duration will be null as it can't be determined
      // e.g the duration of the video may increase over time (live stream)
      // so calculate the duration now so this last thumbnail lasts till the end
      thumbDuration = Math.max(videoDuration - thumb.time, 0);
    }

    // determine how far accross that thumbnail we are
    var timeIntoThumb = hoverTime - thumb.time;
    var positionInThumb = timeIntoThumb / thumbDuration;
    var xCoordInThumb = thumbWidth * positionInThumb;

    // now calculate the position along carousel that we want to be above the hover position
    var xCoordInCarousel = thumbIndex * thumbWidth + xCoordInThumb;

    // and finally the position of the carousel when the hover position is taken in to consideration
    var carouselXCoord = xCoordInCarousel - hoverPosition * backdropWidth;

    this._$backdropCarousel.css('left', -carouselXCoord);

    // now update the transparencies so that they fade in around the active one
    for (let i = 0; i < thumbs.length; i++) {
      let thumbXCoord = thumbWidth * i;
      let distance = thumbXCoord - xCoordInCarousel;
      if (distance < 0) {
        // adjust so that distance is always a measure away from
        // each side of the active thumbnail
        // at every point on the active thumbnail the distance should
        // be 0
        distance = Math.min(0, distance + thumbWidth);
      }
      // fade over the width of 8 thumbnails
      //let opacity = Math.max(1.0 - (Math.abs(distance)/(8*thumbWidth)), 0.08)
      //this._$backdropCarouselImgs[i].css("opacity", opacity)
    }
  }

  _updateSpotlightThumb() {
    if (!this._getOptions().spotlightHeight) {
      // disabled
      return;
    }

    var hoverPosition = this._hoverPosition;
    var videoDuration = this.core.activeContainer.getDuration();
    // the time into the video at the current hover position
    var hoverTime = videoDuration * hoverPosition;

    // determine which thumbnail applies to the current time
    var thumbIndex = this._getThumbIndexForTime(hoverTime);
    var thumb = this._thumbs[thumbIndex];

    // update thumbnail
    this._$spotlight.empty();
    this._$spotlight.append(this._buildImg(thumb, this._getOptions().spotlightHeight));
    /*
    this._$spotlight.append(`
        <div style="position:absolute;bottom:0;left:0;right:0;text-align:center;font-weight:bold;font-size:8pt;background:rgba(0,0,0,0.6)">
          ${Clock.durationFormatted(hoverTime)}
        </div>
    `)
    */

    var elWidth = this.$el.width();
    var thumbWidth = this._$spotlight.width();

    var spotlightXPos = elWidth * hoverPosition - thumbWidth / 2;

    // adjust so the entire thumbnail is always visible
    spotlightXPos = Math.max(Math.min(spotlightXPos, elWidth - thumbWidth), 0);

    this._$spotlight.css('left', spotlightXPos);
  }

  // returns the thumbnail which represents a time in the video
  // or null if there is no thumbnail that can represent the time
  _getThumbIndexForTime(time) {
    var thumbs = this._thumbs;
    for (let i = thumbs.length - 1; i >= 0; i--) {
      let thumb = thumbs[i];
      if (thumb && thumb.time <= time) {
        return i;
      }
    }
    // stretch the first thumbnail back to the start
    return 0;
  }

  _renderPlugin() {
    if (!this._loaded) {
      return;
    }
    if (this._show && this._getOptions().thumbnailUrlTemplate) {
      this.$el.removeClass('hidden');
      this._updateCarousel();
      this._updateSpotlightThumb();
    } else {
      this.$el.addClass('hidden');
    }
  }

  render() {
    this._$imageCache = $('<div />').addClass('image-cache');
    this.$el.append(this._$imageCache);
    // if either of the heights are null or 0 then that means that part is disabled
    if (this._getOptions().backdropHeight) {
      this._$backdrop = $('<div />').addClass('backdrop');
      this._$backdrop.height(this._getOptions().backdropHeight);
      this._$backdropCarousel = $('<div />').addClass('carousel');
      this._$backdrop.append(this._$backdropCarousel);
      this.$el.append(this._$backdrop);
    }
    var spotlightHeight = this._getOptions().spotlightHeight;
    if (spotlightHeight) {
      this._$spotlight = $('<div />').addClass('spotlight');
      this._$spotlight.height(spotlightHeight);
      this.$el.append(this._$spotlight);
    }
    this.$el.addClass('hidden');
    this._appendElToMediaControl();
    return this;
  }
}

ScrubThumbnailsPlugin.type = 'core';
