import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import platform from 'platform';

var freezeViewport = function (e) {
  e.preventDefault();
};

function toggleBodyScrolling(stop) {
  if (stop) {
    document.body.classList.add('boxcast-noscroll');
  } else {
    document.body.classList.remove('boxcast-noscroll');
  }

  // Additionally, in non-iOS browsers, we may need to freeze the viewport
  var isiOS = platform && platform.os && platform.os.family == 'iOS';
  if (!isiOS) {
    if (stop) {
      document.body.addEventListener('touchmove', freezeViewport, false);
    } else {
      document.body.removeEventListener('touchmove', freezeViewport, false);
    }
  }
}

const Modal = createClass({
  displayName: 'Modal',
  propTypes: {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClickOverlay: PropTypes.func.isRequired,
    opacity: PropTypes.number,
    visible: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      className: 'Modal',
      opacity: 0.5,
    };
  },
  getInitialState() {
    return {
      styles: this.getStyles(),
      overlayVisible: this.props.visible,
      modalVisible: this.props.visible,
    };
  },
  UNSAFE_componentWillReceiveProps(newProps) {
    let visible = {};
    if (newProps.visible) {
      visible = {
        overlayVisible: true,
        modalVisible: true,
      };
    } else {
      visible = {
        overlayVisible: false,
        modalVisible: false,
      };
    }
    this.setState(visible);
  },
  getStyles() {
    return {
      overlay: {
        background: `rgba(0,0,0,${this.props.opacity})`,
        bottom: 0,
        display: 'block',
        left: 0,
        overflowY: 'auto',
        overflowScrolling: 'touch',
        WebkitOverflowScrolling: 'touch',
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 1000,
      },
      wrapper: {
        bottom: 0,
        boxSizing: 'border-box',
        display: 'table',
        height: '100%',
        left: 0,
        position: 'absolute',
        right: 0,
        textAlign: 'center',
        top: 0,
        width: '100%',
      },
      subWrapper: {
        display: 'table-cell',
        verticalAlign: 'middle',
      },
      modal: {
        background: 'white',
        margin: '0 auto',
        position: 'relative',
      },
      x: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#aaa',
        border: '1px solid',
        borderRadius: '50%',
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        cursor: 'pointer',
      },
    };
  },
  componentDidMount() {},
  componentWillUnmount() {
    document.body.classList.remove('boxcast-noscroll');
  },
  renderModal() {
    if (this.state.modalVisible) {
      return (
        <div
          style={this.state.styles.modal}
          className={this.props.className}
          onClick={this.stopPropagation}
        >
          <div style={this.state.styles.x} onClick={this.props.onClickOverlay}>
            &times;
          </div>
          {this.props.children}
        </div>
      );
    }
    return null;
  },
  renderContentOverlay() {
    return (
      <div style={this.state.styles.subWrapper} onClick={this.props.onClickOverlay}>
        {this.renderModal()}
      </div>
    );
  },
  renderOverlay() {
    if (this.state.overlayVisible) {
      toggleBodyScrolling(true);
      return (
        <div style={this.state.styles.overlay}>
          <div style={this.state.styles.wrapper}>{this.renderContentOverlay()}</div>
        </div>
      );
    } else {
      toggleBodyScrolling(false);
    }
    return null;
  },
  render() {
    return <div>{this.renderOverlay()}</div>;
  },
  stopPropagation(e) {
    e.stopPropagation();
  },
});

export default Modal;
