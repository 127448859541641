import React from 'react';
import Config from '../../config';

export default function PdfDocument(props) {
  const { url } = props;
  return (
    <div className="boxcast-playlist-list">
      {url ? (
        <div style={{ position: 'relative', paddingTop: '100%' }}>
          <iframe
            src={Config.get('pdfServiceUrl') + encodeURIComponent(url)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            frameBorder="0"
          ></iframe>
        </div>
      ) : (
        <p>No file has been uploaded.</p>
      )}
    </div>
  );
}
