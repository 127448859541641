import React from 'react';
import { Util } from '../../utils';

export function OrderSummaryLarge(props) {
  const { description, accountName, channelTicket, ticketPrice, disclaimer, taxAmount, modalType } = props;
  return (
    <div>
      <div className="boxcast-payment-subtitle" style={{ marginBottom: '20px' }}>
        Order Summary
      </div>
      <div className="boxcast-payment-details boxcast-overflow-ellipse">{description}</div>
      <div className="boxcast-payment-label boxcast-overflow-ellipse">{accountName}</div>
      {channelTicket ? (
        <div className="boxcast-payment-label-sm" style={{ marginTop: '30px' }}>
          This ticket entitles you to all broadcasts in the channel.
        </div>
      ) : (
        <span />
      )}
      <div
        className="boxcast-row"
        style={{
          marginTop: '30px',
          paddingTop: '10px',
          borderTop: '1px solid #ccc',
        }}
      >
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-label">Ticket Price</div> : <span />}
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-amt">{Util.formatCurrency(ticketPrice)}</div> : <span />}
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-label">Estimated Sales Tax</div> : <span />}
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-amt">{taxAmount == null ? '---' : Util.formatCurrency(taxAmount)}</div> : <span />}
        <div className="boxcast-col-50 boxcast-payment-label-total">Total</div>
        <div className="boxcast-col-50 boxcast-payment-total-amt">{Util.formatCurrency(ticketPrice + taxAmount)}</div>
      </div>
      <div className="boxcast-payment-label-sm" style={{ marginTop: '60px' }}>
        {disclaimer}
      </div>
      <div className="boxcast-payment-label" style={{ fontWeight: 'normal', marginTop: '10px' }}>
        <a
          href="https://boxcast.zendesk.com/hc/en-us/sections/200415183-Viewer-FAQs"
          target="_blank"
        >
          Need help? Ask the BoxCast support team.
        </a>
      </div>
    </div>
  );
}

export function OrderSummarySmall(props) {
  const { description, accountName, channelTicket, ticketPrice, modalType, taxAmount } = props;
  return (
    <div className="boxcast-order-summary-xs boxcast-visible-xs boxcast-hidden-sm boxcast-hidden-md boxcast-hidden-lg">
      <div className="boxcast-payment-subtitle">Order Summary</div>
      <div className="boxcast-payment-details boxcast-overflow-ellipse">{description}</div>
      <div className="boxcast-payment-label boxcast-overflow-ellipse">{accountName}</div>
      {channelTicket ? (
        <div className="boxcast-payment-label-sm" style={{ marginTop: '10px' }}>
          This ticket entitles you to all broadcasts in the channel.
        </div>
      ) : (
        <span />
      )}
      <div className="boxcast-row" style={{ marginTop: '20px' }}>
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-label">Ticket Price</div> : <span />}
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-amt">{Util.formatCurrency(ticketPrice)}</div> : <span />}
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-label">Estimated Sales Tax</div> : <span />}
        {modalType == 'ticket' ? <div className="boxcast-col-50 boxcast-payment-amt">{taxAmount == null ? '---' : Util.formatCurrency(taxAmount)}</div> : <span />}
        <div className="boxcast-col-50 boxcast-payment-label-total">Total</div>
        <div className="boxcast-col-50 boxcast-payment-total-amt">{Util.formatCurrency(ticketPrice + taxAmount)}</div>
      </div>
    </div>
  );
}
