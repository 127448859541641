import Clock from './clock';

function logToConsoleIfLevelEnabled(level, namespace, ...args) {
  if ((window.boxcast && window.boxcast.debug) || level == 'error') {
    let date = Clock.format(Clock.now());
    console[level].call(console, `[${namespace}] [${date}]`, ...args);
  }
}

const levels = ['debug', 'log', 'info', 'warn', 'error'];

export default class Logger {
  static getInstance(namespace) {
    return new Logger(namespace);
  }

  constructor(namespace) {
    this.namespace = namespace;

    // Glue methods on for normal console.* methods
    levels.forEach((level) => {
      this[level] = (...args) => {
        logToConsoleIfLevelEnabled(level, this.namespace, ...args);
      };
    });
  }

  // TODO: Some messages need to be sent up to the server. We do that with
  // Logger.persist and Logger.analytics
  persist(level, ...args) {
    logToConsoleIfLevelEnabled('persist', this.namespace, '<<<--- PERSIST --->>>', ...args);
  }
  analytics(...args) {
    logToConsoleIfLevelEnabled('analytics', this.namespace, '<<<--- ANALYTICS --->>>', ...args);
  }
}
