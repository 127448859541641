import React from 'react';
import { Logger } from '../../utils';

const logger = Logger.getInstance('player');

export default class QualityPicker extends React.Component {
  componentDidMount() {
    // on first mounting of this component, it's come into view so we should focus on it for
    // improved keyboard accessibility
    this.refs.backButton.focus();
  }
  render() {
    const { currentLevel, levels } = this.props;
    const sortedLevels = levels.sort((a, b) => {
      if (a.id == -1) return -1;
      if (b.id == -1) return 1;

      //highest to lowest resolution (height)
      if (b.level && b.level.height && a.level && a.level.height) {
        const r = b.level.height - a.level.height;
        if (r != 0) return r;
      }

      // If those match, fall back to the IDs
      return b.id - a.id;
    });
    return (
      <ul className="boxcast-clappr-menu">
        <li>
          <span className="boxcast-clappr-menu-title">Quality</span>
          <button
            ref="backButton"
            type="button"
            aria-label="Back"
            className="boxcast-unstyled-button boxcast-clappr-menu-icon-left"
            onClick={() => this.props.onBack()}
          >
            <i className="boxcast-icon-chevron-left"></i>
          </button>
        </li>
        {sortedLevels.map((level) => {
          let selected = level.id == currentLevel;
          let hd = isHD(level);
          let hfr = isHFR(level);
          return (
            <li key={level.id} className={selected ? 'link active' : 'link'}>
              <button
                type="button"
                aria-label={`Change quality to ${level.label}`}
                className="boxcast-unstyled-button"
                onClick={() => this.props.onChangeLevel(level.id)}
              >
                <span className="boxcast-clappr-menu-label">
                  <span className={'boxcast-clappr-menu-quality-label'}>{level.label}</span>
                  {hd ? <span className={'boxcast-clappr-menu-quality-hd'}>HD</span> : ''}
                </span>
                <span className="boxcast-clappr-menu-icon-right">
                  <i className={selected ? 'boxcast-icon-check' : ''}></i>
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    );
  }
}

function isHD(level) {
  return level.label.substring(0, level.label.indexOf('p')) >= 720;
}

function isHFR(level) {
  return level.label.indexOf('p60') >= 0;
}
