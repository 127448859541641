//
// <SocialButtons />
//
// Note: this requires the user to have specified a Facebook App ID
// when loading the channel, because we need a unique one per embed
// domain.

import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import { Logger, broadcast as BroadcastUtils } from '../../utils';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';

import NonBreakingSpace from './nbsp.jsx';

const logger = Logger.getInstance('components');

var SocialButtons = createClass({
  displayName: 'SocialButtons',

  contextTypes: {
    model: PropTypes.object,
    userArgs: PropTypes.object,
    env: PropTypes.func,
  },

  mixins: [RefluxModelListenerMixin],

  refluxModelListeners: {
    SelectedBroadcastStore: 'onStoreChanged',
  },

  getInitialState: function () {
    const { SelectedBroadcastStore } = this.context.model;
    return {
      broadcast: SelectedBroadcastStore.broadcast,
      selectedHighlight: SelectedBroadcastStore.selectedHighlight,
    };
  },

  onStoreChanged: function () {
    this.setState(this.getInitialState());
  },

  componentDidMount: function () {
    const { userArgs } = this.context;
    if (userArgs.showSocialShare) {
      if (userArgs.facebookAppId) {
        loadFacebookSDK(userArgs.facebookAppId);
      }
    }
  },

  render: function () {
    const { broadcast, selectedHighlight } = this.state;
    const { userArgs } = this.context;

    if (!userArgs.showSocialShare || !broadcast.channel_id) {
      return <span />;
    }

    return (
      <div className="boxcast-social">
        {userArgs.facebookAppId ? this.renderFacebook() : <span />}
        {this.renderTwitter()}
      </div>
    );
  },

  renderFacebook: function () {
    return (
      <button
        type="button"
        className="boxcast-button"
        style={{ color: '#3b5998', borderColor: '#3b5998' }}
        onClick={() => this.shareOnFacebook()}
      >
        <i className="boxcast-icon-facebook"></i>
        <NonBreakingSpace />
        Share on Facebook
      </button>
    );
  },

  renderTwitter: function () {
    return (
      <button
        type="button"
        className="boxcast-button"
        style={{ color: '#55acee', borderColor: '#55acee' }}
        onClick={() => this.shareOnTwitter()}
      >
        <i className="boxcast-icon-twitter"></i>
        <NonBreakingSpace />
        Share on Twitter
      </button>
    );
  },

  getLinkToShare: function () {
    const { broadcast, selectedHighlight } = this.state;
    const { model, env, userArgs } = this.context;

    if (userArgs.socialShareURL) {
      return userArgs.socialShareURL;
    }

    // A few different cases need to be considered here, e.g.:
    // 1. link to channel view and show one of the broadcasts
    // 2. link to single-broadcast view
    // 3. link to highlight view within either #1 or #2 context (whoa)
    // 4. link to single highlight all by itself
    var rootChannelId = model.CurrentChannelStore.id;
    var broadcastChannelId = broadcast.channel_id;
    var baseShareUrl = env('socialShareUrlPrefix');
    if (rootChannelId == broadcastChannelId) {
      // User specified loadChannel for a single broadcast (not custom channel)
      var qs = `b=${broadcast.id || selectedHighlight.broadcast_id}`;
      if (selectedHighlight) {
        qs += `&h=${selectedHighlight.id}`;
      }
      return `${baseShareUrl}/view/${rootChannelId}?${qs}`;
    } else if (rootChannelId) {
      // Custom channel mode with broadcast selected
      var qs = `b=${broadcast.id || selectedHighlight.broadcast_id}`;
      if (selectedHighlight) {
        qs += `&h=${selectedHighlight.id}`;
      }
      return `${baseShareUrl}/channel/${rootChannelId}?${qs}`;
    } else if (selectedHighlight && selectedHighlight.id == model.HighlightListStore.channelId) {
      // User is using the loadHighlight mode (single highlight)
      return `${baseShareUrl}/highlights/${broadcastChannelId}/${
        broadcast.id || selectedHighlight.broadcast_id
      }/${selectedHighlight.id}`;
    } else {
      // User is using the loadHighlights grid mode with nothing selected
      var qs = `b=${broadcast.id || selectedHighlight.broadcast_id}`;
      if (selectedHighlight) {
        qs += `&h=${selectedHighlight.id}`;
      }
      return `${baseShareUrl}/highlights/${model.HighlightListStore.channelId}?${qs}`;
    }
  },

  shareOnFacebook: function () {
    const { broadcast, selectedHighlight } = this.state;
    const { env } = this.context;

    if (!FB) {
      alert(
        'The Facebook SDK is not initialized so we cannot share this link right now. Please refresh and try again.'
      );
      return;
    }

    const previewImg = (selectedHighlight || broadcast).preview || env('defaultSocialShareThumb');

    FB.ui(
      {
        method: 'feed',
        link: this.getLinkToShare(),
        name: (selectedHighlight || broadcast).name,
        description: (selectedHighlight || broadcast).description,
        picture: BroadcastUtils.ensureHttpOnProtocolRelativeUrl(previewImg),
        caption: 'BoxCast :: a smarter way to stream',
        type: 'video',
      },
      function (response) {
        console.log(response);
      }
    );
  },

  shareOnTwitter: function () {
    const { broadcast, selectedHighlight } = this.state;

    let name = (selectedHighlight || broadcast).name;
    let url = encodeURIComponent(this.getLinkToShare());
    let text = encodeURIComponent(`Watch ${name} online at`);

    window.open(
      'https://twitter.com/intent/tweet?url=' + url + '&text=' + text,
      'Promote on Twitter',
      'dependent=1,height=420,width=550,resizable=1'
    );
  },
});

function loadFacebookSDK(facebookAppId) {
  if (window.fbAsyncInit) {
    return;
  }
  window.fbAsyncInit = function () {
    FB.init({ appId: facebookAppId, xfbml: false, version: 'v17.0' });
  };
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export default SocialButtons;
