// The SlotStore manages external user content in "slots".
import Reflux from 'reflux';

export var SlotStore = (Actions, UserArgsStore) =>
  Reflux.createStore({
    // Constants
    SLOTS: {
      AFTER_PLAYLIST: 'boxoffice.playlist.after',
      AFTER_TITLE_CARD: 'boxoffice.titlecard.after',
    },

    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.slots = UserArgsStore.slots || {};
    },

    // Helpers
    has: function (name) {
      return this.get(name) !== undefined;
    },
    get: function (name) {
      return this.slots[name];
    },
    update: function (name, content) {
      this.slots[name] = content;
    },

    // Reflux action handlers
    onUpdateSlot: function (name, content) {
      this.update(name, content);
      this.trigger();
    },
  });
