import Clock from './clock';

// generates a list of label/value dictionaries
export function tagsForBroadcast(broadcast) {
  let tags = [];

  let timeStr = timeframe(broadcast);
  if (timeStr == 'future') {
    tags.push({ label: 'Timeframe', value: 'Coming Soon' });
  } else if (timeStr == 'current') {
    tags.push({
      label: 'Timeframe',
      value: 'LIVE',
      className: 'boxcast-tag-live-now',
    });
  }

  if (broadcast.transcoder_profile) {
    let value = broadcast.transcoder_profile.replace('plc', 'p').replace(/:.*/, '');
    if (value == 'audio') {
      value = 'Audio Only';
    }
    tags.push({ label: 'Resolution', value: value });
  }

  return tags;
}

const RECENTLY_ENDED_DURATION_MS = 300000; // 5 minutes

// returns 'current', 'future', 'recently-ended', or 'past'
// depending on start/stop time
export function timeframe(broadcast) {
  if (!broadcast || !broadcast.starts_at) {
    return null;
  }
  let startDiff = Clock.diff(Clock.parse(broadcast.starts_at));
  let endDiff = Clock.diff(Clock.parse(broadcast.stops_at));
  if (startDiff <= 0 && (!endDiff || endDiff >= 0)) {
    return 'current';
  } else if (startDiff > 0) {
    return 'future';
  } else if (Math.abs(endDiff) < RECENTLY_ENDED_DURATION_MS) {
    return 'recently-ended';
  } else {
    return 'past';
  }
}

// returns the h:mm:ss formatted duration of the broadcast
export function duration(broadcast) {
  let starts = Clock.parse(broadcast.starts_at).getTime();
  let stops = Clock.parse(broadcast.stops_at).getTime();
  let diffSec = Math.round((stops - starts) / 1000);
  if (diffSec > 86400) {
    return ''; //no broadcast should be more than 24 hours
  }
  return Clock.durationFormatted(diffSec);
}

// if a url is passed as a protocol-relative url (e.g. "//uploads.boxcast.com/foo.jpg"), make
// sure to preprend a protocol. this is required for some 3rd party application integrations.
export function ensureHttpOnProtocolRelativeUrl(url) {
  if (url.startsWith('//')) {
    url = 'http:' + url;
  }
  return url;
}

/*
// generate a link to the broadcast and/or highlight in the context of the given channel
export function linkTo(channelId, broadcast, highlight) {
  var eventUrl;
  if (highlight) {
    eventUrl = `https://boxcast.tv/view/${channelId}?b=${broadcast.id}&h=${highlight.id}`;
  } else {
    eventUrl = `https://boxcast.tv/channel/${channelId}?b=${broadcast.id}`;
  }
  const eventUrlTarget = (window.location.href.indexOf('boxcast.tv') < 0) ? '_blank' : '_self';
  return eventUrl;
}
*/
