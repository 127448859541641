// The PlaybackStore helps manage the viewing of broadcasts within a
// session for a user.  It also supports the Live DVR transition to
// recorded mode by understanding where the user is in the playback.

import Reflux from 'reflux';
import { Logger } from '../utils';

const logger = Logger.getInstance('models');

export var PlaybackStoreFactory = (Actions, SelectedBroadcastStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      logger.log('PlaybackStore#init()');
      this.state = {};
      this.lastPlayedId = null;
    },

    // Public methods
    getStateForBroadcast: function (broadcast, key = null) {
      var state = this.state[broadcast.id] || {};
      if (key) {
        return state[key];
      } else {
        return state;
      }
    },
    isSameAsLastPlayed: function (broadcast) {
      return broadcast.id == this.lastPlayedId;
    },

    // New action handlers just for this store
    onDVRModeChange: function (dvrInUse) {
      var b = SelectedBroadcastStore.selectedHighlight || SelectedBroadcastStore.broadcast;
      logger.log('DVR mode changed', b.id, dvrInUse);
      this.state[b.id] = this.state[b.id] || {};
      this.state[b.id].dvrInUse = dvrInUse;
    },
    onTimeUpdate: function (seekTime, totalTime) {
      var b = SelectedBroadcastStore.selectedHighlight || SelectedBroadcastStore.broadcast;
      this.state[b.id] = this.state[b.id] || {};
      this.state[b.id].lastTime = seekTime;
      this.state[b.id].totalTime = totalTime;
      this.state[b.id].lastTimeUpdatedAt = new Date().getTime();
    },

    // Tie into other action handlers from other stores
    onSelectBroadcast: function (newBroadcast) {
      if (newBroadcast && newBroadcast.id) {
        this.state[newBroadcast.id] = this.state[newBroadcast.id] || {};
      }
    },
    onPlayHighlight: function (highlight) {
      if (highlight && highlight.id) {
        this.state[highlight.id] = this.state[highlight.id] || {};
      }
    },
    // TODO: consider moving onPlaybackErrorOccurred and onPlaybackIsPlaying from
    // SelectedBroadcastStore into here; would need to update all component listeners
    onPlaybackIsPlaying: function () {
      var b = SelectedBroadcastStore.selectedHighlight || SelectedBroadcastStore.broadcast;
      this.lastPlayedId = b.id;
    },
  });
