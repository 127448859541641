// understands the state of the selected broadcast/channel
// to fire off reload actions on a proper interval
import React from 'react';

import { Clock } from '../utils';
import { timeframe } from '../utils/broadcast';

function getNextInterval(SelectedBroadcastStore) {
  // @return null - if we have a good playlist for a live or VOD event
  // @return 5 seconds - for recently ended events (to watch for new playlist)
  // @return 10 seconds - for current events with no playlist yet
  // @return 30 seconds - for current or past events with a playback error
  // @return 60 seconds - for future events (to keep apprised as playlist becomes available)
  // @return 300 seconds - for past events (to watch for highlights, changes, etc)
  var { broadcast, view, playbackError } = SelectedBroadcastStore;
  view = view || {};
  if (view.status && (view.status.indexOf('live') >= 0 || view.status.indexOf('recorded') >= 0)) {
    if (view.playlist && !playbackError) {
      // Already have a playlist and all is well; no need to poll until something changes
      return null;
    }
    if (playbackError) {
      // Something went wrong; check in 30sec in case origin changes playlist
      return 30 * 1000;
    }
  }

  var timeStr = timeframe(broadcast);
  if (timeStr == 'recently-ended' || view.status == 'processing_recording') {
    return 5 * 1000;
  } else if (timeStr == 'current') {
    return 10 * 1000;
  } else if (timeStr == 'future') {
    return 60 * 1000;
  } else {
    return 300 * 1000;
  }
}

var BoxOfficeReloadMixin = {
  componentDidMount: function () {
    this.initBoxOfficeReloadMixin();
  },

  componentWillUnmount: function () {
    clearTimeout(this.boxOfficeReloadTimeout);
  },

  initBoxOfficeReloadMixin: function () {
    clearTimeout(this.boxOfficeReloadTimeout);
    this.scheduleNextBoxOfficeReloadTick();
  },

  scheduleNextBoxOfficeReloadTick: function () {
    // XXX: `BoxOffice` defines the `model` through props; other child components
    // need to refer to it through `context`
    const { SelectedBroadcastStore } = this.props.model;
    const interval = getNextInterval(SelectedBroadcastStore);
    if (interval != null) {
      // Process the action on the next tick
      this.boxOfficeReloadTimeout = setTimeout(() => this.handleBoxOfficeReloadTick(), interval);
    } else {
      // Don't process the action, but check again in 1s if anything has changed
      this.boxOfficeReloadTimeout = setTimeout(() => this.scheduleNextBoxOfficeReloadTick(), 1000);
    }
  },

  handleBoxOfficeReloadTick: function () {
    const { Actions } = this.props.model;

    Actions.RefreshSelectedBroadcastMeta();
    this.scheduleNextBoxOfficeReloadTick();
  },
};

export default BoxOfficeReloadMixin;
