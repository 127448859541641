import React from 'react';
import ReactDOM from 'react-dom';
import { Events, Playback, UICorePlugin } from '@clappr/player';

import { Logger } from '../../utils';
import { ga } from '../../ga';

import SettingsCog from './settings-cog.jsx';

import './clappr-settings-plugin.scss';

const logger = Logger.getInstance('player');
const defaultLevels = [{ id: -1, label: 'Auto' }];
const rateOptions = [
  { id: '0.25', label: '0.25x', tag: 'Slow' },
  { id: '0.5', label: '0.5x' },
  { id: '1.0', label: '1x', tag: 'Normal' },
  { id: '1.25', label: '1.25x' },
  { id: '1.5', label: '1.5x' },
  { id: '2.0', label: '2x', tag: 'Fast' },
];

export default class ClapprSettingsPlugin extends UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }
  get name() {
    return 'clappr_settings';
  }
  get attributes() {
    return {
      class: 'playback_rate',
      'data-playback-rate-select': '',
    };
  }

  bindEvents() {
    var mediaControl = this.core.mediaControl;
    var container = this.core.activeContainer;
    if (this.core.isReady) {
      this.bindPlaybackEvents();
    } else {
      this.listenTo(this.core, Events.CORE_READY, this.bindPlaybackEvents.bind(this));
    }
    if (container) {
      this.listenTo(container, Events.CONTAINER_PLAYBACKDVRSTATECHANGED, this.render.bind(this));
    }
    this.listenTo(this.core, Events.CORE_ACTIVE_CONTAINER_CHANGED, this.reload.bind(this));
    this.listenTo(mediaControl, Events.MEDIACONTROL_RENDERED, this.render.bind(this));
    this.listenTo(mediaControl, Events.MEDIACONTROL_HIDE, this.onHide.bind(this));
    this.listenTo(mediaControl, Events.MEDIACONTROL_SHOW, this.onShow.bind(this));
  }

  labelifyLevels() {
    this.levels.forEach((l) => {
      var level = l.level || {};
      if (level.height) {
        var frameRate = (level.attrs || {})['FRAME-RATE'] || 30;
        if (frameRate >= 50) {
          //50 is high enough to call 60
          l.label = `${l.level.height}p60`;
        } else {
          l.label = `${l.level.height}p`;
        }
      }
    });
  }

  bindPlaybackEvents() {
    let playback = this.core.getCurrentPlayback();

    this.listenTo(playback, Events.PLAYBACK_LEVELS_AVAILABLE, (levels) => {
      this.levels = defaultLevels.concat(levels);
      this.labelifyLevels();
      this.render();
    });
    this.listenTo(playback, Events.PLAYBACK_LEVEL_SWITCH_START, (nextLevelIndex) => {
      this.nextLevelIndex = nextLevelIndex;
      this.switchingLevel = true;
      this.render();
    });
    this.listenTo(playback, Events.PLAYBACK_LEVEL_SWITCH_END, () => {
      this.nextLevelIndex = this.bestGuessCurrentLevel;
      this.switchingLevel = false;
      this.render();
    });
  }

  unBindEvents() {
    var mediaControl = this.core.mediaControl;
    var container = this.core.activeContainer;
    var playback = this.core.getCurrentPlayback();

    this.stopListening(this.core, Events.CORE_READY);
    if (container) this.stopListening(container, Events.CONTAINER_PLAYBACKDVRSTATECHANGED);
    this.stopListening(mediaControl, Events.CORE_ACTIVE_CONTAINER_CHANGED);
    this.stopListening(mediaControl, Events.MEDIACONTROL_RENDERED);
    this.stopListening(mediaControl, Events.MEDIACONTROL_HIDE);
    this.stopListening(mediaControl, Events.MEDIACONTROL_SHOW);
    this.stopListening(playback, Events.PLAYBACK_LEVELS_AVAILABLE);
    this.stopListening(playback, Events.PLAYBACK_LEVEL_SWITCH_START);
    this.stopListening(playback, Events.PLAYBACK_LEVEL_SWITCH_END);
  }

  reload() {
    this.levels = [];
    this.switchingLevel = false;
    this.nextLevelIndex = -1;
    this.currentRate = '1.0';
    this.unBindEvents();
    this.bindEvents();
  }

  render() {
    this.core.mediaControl.$('.media-control-right-panel').append(this.el);
    setTimeout(() => {
      // XXX: try to work around IE11 issue with DOM mutations
      let component = React.createElement(SettingsCog, this.reactComponentProps);
      ReactDOM.render(component, this.el);
    }, 0);
    return this;
  }

  destroy() {
    super.destroy();
    ReactDOM.unmountComponentAtNode(this.el);
  }

  get bestGuessCurrentLevel() {
    // First, try to snoop into hls.js internals
    let playback = this.core.getCurrentPlayback() || {};
    var hls = playback._hls || playback.hls;
    let levelIndex = -1;
    if (hls) {
      levelIndex = hls.levelController.currentLevelIndex;
    }
    if (levelIndex >= 0) {
      return levelIndex;
    }
    // Then try Clappr playback
    levelIndex = (this.core.getCurrentPlayback() || {}).currentLevel;
    return levelIndex;
  }

  get isHD() {
    // this.core.mediaControl.isHD is horribly unreliable during switching
    let currentLevelIndex = this.bestGuessCurrentLevel;
    if (isNaN(currentLevelIndex) || currentLevelIndex < 0) {
      return (this.core.mediaControl || {}).isHD;
    }
    let level;
    this.levels.forEach((l) => {
      if (l.id == currentLevelIndex) {
        level = l;
      }
    });
    return level && level.label.substring(0, level.label.indexOf('p')) >= 720;
  }

  get reactComponentProps() {
    if (isNaN(this.nextLevelIndex) || this.nextLevelIndex < 0) {
      // handle case of switching from AUTO->explicit level->AUTO without a change
      this.nextLevelIndex = this.bestGuessCurrentLevel;
    }
    return {
      currentLevel: (this.core.getCurrentPlayback() || {}).currentLevel,
      isHD: this.isHD,
      levels: this.levels,
      rates: rateOptions,
      switchingLevel: this.switchingLevel,
      nextLevelIndex: this.nextLevelIndex,
      currentRate: this.currentRate,
      showQualitySelector: this.showQualitySelector,
      showRateSelector: this.showRateSelector,
      forceMenuClose: this.forceMenuClose,
      onChangeRate: (newRate) => this.onChangeRate(newRate),
      onChangeLevel: (newLevel) => this.onChangeLevel(newLevel),
      onToggleMenuVisibility: (visible) => this.onToggleMenuVisibility(visible),
    };
  }

  get showQualitySelector() {
    // only show if more than 1 level (besides AUTO) is available
    return this.levels && this.levels.length > 2;
  }

  get isLive() {
    var playback = this.core.getCurrentPlayback();
    return playback && playback.getPlaybackType() == Playback.LIVE;
  }

  get showRateSelector() {
    // don't allow toggling of speed for live playback (only for recorded or DVR)
    var container = this.core.getCurrentContainer() || {};
    var isDVRMode = container.dvrInUse;
    return !this.isLive || isDVRMode;
  }

  onChangeRate(newRate) {
    this.core.$el.find('video, audio').get(0).playbackRate = parseFloat(newRate); 
    this.currentRate = newRate;
    this.render();
    ga('send', 'event', 'player', 'changeRate', newRate);
  }

  onChangeLevel(newLevel) {
    var playback = this.core.getCurrentPlayback() || {};
    playback.currentLevel = newLevel;
    this.currentLevel = newLevel;
    this.render();
    ga('send', 'event', 'player', 'changeLevel', newLevel);
  }

  onHide() {
    this.core.$el.removeClass('bump-text-track');
    this.forceMenuClose = true;
    this.onToggleMenuVisibility(false);
    this.render(); //trigger the react component to ditch the menu
  }

  onShow() {
    this.core.$el.addClass('bump-text-track');
    this.forceMenuClose = false;
    this.render(); //trigger the react component to ditch the menu
  }

  onToggleMenuVisibility(visible) {
    // XXX: the watermark plugin has a position/stacking issue sometimes with this menu,
    // so we need to fade it out when we're showing this menu to make sure it can't get in the way.
    var $watermark = this.core.$el.find('[data-watermark]');
    if (visible) {
      $watermark.addClass('settings-menu-open');
    } else {
      $watermark.removeClass('settings-menu-open');
    }
  }
}

ClapprSettingsPlugin.type = 'core';
