import Reflux from 'reflux';

export var ActionsFactory = () =>
  Reflux.createActions([
    // Supported by CurrentChannelStore
    'LoadChannel',
    'LoadChannelError',

    // Supported by HighlightListStore
    'LoadHighlight',
    'LoadHighlights',
    'LoadMoreHighlights',

    // Supported by UserArgsStore
    'UpdateContentSettings',
    'ContentSettingsChanged',

    // Supported by CurrentChannelStore and SelectedBroadcastStore
    // Called by SelectedBroadcastStore
    'SwitchToPreview',
    'PreviewExpired',
    'RefreshSelectedBroadcastMeta',
    'WatchBroadcastAgain',
    'RedirectToAllowedHost',

    // Supported by SelectedBroadcastStore
    // Called by SelectedBroadcastStore and CurrentChannelStore
    //  ... previously called ChangeVideo ...
    'SelectBroadcast',
    'CurrentPlaybackEnded',
    'PlaybackErrorOccurred',
    'PlaybackIsPlaying',

    // Supported by PlaylistStore
    'PlaylistSearch',
    'PlaylistPaginate',

    // Supported by TicketStore
    'PreviewVideoTimerTick',
    'StripeTokenAcquired',
    'TicketPurchaseCompleted',
    'DonationCompleted',
    'LoadStripeJs',
    'OpenPaymentModal',
    'ClosePaymentModal',
    'LoadCountries',
    'PreviewTicket',
    'PreviewTicketCompleted',

    // Highlightey Stuff
    'PlayHighlight',
    'ReturnToBroadcastFromHighlight',

    // Playback specific actions
    'DVRModeChange',
    'TimeUpdate',
    'ReportTimeUpdated',
    'SeekTo',

    // Social menu
    'ShowShareMenu',
    'HideShareMenu',

    // Restrictions/Blocking
    'PasswordBlockUnlock',

    // Slots
    'UpdateSlot',
  ]);
