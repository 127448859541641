import Clappr from '@clappr/player';
import { BrowserStorage } from '../../utils';
import './closed-captions-plugin.scss';
import ccIcon from '!svg-inline-loader!../../styles/icons/closed-captions.svg';

const CAPTIONING_ENABLED_KEY = 'bx:cc-on';

export default class ClosedCaptions extends Clappr.UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }

  get name() {
    return 'closed_captions';
  }

  get template() {
    return Clappr.template(`
      <div>
        <button
          type="button"
          class="cc-button media-control-button media-control-icon"
          data-cc-button
          aria-label="<%= ariaLabel %>"
        ></button>
        <%= captionList %>
      </div>
      `);
  }

  get events() {
    return {
      'click [data-cc-button]': 'toggleCC',
    };
  }

  get attributes() {
    return {
      class: 'cc-controls',
      'data-cc-controls': '',
    };
  }

  get captionListVisible() {
    if (!this.$ccCaptionList) return false;
    return this.$ccCaptionList.style.display !== 'none';
  }

  set captionListVisible(val) {
    if (!this.$ccCaptionList) return;
    this.$ccCaptionList.style.display = (val ? '' : 'none');
  }

  constructor(core) {
    super(core);
    const config = core.options.closedCaptionsConfig;
    this._initialized = false;
    this._trackId = -1;
    this._ariaLabel = config && config.ariaLabel ? config.ariaLabel : 'cc-button';
    this._labelCb =
      config && config.labelCallback && typeof config.labelCallback === 'function'
        ? config.labelCallback
        : (track) => {
            return track.name;
          };
    this.closedCaptionsConfig = config;
  }

  bindEvents() {
    this.listenTo(this.core, Clappr.Events.CORE_ACTIVE_CONTAINER_CHANGED, this.containerChanged);
    this.listenTo(this.core.mediaControl, Clappr.Events.MEDIACONTROL_RENDERED, this.render);
    this.container = this.core.activeContainer;
    if (this.container) {
      this.listenTo(
        this.container,
        Clappr.Events.CONTAINER_SUBTITLE_AVAILABLE,
        this.onSubtitleAvailable
      );
      this.listenTo(
        this.container,
        Clappr.Events.CONTAINER_SUBTITLE_CHANGED,
        this.onSubtitleChanged
      );
      this.listenTo(this.container, Clappr.Events.CONTAINER_STOP, this.onContainerStop);
    }
    this.listenTo(this.core.mediaControl, Clappr.Events.MEDIACONTROL_HIDE, this.onHide);
  }

  onContainerStop() {
    this.ccAvailable(false);
  }

  onHide() {
    this.captionListVisible = false;
  }

  containerChanged() {
    this.ccAvailable(false);
    this.stopListening();
    this.bindEvents();
  }

  onSubtitleAvailable() {
    this.renderCcButton();
    this.ccAvailable(true);
  }

  onSubtitleChanged(track) {
    this.setCurrentTrack(track.id);

    // This initialization section is needed because this method gets called with track.id == -1
    // when the plugin is first loaded.
    if (this._initialized) {
      let enabled = track.id !== -1;
      BrowserStorage.setItem(CAPTIONING_ENABLED_KEY, enabled);
    } else {
      if (
        this.closedCaptionsConfig.defaultOn ||
        BrowserStorage.getItem(CAPTIONING_ENABLED_KEY, false)
      ) {
        this.enableCC(0, false);
        this.setCurrentTrack(0);
      } else {
        this.disableCC(false);
      }
      this._initialized = true;
    }
  }

  ccAvailable(hasCC) {
    const method = hasCC ? 'addClass' : 'removeClass';
    this.$el[method]('available');
  }

  setCurrentTrack(trackId) {
    if (this._trackId !== trackId) {
      this._trackId = trackId;
    }

    this.updateCCButton();
  }

  renderCcButton() {
    let tracks = this.container ? this.container.closedCaptionsTracks : [];

    for (let i = 0; i < tracks.length; i++) tracks[i].label = this._labelCb(tracks[i]);

    this.$el.html(
      this.template({
        ariaLabel: this._ariaLabel,
        captionList: this.renderCaptionsList(),
      })
    );

    this.$ccButton = this.$el.find('button.cc-button[data-cc-button]');
    // NOTE: tests were failing here – wrapping it in a try/catch for now.
    try {
      this.$ccButton.append(ccIcon);
    } catch {}
    this.$el.append(this.style);

    // Set the caption list element (for use in captionListVisible)
    this.$ccCaptionList = this.$el.find('ul[data-cc-captions-list]')[0];

    this.registerCaptionListButtons();
  }

  render() {
    this.renderCcButton();
    this.updateCCButton();

    const $fullscreen = this.core.mediaControl.$el.find('button[data-fullscreen]');
    if ($fullscreen[0]) {
      this.$el.insertAfter($fullscreen[0]);
    } else {
      this.core.mediaControl.$('.media-control-right-panel')
        .prepend(this.el);
    }

    return this;
  }

  // renderCaptionsList renders the portion of the HTML that displays the list of available
  // captions, along with a header to indicate available captions.
  renderCaptionsList() {
    return `
      <ul class="boxcast-clappr-menu" data-cc-captions-list ${this.captionListVisible ? '' : 'style="display: none"'}>
        <li>
          <span class="boxcast-clappr-menu-title">Captions</span>
        </li>
        ${this.renderCaptionListItems()}
      </ul>
    `;
  }

  // renderCaptionListItems renders the portion of the HTML that lists available captions, as well
  // as a "None" entry to turn captions off entirely.
  renderCaptionListItems() {
    const createClosedCaptionButton = (label, ariaLabel, dataTrackId, isChecked) => `
      <li class="link">
        <button
          type="button"
          aria-label="${ariaLabel}"
          class="boxcast-unstyled-button"
          ${dataTrackId}
        >
          <span class="boxcast-clappr-menu-label">${label}</span>
          <span class="boxcast-clappr-menu-icon-right">
            <i class="boxcast-cc-checked${isChecked ? ' boxcast-icon-check' : ''}" ${dataTrackId}-checked></i>
          </span>
        </button>
      </li>
    `;
    const disableCaptionsButton = createClosedCaptionButton(
        'None',
        'Disable captions',
        'data-cc-disable-captions',
        this._trackId === -1
      )
    const tracks = this.container ? this.container.closedCaptionsTracks : [];
    const trackButtons = tracks.map(track =>
      createClosedCaptionButton(
        track.name,
        `Choose ${track.name} captions`,
        `data-cc-enable-caption-id-${track.id}`,
        this._trackId === track.id
      )
    );

    return [disableCaptionsButton, ...trackButtons].join();
  }

  // registerCaptionListButtons finds all the buttons created in renderCaptionListItems and add
  // click handlers to actually call disableCC/enableCC.
  registerCaptionListButtons() {
    const disableButton = this.$el.find('button[data-cc-disable-captions]')[0];
    if (disableButton) {
      disableButton.addEventListener('click', () => {
        this.disableCC(true);
        this.captionListVisible = false;
      });
    }

    let tracks = this.container ? this.container.closedCaptionsTracks : [];
    for (const track of tracks) {
      const enableButton = this.$el.find(`button[data-cc-enable-caption-id-${track.id}]`)[0];
      if (enableButton) {
        enableButton.addEventListener('click', () => {
          this.enableCC(track.id, true);
          this.captionListVisible = false;
        });
      }
    }
  }

  updateCCButton() {
    if (this._trackId === -1) {
      this.$ccButton.removeClass('enabled');
    } else {
      this.$ccButton.addClass('enabled');
    }
  }

  // toggleCC is called when the closed caption plugin button is clicked.
  toggleCC() {
    let tracks = this.container ? this.container.closedCaptionsTracks : [];

    // If there are multiple captions, toggle the captions list to let the user choose.
    if (tracks.length > 1) {
      this.captionListVisible = !this.captionListVisible;
      return;
    }

    // If there's only one captions, just toggle it on/off
    if (this._trackId === -1 && tracks.length > 0) {
      this.enableCC(tracks[0].id, true);
    } else {
      this.disableCC(true);
    }
  }

  updateCaptionCheck(selectedIndex) {
    const allChecks = this.$el.find('i.boxcast-cc-checked');
    for (let i = 0; i < allChecks.length; i++) allChecks[i].classList.remove('boxcast-icon-check');
    const checkedChecks = this.$el.find(`i.boxcast-cc-checked[data-cc-${selectedIndex === -1 ? 'disable-captions' : `enable-caption-id-${selectedIndex}`}-checked]`);
    for (let i = 0; i < checkedChecks.length; i++) checkedChecks[i].classList.add('boxcast-icon-check');
  }

  // enableCC enables closed captions for the given text track ID. We use this rather than setting
  // the closedCaptionsTrackId property on the container because there are bugs in the hls.js
  // playback engine of Clappr that cause it to render a disabled track when there are multiple
  // captions and closedCaptionsTrackId is set to -1.
  //
  // This function sets the track with an id that matches trackId to 'showing', and all other tracks
  // to 'disabled'. If called with `doCallback=true`, it also calls onSubtitleChanged (which the
  // closedCaptionsTrackId setter would normally do for us). `doCallback` is set to false when
  // called from `onSubtitleChanged` in order to avoid endless cycles.
  enableCC(trackId, doCallback) {
    let tracks = this.container ? this.container.closedCaptionsTracks : [];
    let selectedTrack = undefined;
    for (const track of tracks) {
      if (track.id === trackId) {
        track.track.mode = 'showing';
        selectedTrack = track;
      } else {
        track.track.mode = 'disabled';
      }
    }
    this.updateCaptionCheck(trackId);
    if (doCallback) this.onSubtitleChanged(selectedTrack ?? {id: -1});
  }

  // disableCC disables all closed caption text tracks. We use this rather than setting the
  // closedCaptionsTrackId property on the container because there are bugs in the hls.js playback
  // engine of Clappr that cause it to render a disabled track when there are multiple captions and
  // closedCaptionsTrackId is set to -1.
  //
  // If called with `doCallback=true`, it also calls onSubtitleChanged (which the
  // closedCaptionsTrackId setter would normally do for us). `doCallback` is set to false when
  // called from `onSubtitleChanged` in order to avoid endless cycles.
  disableCC(doCallback) {
    let tracks = this.container ? this.container.closedCaptionsTracks : [];
    for (const track of tracks) track.track.mode = 'disabled';
    this.updateCaptionCheck(-1);
    if (doCallback) this.onSubtitleChanged({id: -1});
  }
}
