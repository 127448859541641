import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import { Clock, Logger } from '../../utils';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';
import * as DOMPurify from 'dompurify';

import BoxCastLinkback from './boxcast-linkback.jsx';
import BroadcastStartStopTime from '../library/broadcast-start-stop-time.jsx';
import BroadcastTags from '../library/broadcast-tags.tsx';
import DonationsWidget from '../monetization/donations-widget.jsx';
import Highlights from '../library/highlights.jsx';
import SocialButtons from '../library/social-buttons.jsx';
import TicketInfoRegion from './ticket-info-region.jsx';

const logger = Logger.getInstance('components');

const config = {
  USE_PROFILES: { html: true },
  ALLOWED_TAGS: ['a', 'b', 'i', 'strong'],
  ADD_ATTR: ['target', 'rel'],
  ADD_VALUES: {
    a: ['_blank', 'noopener'],
  },
};

var TitleDescriptionWell = createClass({
  displayName: 'TitleDescriptionWell',

  contextTypes: {
    model: PropTypes.object,
    userArgs: PropTypes.object,
    m: PropTypes.func,
  },

  mixins: [RefluxModelListenerMixin],

  refluxModelListeners: {
    SelectedBroadcastStore: 'onStoreChanged',
    UserArgsStore: 'onStoreChanged',
    Actions: [{ on: 'DonationCompleted', trigger: 'onDonationCompleted' }],
  },

  getInitialState: function () {
    const { SelectedBroadcastStore, CurrentChannelStore, UserArgsStore } = this.context.model;
    return {
      broadcast: SelectedBroadcastStore.broadcast,
      highlights: SelectedBroadcastStore.highlights,
      selectedHighlight: SelectedBroadcastStore.selectedHighlight,
      isPreview: SelectedBroadcastStore.timeLimitedPreview,
      ticket: SelectedBroadcastStore.ticket || CurrentChannelStore.ticket,
      autoOpenTicketWindow: UserArgsStore.autoOpenTicketWindow,
      showDonations: UserArgsStore.showDonations,
      donateMessage: UserArgsStore.donateMessage,
      showLinkback: !UserArgsStore.hide_linkback,
    };
  },

  onStoreChanged: function () {
    this.setState(this.getInitialState());
  },

  onDonationCompleted: function () {
    this.setState({ donationCompleted: true });
  },

  render: function () {
    const { broadcast, highlights, selectedHighlight, showLinkback } = this.state;
    const { userArgs } = this.context;

    if (
      userArgs.showTitle ||
      userArgs.showDescription ||
      userArgs.showHighlights ||
      userArgs.showDonations
    ) {
      let obj = selectedHighlight ? selectedHighlight : broadcast;
      const sanitizedHtml = DOMPurify.sanitize(obj.description_html, config);

      return (
        <div className="boxcast-well"> 
          {userArgs.showTitle ? <h1 className="boxcast-title">{obj.name}</h1> : <span />}
          {userArgs.showDescription ? (
            obj.description_html
              ? <div className="boxcast-description" dangerouslySetInnerHTML={{__html: sanitizedHtml }}></div>
              : <div className="boxcast-description">{obj.description}</div>
          ) : null}
          {userArgs.showDescription ? <BroadcastStartStopTime broadcast={obj} /> : <span />}
          <BroadcastTags broadcast={broadcast} />
          <TicketInfoRegion
            broadcast={this.state.broadcast}
            isPreview={this.state.isPreview}
            ticket={this.state.ticket}
            autoOpenTicketWindow={this.state.autoOpenTicketWindow}
            CurrentChannelStore={this.context.model.CurrentChannelStore}
            m={this.context.m}
          />
          {this.renderDonationsWidget()}
          {userArgs.showSocialShare && !obj.is_private ? <SocialButtons /> : <span />}
          {this.renderBackToBroadcastFromHighlight()}
          {this.props.showHighlights ? <Highlights highlights={highlights} /> : <span />}
          {showLinkback ? <BoxCastLinkback  /> : <span />}
        </div>
      );
    } else {
      return <span></span>;
    }
  },

  renderDonationsWidget: function () {
    const { m } = this.context;

    if (this.state.donationCompleted) {
      return (
        <div className="boxcast-ticket">
          <p>
            <strong>{m('donation_complete')}</strong>
          </p>
        </div>
      );
    } else if (this.state.showDonations) {
      return (
        <div className="boxcast-ticket">
          {this.state.donateMessage ? <p>{this.state.donateMessage}</p> : <span />}
          <DonationsWidget>
            <button type="button" className="boxcast-ticket-button">
              {m('donation_button_label')}
            </button>
          </DonationsWidget>
        </div>
      );
    } else {
      return <span></span>;
    }
  },

  renderBackToBroadcastFromHighlight: function () {
    const { broadcast, selectedHighlight } = this.state;
    const { Actions } = this.context.model;

    if (selectedHighlight) {
      return (
        <h4>
          Highlight from{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              Actions.ReturnToBroadcastFromHighlight();
            }}
          >
            {broadcast.name}
          </a>
        </h4>
      );
    } else {
      return <span></span>;
    }
  },
});

export default TitleDescriptionWell;
