import { Events, Styler, template, UICorePlugin } from '@clappr/player';
import watermarkStyle from './watermark.scss';
import watermarkHTML from './watermark.html';

export default class WaterMarkPlugin extends UICorePlugin {
  get name() {
    return 'watermark';
  }

  get template() {
    return template(watermarkHTML);
  }

  get mediaControl() {
    return this.core.mediaControl;
  }

  get container() {
    return this.core.getCurrentContainer();
  }

  bindEvents() {
    this.listenTo(this.mediaControl, Events.MEDIACONTROL_HIDE, this.onControlsHide);
    this.listenTo(this.mediaControl, Events.MEDIACONTROL_SHOW, this.onControlsShow);
    this.listenTo(this.mediaControl, Events.MEDIACONTROL_RENDERED, this.render);
  }

  onControlsShow() {
    // Slide the logo up so not covered by media controls
    this.container.$el.find('[data-watermark]').addClass('with-media-controls-shown');
  }

  onControlsHide() {
    // Slide the logo back down
    this.container.$el.find('[data-watermark]').removeClass('with-media-controls-shown');
  }

  render() {
    if (this.rendered) {
      return this;
    }
    if (!this.container) {
      setTimeout(() => this.render(), 100);
      return this;
    }
    const templateOptions = {
      position: this.options.position || 'bottom-right',
    };
    this.container.$el.append(this.template(templateOptions));
    this.rendered = true;
    return this;
  }
}

WaterMarkPlugin.type = 'core';
