import React from 'react';
import { Logger } from '../../utils';
import QualityPicker from './quality-picker.jsx';
import SettingsMenu from './settings-menu.jsx';
import RatePicker from './rate-picker.jsx';

const logger = Logger.getInstance('player');

export default class SettingsCog extends React.Component {
  constructor() {
    super();
    this.state = {
      menuVisible: false,
      submenu: null,
    };
  }

  render() {
    const { showQualitySelector, showRateSelector, forceMenuClose, isHD } = this.props;
    if (showQualitySelector || showRateSelector) {
      return (
        <div>
          <button
            type="button"
            className="boxcast-clappr-button"
            data-playback-rate-button
            aria-label="Player Settings (Quality, Speed, CC)"
            onClick={() => this.toggleMenu()}
          >
            <i className="boxcast-icon-cog"></i>
            {isHD ? <span className="boxcast-cog-hd">HD</span> : ''}
          </button>
          {this.state.menuVisible ? this.renderMenu() : <span />}
        </div>
      );
    } else {
      return <span />;
    }
  }

  static getDerivedStateFromProps(newProps, state) {
    if (newProps.forceMenuClose && state.menuVisible) {
      newProps.onToggleMenuVisibility(false);
      return { menuVisible: false };
    }
    return null;
  }

  onChangeRate(newRate) {
    if (this.props.onChangeRate) {
      this.props.onChangeRate(newRate);
    }
    // Go back to main menu
    this.setState({ submenu: null });
  }

  onChangeLevel(newLevel) {
    if (this.props.onChangeLevel) {
      this.props.onChangeLevel(newLevel);
    }
    // Go back to main menu
    this.setState({ submenu: null });
  }

  renderMenu() {
    if (this.state.submenu == 'quality') {
      return (
        <QualityPicker
          onBack={() => this.setState({ submenu: null })}
          onChangeLevel={(newLevel) => this.onChangeLevel(newLevel)}
          currentLevel={this.props.currentLevel}
          nextLevelIndex={this.props.nextLevelIndex}
          levels={this.props.levels}
        />
      );
    } else if (this.state.submenu == 'speed') {
      return (
        <RatePicker
          onBack={() => this.setState({ submenu: null })}
          onChangeRate={(newRate) => this.onChangeRate(newRate)}
          currentRate={this.props.currentRate}
          rates={this.props.rates}
        />
      );
    } else {
      return (
        <SettingsMenu onChangeMenu={(menu) => this.setState({ submenu: menu })} {...this.props} />
      );
    }
  }

  toggleMenu() {
    var currentlyOpen = this.state.menuVisible;
    this.setState({
      menuVisible: !currentlyOpen,
      submenu: null, //always start at the root menu
    });
    this.props.onToggleMenuVisibility(!currentlyOpen);
  }
}
