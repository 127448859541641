// The Highlights component is the top-level component used to load a channel
// into view for an embed widget.  It manages the context for all sub-components
// in the tree and supports the flux models/stores.
//
// Usage:
//
//    <Highlights userArgs={ dictOfUserArgs }
//               model={ modelFactoryInstance } />
//

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Reflux from 'reflux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import Messages from '../i18n';
import { Clock, Logger } from '../utils';
import { animateIntoView } from '../utils/dom';
import { ga } from '../ga';
import Config from '../config';
import BoxOfficeReloadMixin from '../mixins/box-office-reload-mixin';
import RefluxModelListenerMixin from '../mixins/reflux-model-listener-mixin';
import ResizeMixin from '../mixins/resize-mixin';

import TopThirdOverlay from '../components/player-overlays/top-third-overlay.jsx';
import PreviewIcon from '../components/library/preview-icon.jsx';
import HighlightEndedOverlay from '../components/player-overlays/highlight-ended-overlay.jsx';
import NonBreakingSpace from '../components/library/nbsp.jsx';

import Player from '../player/player.jsx';

const logger = Logger.getInstance('components');

const HighlightsLayout = createClass({
  displayName: 'HighlightsLayout',

  ///
  /// Tee up the React lifecycle for this component
  ///

  propTypes: {
    model: PropTypes.object,
    userArgs: PropTypes.object,
    showList: PropTypes.bool,
  },

  refluxModelListeners: {
    HighlightListStore: 'onStoreChanged',
    CurrentChannelStore: 'onStoreChanged',
    SelectedBroadcastStore: 'onStoreChanged',
    Actions: [
      { on: 'SelectBroadcast', trigger: 'onSelectBroadcast' },
      { on: 'PlayHighlight', trigger: 'onSelectBroadcast' },
    ],
  },

  mixins: [BoxOfficeReloadMixin, RefluxModelListenerMixin, ResizeMixin],

  childContextTypes: {
    model: PropTypes.object,
    userArgs: PropTypes.object,
    m: PropTypes.func,
    env: PropTypes.func,
  },

  getChildContext: function () {
    // We extract some special metadata that can flow freely throughout the
    // component tree without explicitly passing as props/state as a shortcut.
    // This utilizes the React `context` paradigm.
    return {
      model: this.props.model,
      userArgs: this.props.userArgs,
      m: this.m,
      env: this.env,
    };
  },

  m: function (...args) {
    // Shortcut method to allow user-supplied messages to override defaults
    return Messages.factory(this.props.userArgs.messages)(...args);
  },

  env: function (key) {
    // Shortcut method to allow user-supplied environment to override defaults
    return Config.factory(this.props.userArgs.env)(key);
  },

  getDefaultProps: function () {
    return {
      model: {},
      userArgs: {},
    };
  },

  getInitialState: function () {
    const { SelectedBroadcastStore, CurrentChannelStore, HighlightListStore } = this.props.model;

    let lastSelectedHighlight = null;
    if (SelectedBroadcastStore.selectedHighlight == null) {
      const state = this.state || {};
      lastSelectedHighlight = state.selectedHighlight || state.lastSelectedHighlight;
    }

    let state = {
      channelId: HighlightListStore.channelId,
      broadcast: SelectedBroadcastStore.broadcast,
      view: SelectedBroadcastStore.view,
      highlightsByBroadcast: HighlightListStore.highlightsByBroadcast,
      highlights: HighlightListStore.highlights,
      selectedHighlight: SelectedBroadcastStore.selectedHighlight,
      pagination: HighlightListStore.pagination,
      isLoading: !!(
        SelectedBroadcastStore.loading ||
        CurrentChannelStore.loading ||
        HighlightListStore.loading
      ),
      ended: SelectedBroadcastStore.ended,
      lastSelectedHighlight: lastSelectedHighlight,
    };

    return state;
  },

  onStoreChanged: function () {
    // XXX: if getInitialState acquires any resonsibilities outside of stores, we'll
    // need to rejigger this to only reset the things under the store's influence
    this.setState(this.getInitialState());

    // New video was possibly selected; need to reset the reloader mixin
    this.initBoxOfficeReloadMixin();
  },

  onSelectBroadcast: function () {
    // New video was selected; need to reset the reloader mixin
    this.initBoxOfficeReloadMixin();

    /*
     * XXX: many customers embed these into a news article; it'd be awful to
     * have them auto-scroll to the video before they actually play it. Don't do that!
    // Wait a few ticks to let player render, then scroll it into view
    setTimeout(() => {
      var playerElement = ReactDOM.findDOMNode(this.refs.playerElement);
      if (playerElement) {
        animateIntoView(playerElement, 500);
      } else {
        console.warn('Could not find player element to scroll to.');
      }
    }, 100);
    */
  },

  ///
  /// Render the component
  ///

  renderPlayer: function () {
    const { broadcast, view, selectedHighlight } = this.state;
    const { userArgs } = this.props;

    if (view && view.playlist) {
      return (
        <Player
          broadcast={selectedHighlight || broadcast}
          broadcastView={view}
          autoplay={userArgs.autoplay || this._autoplayOnNext}
          customPoster={userArgs.poster}
          ref="playerElement"
        />
      );
    } else {
      return <span />;
    }
  },

  renderLoading: function () {
    // TODO: consider differentiating between different types of loading
    const { isLoading } = this.state;

    return (
      <CSSTransition in={false} timeout={300} classNames="boxcast-loader-react-transition">
        {isLoading ? (
          <div key="box-office-loading" className="boxcast-loader-overlay">
            <div className="boxcast-loader"></div>
          </div>
        ) : (
          <span key="box-office-not-loading" />
        )}
      </CSSTransition>
    );
  },

  render: function () {
    const { userArgs, model, showList } = this.props;
    const { selectedHighlight, broadcast, componentWidthDesc, highlights, pagination, isLoading } =
      this.state;
    const { Actions } = model;

    let playerBg = {};
    if (userArgs.poster) {
      playerBg.backgroundImage = `url("${userArgs.poster}")`;
      playerBg.backgroundSize = 'cover';
      playerBg.backgroundRepeat = 'no-repeat';
      playerBg.backgroundPosition = '50% 50%';
    }

    let aspectRatioClass = (userArgs.aspectRatio || '16:9').replace(':', '_');

    let classNames = [
      `boxcast-boxoffice`,
      `boxcast-theme-${userArgs.theme || 'light'}`,
      `boxcast-ar-${aspectRatioClass}`,
      `boxcast-size-${componentWidthDesc}`,
    ].join(' ');

    if (!showList) {
      return (
        <div className={classNames}>
          <div className="boxcast-highlights boxcast-highlights-large">
            {this.renderPlayerWrapper()}
          </div>
        </div>
      );
    } else {
      return (
        <div className={classNames}>
          {!isLoading && !highlights.length ? (
            <h4>No highlights were found</h4>
          ) : (
            this.renderHighlightsGrid(highlights)
          )}
          {pagination.next ? (
            <button
              type="button"
              disabled={isLoading}
              onClick={() => !isLoading && Actions.LoadMoreHighlights()}
            >
              {isLoading ? 'Loading...' : 'Load More'}
            </button>
          ) : (
            <span />
          )}
        </div>
      );
    }
  },

  renderHighlightsGrid: function (highlights) {
    return (
      <div className="boxcast-highlights boxcast-highlights-large">
        <ul>{highlights.map((h) => this.renderHighlight(h))}</ul>
      </div>
    );
  },

  renderHighlight: function (highlight) {
    const { channelId, selectedHighlight, lastSelectedHighlight, broadcast, ended } = this.state;
    const { userArgs, model } = this.props;
    if (highlight === selectedHighlight) {
      return (
        <li
          key={highlight.id}
          className="boxcast-highlight-list-item boxcast-highlight-list-item-playing"
        >
          {this.renderPlayerWrapper()}
        </li>
      );
    } else if (!selectedHighlight && lastSelectedHighlight && highlight === lastSelectedHighlight) {
      return (
        <li
          key={highlight.id}
          className="boxcast-highlight-list-item boxcast-highlight-list-item-playing"
        >
          {this.renderPlayerWrapper()}
        </li>
      );
    } else {
      return (
        <li key={highlight.id} className="boxcast-highlight-list-item">
          <PreviewIcon
            image={highlight.preview}
            duration={Clock.durationFormatted(highlight.duration)}
            onClick={(e) => this.playHighlight(e, highlight)}
          />
          <div className="boxcast-highlights-overlay">
            <h3>{highlight.name}</h3>
            <h5>
              {Clock.formatRelative(Clock.parse(highlight.streamed_at))}
              <span style={{ opacity: 0.6 }}>
                <NonBreakingSpace />|<NonBreakingSpace />
              </span>
              {Clock.durationFormatted(highlight.duration, 'hms')}
            </h5>
            <h5>From {highlight.broadcast_name}</h5>
          </div>
        </li>
      );
    }
  },

  renderPlayerWrapper: function () {
    return (
      <div key="player-container-visible">
        <div className="boxcast-player-container">
          <div className="boxcast-player-wrapper">
            <div className="boxcast-player-inner">{this.renderPlayer()}</div>
            <TopThirdOverlay />
            {this.renderLoading()}
            {this.renderEnded()}
          </div>
        </div>
      </div>
    );
  },

  renderEnded: function () {
    const { userArgs } = this.props;
    if (!this.state.ended) {
      return <span />;
    }
    return (
      <HighlightEndedOverlay
        showSocialShare={userArgs.showSocialShare}
        broadcast={this.state.broadcast}
        channelId={this.state.channelId}
      />
    );
  },

  playHighlight: function (e, highlight) {
    const { Actions } = this.props.model;
    this._autoplayOnNext = true;
    e.preventDefault();
    Actions.PlayHighlight(highlight);
    ga('send', 'event', 'highlight', 'playHighlight');
  },

  /*
  renderHighlightsByBroadcastGrid: function() {
    const { highlightsByBroadcast } = this.state;
    return highlightsByBroadcast.map((broadcast) => this.renderHighlightsByBroadcastRow(broadcast));
  },

  renderHighlightsByBroadcastRow: function(broadcast) {
    const title = (
      <span>
        Highlights from {broadcast.name}
      </span>
    );
    return (
      <div key={broadcast.id}>
          <Highlights title={title} highlights={broadcast.highlights} />
      </div>
    );
  }
  */
});

export default HighlightsLayout;
