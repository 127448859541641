import { Util } from './utils';

const BLOCKED_ACCOUNT_IDS = ['bvxe7mo9gomf9gyelsea'];

// NOTE: assetBase needs to be synced with deploy.config.js
const BY_ENV = {
  _shared: {
    version: VERSION,
    gaTrackerName: '__boxcastGA',
    gaTrackerCode: 'UA-15394876-4',
    defaultSocialShareThumb: 'https://dashboard.boxcast.com/img/boxcast_social_play_square.png',
    playlistPageSize: 5,
    googleMapsAPIKey: 'AIzaSyCrs_AAbiu1dAJDyE73l6-uZh5TPo3de7c',
    stripeElementsOptions: {
      hidePostalCode: true, // https://github.com/stripe/react-stripe-elements/issues/20
    },
  },
  localhost: {
    // Note: when running this code locally, most of the configuration options in the localhost section are never applied. By default, the config section matches the embed code env option, so if you are testing against a prod video, that instance of the player will use the prod configuration even when running locally.
    apiRoot: 'http://localhost:3000',
    assetBase: `http://localhost:8080/build`,
    metricsUrl: 'http://localhost:8080/dev/null',
    iframeUrl: 'http://localhost:8000/embed.html#',
    ticketPurchaseUrl: 'http://localhost:8000/channel',
    stripeKey: 'n/a',
    previewSeconds: 10,
    previewBufferSize: 5,
    purchasedBufferSize: 5,
    allowNonSSLCheckout: false,
    socialShareUrlPrefix: 'https://dev.boxcast.tv',
    geoServiceUrl: 'https://geo.dev.boxcast.com/json/',
    pdfServiceUrl: 'http://localhost:8080/viewer.html?file=',
    chromecastReceiverAppID: 'C0565D00', // XXX: this is the "localhost" app; you'll most likely need to change its config to point to your local server on the local network
    blockedAccountIDs: BLOCKED_ACCOUNT_IDS,
  },
  test: {
    apiRoot: 'https://rest.dev.boxcast.com',
    assetBase: `http://localhost:8080/build`,
    metricsUrl: 'http://localhost:8080/dev/null',
    iframeUrl: 'https://dev.boxcast.tv/embed.html#',
    ticketPurchaseUrl: 'http://localhost:8000/channel',
    stripeKey: 'n/a',
    previewSeconds: 10,
    previewBufferSize: 5,
    purchasedBufferSize: 5,
    allowNonSSLCheckout: false,
    socialShareUrlPrefix: 'https://dev.boxcast.tv',
    geoServiceUrl: 'https://geo.dev.boxcast.com/json/',
    pdfServiceUrl: 'https://js.dev.boxcast.com/latest/viewer.html?file=',
    chromecastReceiverAppID: '313DB1A5',
    blockedAccountIDs: BLOCKED_ACCOUNT_IDS,
  },
  dev: {
    apiRoot: 'https://rest.dev.boxcast.com',
    assetBase: 'https://js.dev.boxcast.com/latest',
    metricsUrl: 'https://metrics.dev.boxcast.com/player/interaction',
    iframeUrl: 'https://dev.boxcast.tv/embed.html#',
    ticketPurchaseUrl: 'https://dev.boxcast.tv/channel',
    stripeKey: 'pk_test_jJXNd6tHTMtwyQWWHE4lGQvJ',
    previewSeconds: 10,
    previewBufferSize: 25, //remember countdown timer for last N previews in browser cache
    purchasedBufferSize: 50, //remember last N tickets in browser cache
    allowNonSSLCheckout: true,
    socialShareUrlPrefix: 'https://dev.boxcast.tv',
    geoServiceUrl: 'https://geo.dev.boxcast.com/json/',
    pdfServiceUrl: 'https://js.dev.boxcast.com/latest/viewer.html?file=',
    chromecastReceiverAppID: '313DB1A5',
    blockedAccountIDs: BLOCKED_ACCOUNT_IDS,
  },
  prod: {
    apiRoot: 'https://rest.boxcast.com',
    assetBase: `https://js.boxcast.com/${VERSION}`,
    metricsUrl: 'https://metrics.boxcast.com/player/interaction',
    iframeUrl: 'https://boxcast.tv/embed.html#',
    ticketPurchaseUrl: 'https://boxcast.tv/channel',
    stripeKey: 'pk_live_UJ0NcpFDJTsAbVzzYkcohioj',
    previewSeconds: 90,
    previewBufferSize: 25, //remember countdown timer for last N previews in browser cache
    purchasedBufferSize: 50, //remember last N tickets in browser cache
    allowNonSSLCheckout: false,
    socialShareUrlPrefix: 'https://boxcast.tv',
    geoServiceUrl: 'https://geo.boxcast.com/json/',
    pdfServiceUrl: `https://js.boxcast.com/${VERSION}/viewer.html?file=`,
    chromecastReceiverAppID: '6FCDEF76',
    blockedAccountIDs: BLOCKED_ACCOUNT_IDS,
  },
};

function byEnv(env) {
  if (env && typeof BY_ENV[env] != 'undefined') {
    return Util.mergeDict(BY_ENV._shared, BY_ENV[env]);
  } else {
    return Util.mergeDict(BY_ENV._shared, BY_ENV.prod);
  }
}

class Config {
  constructor() {
    this.dict = {};
    this._seeded = false;
  }

  get(key) {
    if (!this._seeded) {
      this.seed(null); //default to prod if trying to access keys before official env seeding
    }
    return this.dict[key];
  }

  seed(args) {
    // Merge the environment-specific config with any user args
    args = args || {};
    if (!args.env) args.env = this.dict.env; //handle re-seed
    this.dict = Util.mergeDict(byEnv(args.env), args);
    this._seeded = true;
  }

  add(key, value) {
    if (!this._seeded) {
      this.seed(null); //default to prod if trying to access keys before official env seeding
    }
    this.dict[key] = value;
  }

  factory(env) {
    var config = byEnv(env);
    return (key) => {
      return config[key];
    };
  }
}

export default new Config();
