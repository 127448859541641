import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { ga } from '../../ga';

import { Clock } from '../../utils';

export default createClass({
  displayName: 'VideoIndex',

  contextTypes: {
    model: PropTypes.object,
  },

  propTypes: {
    cues: PropTypes.array,
  },

  render: function () {
    const { cues } = this.props;
    var i = 0;
    return (
      <div className="boxcast-playlist-list">
        <ul>{cues.map((c) => this.renderCue(c, i++))}</ul>
      </div>
    );
  },

  renderCue: function (cue, idx) {
    let className = 'boxcast-playlist-item boxcast-selectable';
    return (
      <li key={idx} className={className} style={{ minHeight: 'auto' }}>
        <p style={{ float: 'right' }}>({cue.playback_start_time_fmt})</p>
        <h3>
          <a href="#" onClick={(e) => this.switchTo(e, cue)}>
            {cue.name}
          </a>
        </h3>
      </li>
    );
  },

  switchTo: function (e, cue) {
    const { Actions } = this.context.model;
    e.preventDefault();
    Actions.SeekTo(Clock.formattedToSeconds(cue.playback_start_time_fmt));
    ga('send', 'event', 'cue', 'switchTo');
  },
});
