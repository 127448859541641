// Helper mixin to watch window size and measure the width of the component.
// This is useful because CSS @media queries can only handle the entire window
// width, but we need to make sure to handle measurements based on our component,
// which is often just a fraction of the page width.
//
// The following state variables are added to any component that includes this mixin:
// - state.componentWidth - (number) Last observed width in pixels
// - state.componentWidthDesc - (string) Mapping of last observed width to the string xs, sm, md, lg
// - state.maxObservedComponentWidth - (number) Greatest observed width in pixels through any resize events
import ReactDOM from 'react-dom';
import { xsMax, smMax, mdMax } from '../breakpoints';

function widthToSizeDescription(width) {
  if (width <= xsMax) return 'xs';
  if (width <= smMax) return 'sm';
  if (width <= mdMax) return 'md';
  return 'lg';
}

var ResizeMixin = {
  // Lifecycle methods
  componentDidMount: function () {
    this.$resizeMixin$domNode = ReactDOM.findDOMNode(this);

    var width = this.$resizeMixin$getWidth();
    this.setState({
      maxObservedComponentWidth: width,
      componentWidth: width,
      componentWidthDesc: widthToSizeDescription(width),
    });
    if (width == 0) {
      // If component or any ancestor is display:none, this won't be available ... keep trying until it is
      this.$resizeMixin$interval = setInterval(() => this.$resizeMixin$onResize(), 100);
    }
    window.addEventListener('resize', this.$resizeMixin$onResize, true);
  },
  componentWillUnmount: function () {
    window.removeEventListener('resize', this.$resizeMixin$onResize, true);
    if (this.$resizeMixin$interval) {
      clearInterval(this.$resizeMixin$interval);
    }
  },

  // Private methods (prefixed to avoid collisions on parent components)
  $resizeMixin$getWidth: function () {
    return this.$resizeMixin$domNode.getBoundingClientRect().width;
  },
  $resizeMixin$onResize: function () {
    var width;
    try {
      width = this.$resizeMixin$getWidth();
    } catch (e) {
      console.error('Error computing width', e);
      // If this was called from interval and component was forcefully unmounted between ticks,
      // we could get an error here and need to cancel.
      if (this.$resizeMixin$interval) {
        clearInterval(this.$resizeMixin$interval);
        this.$resizeMixin$interval = null;
        return;
      }
    }
    if (width != this.state.width) {
      this.setState({
        maxObservedComponentWidth:
          width > this.state.maxObservedComponentWidth
            ? width
            : this.state.maxObservedComponentWidth,
        componentWidth: width,
        componentWidthDesc: widthToSizeDescription(width),
      });
      if (this.$resizeMixin$interval) {
        clearInterval(this.$resizeMixin$interval);
        this.$resizeMixin$interval = null;
      }
    }
  },
};

export default ResizeMixin;
