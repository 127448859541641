import Reflux from 'reflux';
import Promise from 'bluebird';

import API from '../api';
import Config from '../config';
import { BrowserStorage, Logger } from '../utils';
import { timeframe } from '../utils/broadcast';

const logger = Logger.getInstance('models');

export var HighlightListStoreFactory = (Actions, SelectedBroadcastStore, UserArgsStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.channelId = null;
      this.highlights = [];
      this.highlightsByBroadcast = [];
      this.loading = false;
      this.error = null;
      this.args = {};
      this.callbacks = {};
      this.pagination = {};
      this.pageSize = 20;
      this.sortOrder = UserArgsStore.highlightsSort || '-streamed_at';
    },

    // Public methods
    getNextHighlightAfter: function (selectedHighlight) {
      var justGrabNext = false;
      for (let i = 0; i < this.highlightsByBroadcast.length; i++) {
        let highlights = this.highlightsByBroadcast[i].highlights;
        const curIdx = highlights.map((h) => h.id).indexOf(selectedHighlight.id);
        if (curIdx >= 0 && curIdx + 1 < highlights.length) {
          return highlights[curIdx + 1];
        } else if (curIdx >= 0) {
          justGrabNext = true;
        } else if (justGrabNext && highlights.length) {
          return highlights[0];
        }
      }
      return null;
    },

    // Action bindings
    onLoadHighlight: function (highlightId, args) {
      logger.log('HighlightListStore#onLoadHighlight', highlightId, args);

      // Reset state of store
      this.init();
      this.callbacks.onLoadHighlights = args.onLoadHighlights;
      this.channelId = highlightId;
      this.loading = true;
      this.args = args;
      this.trigger();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      // Hit API
      return this._playHighlightById(highlightId)
        .then(() => {
          this.loading = false;
          this.trigger();
        })
        .then(() => {
          if (this.callbacks.onLoadHighlights) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => this.callbacks.onLoadHighlights(this.highlights), 0);
          }
        })
        .catch((error) => {
          if (!isThisRequestStillValid()) return null;
          this.error = error;
          this.loading = false;
          this.trigger();
        });
    },
    onLoadHighlights: function (channelId, args) {
      logger.log('HighlightListStore#onLoadHighlights', channelId, args);

      // Reset state of store
      this.init();
      this.callbacks.onLoadHighlights = args.onLoadHighlights;
      this.channelId = channelId;
      this.loading = true;
      this.args = args;
      this.trigger();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      // Hit API
      var promise = API.getWithPagination(`/channels/${this.channelId}/highlights`, {
        s: this.sortOrder,
      });
      return promise
        .then((response) => {
          if (!isThisRequestStillValid()) return null;
          this.pagination = response.pagination;
          this.highlights = response.data || [];
          return this._groupByBroadcast(this.highlights);
        })
        .then((highlightsByBroadcast) => {
          this.highlightsByBroadcast = highlightsByBroadcast;

          // XXX: The `autoplay` semantics here are slightly different than in the
          // normal loadChannel call; here they decide whether or not to select
          // a highlight vs. forcing user to select one.
          if (this.args.autoplay) {
            if (this.args.selectedHighlightId) {
              return this._playHighlightById(this.args.selectedHighlightId);
            } else if (!SelectedBroadcastStore.selectedHighlight) {
              return this._playFirstHighlight();
            }
          }
        })
        .then(() => {
          this.loading = false;
          this.trigger();
        })
        .then(() => {
          if (this.callbacks.onLoadHighlights) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => this.callbacks.onLoadHighlights(this.highlights), 0);
          }
        })
        .catch((error) => {
          if (!isThisRequestStillValid()) return null;
          this.error = error;
          this.loading = false;
          this.trigger();
        });
    },

    onLoadMoreHighlights: function () {
      if (!this.pagination.next) {
        return;
      }

      this.loading = true;
      this.trigger();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      // Hit API
      var promise = API.getWithPagination(`/channels/${this.channelId}/highlights`, {
        s: this.sortOrder,
        p: this.pagination.next,
      });
      return promise
        .then((response) => {
          if (!isThisRequestStillValid()) return null;
          this.pagination = response.pagination;
          this.highlights = this.highlights.concat(response.data || []);
          return this._groupByBroadcast(this.highlights);
        })
        .then((highlightsByBroadcast) => {
          this.highlightsByBroadcast = highlightsByBroadcast;
          this.loading = false;
          this.trigger();
        })
        .then(() => {
          if (this.callbacks.onLoadHighlights) {
            // Call this outside our stack to avoid client exceptions bubbling into here
            setTimeout(() => this.callbacks.onLoadHighlights(this.highlights), 0);
          }
        })
        .catch((error) => {
          if (!isThisRequestStillValid()) return null;
          this.error = error;
          this.loading = false;
          this.trigger();
        });
    },

    // Private methods
    _groupByBroadcast: function (channelHighlights) {
      // XXX: this isn't perfect because the API could paginate across broadcast boundaries,
      // and highlights could be interspersed if there were ongoing events.  But the sort order
      // is based on the event stream time (as opposed to when it was clipped), so it should
      // be close enough usually.
      var broadcastPromises = [];
      var bMap = {};
      channelHighlights.forEach((h) => {
        if (!bMap[h.broadcast_id]) {
          let promise = API.get(`/broadcasts/${h.broadcast_id}`).then((b) => {
            b.highlights = [];
            return b;
          });
          broadcastPromises.push(promise);
          bMap[h.broadcast_id] = broadcastPromises.length - 1;
        }
        broadcastPromises[bMap[h.broadcast_id]].then((b) => {
          h.broadcast_name = b.name; // XXX: API should provide this
          b.highlights.push(h);
        });
      });
      return Promise.all(broadcastPromises);
    },

    _playFirstHighlight: function () {
      if (this.highlightsByBroadcast.length) {
        Actions.PlayHighlight(this.highlightsByBroadcast[0].highlights[0]);
      }
    },

    _playHighlightById: function (id) {
      var highlight = this.highlights.filter((h) => h.id == id);
      if (highlight && highlight.length) {
        return Actions.PlayHighlight(highlight[0]);
      } else {
        // We don't have it yet; go get it from the api
        return API.get(`/highlights/${id}`).then((h) => {
          return Actions.PlayHighlight(h);
        });
      }
    },
  });
