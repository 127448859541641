import React from 'react';
import BuyTicketButton from '../monetization/buy-ticket-button.jsx';

export default function TicketInfoRegion(props) {
  const { broadcast, isPreview, ticket, autoOpenTicketWindow } = props;
  const { CurrentChannelStore, m } = props; // TODO: get from context

  // Prefer to purchase channel if we're in a channel view; fall back to broadcast ticket
  let channelTicket, ticketPrice;
  if (CurrentChannelStore.isTicketed) {
    channelTicket = true;
    ticketPrice = CurrentChannelStore.ticketPrice;
  } else {
    channelTicket = false;
    ticketPrice = broadcast.ticket_price;
  }

  if (isPreview) {
    const buttonLabel = m(
      `purchase_${channelTicket ? 'channel_' : ''}ticket_button`,
      '$' + parseFloat(ticketPrice, 10).toFixed(2)
    );
    return (
      <div className="boxcast-ticket">
        <BuyTicketButton autoOpenTicketWindow={autoOpenTicketWindow}>
          <button type="button" className="boxcast-ticket-button">
            {buttonLabel}
          </button>
        </BuyTicketButton>
      </div>
    );
  }

  if (ticket) {
    let message = m(`purchase_${channelTicket ? 'channel_' : ''}ticket_success_subtitle`, ticket);
    return (
      <div className="boxcast-ticket">
        <p className="boxcast-ticket-purchased">{message}</p>
      </div>
    );
  }

  return null;
}
