// Responsive breakpoints used by the player

// xsMin is the minimum pixel size of the container for the "xs" player layout
export const xsMin = 0;
// xsMax is the maximum pixel size of the container for the "xs" player layout
export const xsMax = 500;

// smMin is the minimum pixel size of the container for the "sm" player layout
export const smMin = 501;
// smMax is the maximum pixel size of the container for the "sm" player layout
export const smMax = 768;

// mdMin is the minimum pixel size of the container for the "md" player layout
export const mdMin = 769;
// mdMax is the maximum pixel size of the container for the "md" player layout
export const mdMax = 1024;

// lgMin is the minimum pixel size of the container for the "lg" player layout
export const lgMin = 1025;
// lgMax is the maximum pixel size of the container for the "lg" player layout
export const lgMax = Infinity;
