// The Watchdog class keeps track of the Clappr Player and reports
// back through the stores what is actually going on.
//
// NOTE: Clappr events are a bit odd; see this to start:
// https://github.com/clappr/clappr/issues/838
//
// The Watchdog instance supports numerous system features, including:
//  * tracking playback progress so ticket timer can count down
//  * facilitating error reporting when playback problems are detected
//  * reporting on extended buffering
//  * ...

import { Clock, Logger } from '../utils';
import { normalizeError } from './clappr-helper';
import { ga } from '../ga';

const logger = Logger.getInstance('watchdog');
const BUFFERING_DELAY = 30; // seconds after buffering starts until we present message to user

export class Watchdog {
  constructor(player, model) {
    this.player = player;
    this.model = {
      Actions: model.Actions,
      SelectedBroadcastStore: model.SelectedBroadcastStore,
    }; //avoid circular references
    this.timerBuffer = null;
    this.stoppedHACK = false; //work around issue where playback:error fires after stopping (not pausing)
    this.attach();

    document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
  }

  onVisibilityChange() {
    if (document.hidden) {
      if (this.timerBuffer) {
        logger.info('Pausing playback due to visibility change while buffering');
        this.player.pause();
        this.handleNormalOperation();
        ga('send', 'event', 'watchdog', 'pause-while-buffering');
      }
    }
  }

  handleBufferingStart() {
    if (this.timerBuffer) {
      logger.info('Buffering started (duplicate event fired)');
    } else {
      logger.info('Buffering started');
      this.timerBuffer = setTimeout(() => this.handleBufferingForTooLong(), BUFFERING_DELAY * 1000);
    }
  }

  handleBufferingForTooLong() {
    const { Actions } = this.model;
    logger.error('Buffering for too long');
    Actions.PlaybackErrorOccurred({
      error: {
        code: 'buffering_too_long',
        stuck_on_discontinuity: this.stuckOnDiscontinuity,
      },
    });
    ga('send', 'event', 'watchdog', 'buffering-too-long');
  }

  handlePlaybackError(error) {
    const { Actions } = this.model;
    if (this.stoppedHACK) {
      logger.warn(
        'An error occurred, but playback is stopped so this should not be a problem',
        error
      );
    } else if (error === null) {
      logger.warn('An error event was fired, but the error was null'); // Ugh, Firefox
    } else {
      logger.error('An error occurred', error);
      Actions.PlaybackErrorOccurred({
        error: normalizeError(error),
      });
    }
  }

  initPlaybackEvents() {
    this.checkMediaSupport();

    // Try to dig into hls.js internals a bit
    let playback = this.player.core.getCurrentPlayback();
    if (playback && playback._hls) {
      // The hls.js LEVEL_UPDATED event is what gives us the list of all .ts segments
      // mapped to their timestamps.  We can use this along with our knowledge of the
      // pattern of how we name thumbnail previews to generate them.
      playback._hls.on('hlsLevelUpdated', (evt, data) => {
        var fragments = data.details.fragments;
        var totalDiscontinuities = data.details.endCC;
        var lastValidSegmentContinuityCounter = fragments[fragments.length - 1].cc;
        if (
          playback.getPlaybackType() == 'live' &&
          totalDiscontinuities > lastValidSegmentContinuityCounter
        ) {
          this.stuckOnDiscontinuity = true;
        } else {
          this.stuckOnDiscontinuity = false;
        }
        this.fragments = fragments;
        this.endCC = totalDiscontinuities;
      });
    }
  }

  checkMediaSupport() {
    const { Actions } = this.model;
    var playback = this.player.core.getCurrentPlayback();
    var playbackType = playback && playback.getPlaybackType();
    if (playbackType == 'no_op') {
      logger.error('Media not supported; no_op playback detected for', this.player.options.source);
      Actions.PlaybackErrorOccurred({
        error: { code: 4 }, //MEDIA_ERR_SRC_NOT_SUPPORTED
      });
      ga('send', 'event', 'watchdog', 'media-not-supported', this.player.options.source);
    }
  }

  handleNormalOperation() {
    clearTimeout(this.timerBuffer);
    this.timerBuffer = null;
    this.stoppedHACK = false;
  }

  attach() {
    const { Actions, SelectedBroadcastStore } = this.model;
    let player = this.player;
    let playback = player.core.getCurrentPlayback();

    // Wire up events when problem is detected
    playback.on('playback:buffering', () => {
      this.handleBufferingStart();
    });
    playback.on('playback:error', (error) => {
      this.handlePlaybackError(error);
    });

    // Wire up events for additional feature detection
    playback.on('playback:dvr', (dvrInUse) => {
      Actions.DVRModeChange(dvrInUse);
    });

    // Wire up events to detect that all is well
    playback.on('playback:bufferfull', () => {
      this.timerBuffer && logger.info('back to normal; bufferfull');
      this.handleNormalOperation();
    });
    playback.on('playback:play', () => {
      this.timerBuffer && logger.info('back to normal; playing');
      this.handleNormalOperation();
      SelectedBroadcastStore.playing = true;
      Actions.PlaybackIsPlaying();
    });
    player.on('timeupdate', (time) => {
      // XXX: timeupdate can be thrown immediately *after* buffering starts but before the buffering state is fixed.
      //this.timerBuffer && logger.info('back to normal; timeupdate');
      //this.handleNormalOperation();
      Actions.TimeUpdate(time.current, time.total);
    });
    player.on('pause', () => {
      this.timerBuffer && logger.info('back to normal; paused');
      this.handleNormalOperation();
      SelectedBroadcastStore.playing = false;
    });
    player.on('stop', () => {
      this.timerBuffer && logger.info('back to normal; stopped');
      this.handleNormalOperation();
      SelectedBroadcastStore.playing = false;
      this.stoppedHACK = true;
    });
    player.on('ended', () => {
      this.timerBuffer && logger.info('back to normal; ended');
      this.handleNormalOperation();
      SelectedBroadcastStore.playing = false;
      Actions.CurrentPlaybackEnded();
    });

    // Wire up special case for no_op playback
    if (this.player.isReady) {
      this.initPlaybackEvents();
    } else {
      this.player.on('ready', () => this.initPlaybackEvents());
    }
  }

  detach() {
    clearTimeout(this.timerBuffer);
    this.timerBuffer = null;

    let player = this.player;
    let playback = this.player.core.getCurrentPlayback();

    playback.off('playback:buffering');
    playback.off('playback:error');
    playback.off('playback:dvr');
    player.off('play');
    player.off('timeupdate');
    player.off('pause');
    player.off('stop');
    player.off('ended');

    document.removeEventListener('visibilitychange', this.onVisibilityChange.bind(this));
  }
}
