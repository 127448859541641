import React from 'react';
import Redirects from '../../redirects';
import PropTypes from 'prop-types';

class DonationsWidget extends React.Component {
  constructor() {
    super();
    this.state = {
      isSSL: window.location.protocol == 'https:',
    };
  }

  render() {
    const { UserArgsStore } = this.context.model;
    const { env } = this.context;

    if (UserArgsStore.donateURL) {
      return this.renderRedirectButton();
    } else if (!this.state.isSSL) {
      if (env('allowNonSSLCheckout') || UserArgsStore.allowNonSSLCheckout) {
        return this.renderRealButton();
      } else {
        console.warn(
          'Current site is not SSL, will need to redirect to BoxCast to complete transaction'
        );
        return this.renderRedirectButton();
      }
    } else {
      return this.renderRealButton();
    }
  }

  redirectToDonate() {
    const { UserArgsStore } = this.context.model;

    if (UserArgsStore.donateURL) {
      window.open(UserArgsStore.donateURL);
      return false;
    } else {
      alert(
        'You will now be redirected to the secure BoxCast web site to complete the donation...'
      );
      Redirects.redirectNowToPurchase({
        ticketPurchaseUrl: this.context.env('ticketPurchaseUrl'),
        channelId: this.context.model.CurrentChannelStore.id,
        ticketId: '~donate',
      });
    }
  }

  renderRedirectButton() {
    return <span onClick={() => this.redirectToDonate()}>{this.props.children}</span>;
  }

  showModal() {
    const { Actions } = this.context.model;
    Actions.OpenPaymentModal('donate');
  }

  renderRealButton() {
    return <span onClick={() => this.showModal()}>{this.props.children}</span>;
  }
}

DonationsWidget.contextTypes = {
  model: PropTypes.object,
  env: PropTypes.func,
};

export default DonationsWidget;
