import Clappr from '@clappr/player';

export default class TapToUnmuteButton extends Clappr.UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }

  get name() {
    return 'tap_to_unmute';
  }

  get template() {
    return Clappr.template(
      `<div data-tap-to-unmute><button type="button">Tap To Unmute</button></div>`
    );
  }

  get container() {
    return this.core && this.core.activeContainer;
  }

  get playback() {
    return this.core && this.core.activePlayback;
  }

  bindEvents() {
    if (this.core.isReady) {
      this._bindPlaybackEvents();
    } else {
      this.listenTo(this.core, Clappr.Events.CORE_READY, () => this._bindPlaybackEvents());
    }
  }

  _bindPlaybackEvents() {
    this.listenTo(this.playback, 'custom:autoplay:muted', () => this._show());
    this.listenTo(this.container, Clappr.Events.CONTAINER_VOLUME, (v) => this._onVolumeUpdate(v));
  }

  _show() {
    if (this._rendered) {
      this.$button.show();
      return;
    }

    this.core.$el.append(this.template({}));
    this.$button = this.core.$el.find('[data-tap-to-unmute] button');
    this.$button.on('click', () => this._onTapToUnmute());

    this._rendered = true;
  }

  _onVolumeUpdate(volume) {
    if (volume > 0) {
      this._hide();
    }
  }

  _onTapToUnmute() {
    this.core.mediaControl.setVolume(100);
    this._hide();
  }

  _hide() {
    if (this._rendered) {
      this.$button.hide();
    }
  }
}

TapToUnmuteButton.type = 'core';
