import Clappr from '@clappr/player';
import { BrowserStorage } from '../../utils';
import './closed-captions-plugin.scss';
import ccIcon from '!svg-inline-loader!../../styles/icons/closed-captions.svg';

const CAPTIONING_ENABLED_KEY = 'bx:cc-on';

export default class ClosedCaptions extends Clappr.UICorePlugin {
  get supportedVersion() {
    return { min: '0.4.0' };
  }

  get name() {
    return 'closed_captions';
  }

  get template() {
    return Clappr.template(`<button type="button" class="cc-button media-control-button media-control-icon" data-cc-button
      aria-label="<%= ariaLabel %>"></button>`);
  }

  get events() {
    return {
      'click [data-cc-button]': 'toggleCC',
    };
  }

  get attributes() {
    return {
      class: 'cc-controls',
      'data-cc-controls': '',
    };
  }

  constructor(core) {
    super(core);
    const config = core.options.closedCaptionsConfig;
    this._initialized = false;
    this._trackId === -1;
    this._ariaLabel = config && config.ariaLabel ? config.ariaLabel : 'cc-button';
    this._labelCb =
      config && config.labelCallback && typeof config.labelCallback === 'function'
        ? config.labelCallback
        : (track) => {
            return track.name;
          };
    this.closedCaptionsConfig = config;
  }

  bindEvents() {
    this.listenTo(this.core, Clappr.Events.CORE_ACTIVE_CONTAINER_CHANGED, this.containerChanged);
    this.listenTo(this.core.mediaControl, Clappr.Events.MEDIACONTROL_RENDERED, this.render);
    this.container = this.core.activeContainer;
    if (this.container) {
      this.listenTo(
        this.container,
        Clappr.Events.CONTAINER_SUBTITLE_AVAILABLE,
        this.onSubtitleAvailable
      );
      this.listenTo(
        this.container,
        Clappr.Events.CONTAINER_SUBTITLE_CHANGED,
        this.onSubtitleChanged
      );
      this.listenTo(this.container, Clappr.Events.CONTAINER_STOP, this.onContainerStop);
    }
  }

  onContainerStop() {
    this.ccAvailable(false);
  }

  containerChanged() {
    this.ccAvailable(false);
    this.stopListening();
    this.bindEvents();
  }

  onSubtitleAvailable() {
    this.renderCcButton();
    this.ccAvailable(true);
  }

  onSubtitleChanged(track) {
    this.setCurrentTrack(track.id);

    // This initialization section is needed because this method gets called with track.id == -1
    // when the plugin is first loaded.
    if (this._initialized) {
      let enabled = track.id !== -1;
      BrowserStorage.setItem(CAPTIONING_ENABLED_KEY, enabled);
    } else {
      if (
        this.closedCaptionsConfig.defaultOn ||
        BrowserStorage.getItem(CAPTIONING_ENABLED_KEY, false)
      ) {
        this.container.closedCaptionsTrackId = 0;
      }
      this._initialized = true;
    }
  }

  ccAvailable(hasCC) {
    const method = hasCC ? 'addClass' : 'removeClass';
    this.$el[method]('available');
  }

  setCurrentTrack(trackId) {
    if (this._trackId !== trackId) {
      this._trackId = trackId;
    }

    this.updateCCButton();
  }

  renderCcButton() {
    let tracks = this.container ? this.container.closedCaptionsTracks : [];

    for (let i = 0; i < tracks.length; i++) tracks[i].label = this._labelCb(tracks[i]);

    this.$el.html(
      this.template({
        ariaLabel: this._ariaLabel,
      })
    );

    this.$ccButton = this.$el.find('button.cc-button[data-cc-button]');
    // NOTE: tests were failing here – wrapping it in a try/catch for now.
    try {
      this.$ccButton.append(ccIcon);
    } catch {}
    this.$el.append(this.style);
  }

  render() {
    this.renderCcButton();
    this.updateCCButton();

    const $fullscreen = this.core.mediaControl.$el.find('button[data-fullscreen]');
    if ($fullscreen[0]) {
      this.$el.insertAfter($fullscreen[0]);
    } else {
      this.core.mediaControl.$('.media-control-right-panel')
        .prepend(this.el);
    }

    return this;
  }

  updateCCButton() {
    if (this._trackId === -1) {
      this.$ccButton.removeClass('enabled');
    } else {
      this.$ccButton.addClass('enabled');
    }
  }

  toggleCC() {
    let tracks = this.container ? this.container.closedCaptionsTracks : [];
    if (this.container.closedCaptionsTrackId === -1 && tracks.length > 0) {
      this.container.closedCaptionsTrackId = tracks[0].id;
    } else {
      this.container.closedCaptionsTrackId = -1;
    }
  }
}
