// The UserArgsStore maintains all arguments/options as a combination of both:
//  - initial options specified on the embed widget
//  - any updates provided by the view content settings
import Reflux from 'reflux';
import { Util } from '../utils';

export var UserArgsStoreFactory = (Actions, channelId, embedArgs) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      // Channel ID is sepcial argument
      this.channelId = channelId;

      // Glue all default embed args right on here at the top level
      embedArgs = embedArgs || {};
      Object.keys(embedArgs).forEach((key) => {
        this[key] = embedArgs[key];
      });

      // Track all changes resulting from content settings view updates
      this.previousContentSettings = {};
      this.updatedContentSettingsKeys = [];

      Actions.ContentSettingsChanged(this);
    },

    // Action bindings
    onUpdateContentSettings: function (newCS) {
      if (Util.areAnyChanged(this.previousContentSettings, newCS)) {
        // Remove prior content settings updates to avoid single-broadcast settings from sticking
        // around when switching through multiple broadcasts in a channel playlist
        this.updatedContentSettingsKeys.forEach((key) => {
          delete this[key];
        });
        this.updatedContentSettingsKeys = [];

        // Now, add all the changed fields in
        Object.keys(newCS).forEach((key) => {
          this[key] = newCS[key];
          this.updatedContentSettingsKeys.push(key);
        });

        // Make sure that the originally passed-in arguments from the embed settings continue to
        // take highest priority
        Object.keys(embedArgs).forEach((key) => {
          this[key] = embedArgs[key];
        });
        this.trigger();
        Actions.ContentSettingsChanged(this);
      }
      this.previousContentSettings = newCS;
    },
  });
