// Wrapper for Google Analytics
// It's important that it does not conflict with any GA use on the host
// page when this is embedded, so we use our own custom tracker.
import Config from './config';

const TRACKER_NAME = Config.get('gaTrackerName');
const TRACKER_CODE = Config.get('gaTrackerCode');
const VERSION = Config.get('version');

var ga;
if (process.env.NODE_ENV == 'production') {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', TRACKER_NAME);
  //})(window,document,'script','https://www.google-analytics.com/analytics_debug.js',TRACKER_NAME);

  ga = (...args) => window[TRACKER_NAME](...args);
  //window.ga_debug = {trace: true};
  ga('create', TRACKER_CODE, 'auto');
  ga('require', 'displayfeatures');
  ga('set', 'hostname', location.hostname);
  ga('set', 'dimension1', VERSION);
  ga('send', 'pageview');
} else {
  ga = () => console.log('[ga] swallowing event');
}

export function gaEvent(tag, args, category = 'load') {
  ga('send', 'event', tag, category, JSON.stringify(args));
}

export function gaSafely(tag, args, fn) {
  try {
    var result = fn();
    gaEvent(tag, args);
    return result;
  } catch (err) {
    if (err.message != 'halt') {
      var error = `${err.message} ${err.filename}:${err.lineNumber}`;
      console.error(error);
      ga('send', 'event', tag, 'fatal', error);
      ga('send', 'exception', { exFatal: true, exDescription: error });
    }
    throw err;
  }
}

export function gaServerError(obj, resp, options) {
  resp = resp || {};
  obj = obj || {};
  var error = `${resp.status} ${resp.responseText}`;
  var label = `${JSON.stringify([obj.url(), options.data])} -> ${error}`;
  var action = 'error';
  if ([401, 402, 403].indexOf(resp.status) >= 0) {
    action = 'warn';
  }
  ga('send', 'event', 'server', action, label, { nonInteraction: 1 });
}

export { ga };
