import Clappr from '@clappr/player';
import React from 'react';
import ReactDOM from 'react-dom';
import Reflux from 'reflux';

import API from './api';
import Context from './context';
import Renderer from './renderer';
import * as utils from './utils';
import './polyfill';

let oldWindowBoxcast;

// The `boxcast` function creates a context around the selected page element.
function boxcast(cssSelector) {
  return new Context(cssSelector, boxcast);
}

boxcast.version = VERSION;

// We also glue additional shortcut methods onto the top-level boxcast object.
// NOTE: Object.assign is not available in IE10 so we do this manually.

// a renderer object can help render simple UI components in the BoxCast style
// outside of the context of an entire BoxOffice via loadChannel
boxcast.renderer = new Renderer();

// we also expose our helper utilities and libraries to use externally
boxcast.utils = utils;
boxcast.libs = {
  React: React,
  ReactDOM: ReactDOM,
  Clappr: Clappr,
  Reflux: Reflux,
};

// getBroadcast returns an API promise for the specified broadcast.
boxcast.getBroadcast = (id) => {
  return API.get(`/broadcasts/${id}`);
};

// getChannelBroadcasts returns an API promise for broadcasts from the
// specified channel.
boxcast.getChannelBroadcasts = (id, args) => {
  return API.get(`/channels/${id}/broadcasts`, args);
};

// getBroadcastHighlights returns an API promise for highlights from the
// specified broadcast.
boxcast.getBroadcastHighlights = (id, args) => {
  return API.get(`/broadcasts/${id}/highlights`, args);
};

// getChannelHighlights returns an API promise for highlights from the
// specified channel.
boxcast.getChannelHighlights = (id, args) => {
  return API.get(`/channels/${id}/highlights`, args);
};

// Relinquish control of global `boxcast` object
boxcast.noConflict = () => {
  window['boxcast'] = oldWindowBoxcast;
  return boxcast;
};

(function (self) {
  // Finally, we expose `boxcast` to the window scope
  oldWindowBoxcast = self.boxcast;
  self.boxcast = boxcast;
})(window);

export default boxcast;
