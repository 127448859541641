import platform from 'platform';

export function isLikelyToRequireFlashForHLS() {
  // Heuristic based on browser to help understand what we're likely going
  // to need to play back any HLS stream.
  return platform.name == 'IE';
}

export function usesNativeHLSPlayer() {
  // If a platform doesn't support MediaSourceExtensions, we can't use hls.js
  // and will default to a native HLS player.
  if (typeof MediaSource === 'undefined' || typeof SourceBuffer === 'undefined') {
    return true;
  } else {
    return false;
  }
}

export function canProbablyPlayHLSByteranges() {
  if (isLikelyToRequireFlashForHLS()) {
    return false;
  } else if (usesNativeHLSPlayer()) {
    // Apple's player specifically has issues with our byteranges (aggressive level checkdowns).
    return false;
  } else {
    return true;
  }
}
