import { ActionsFactory } from './actions';
import { CurrentChannelStoreFactory } from './current-channel-store';
import { FreePreviewStoreFactory } from './free-preview-store';
import { PlaybackStoreFactory } from './playback-store';
import { PlaylistStoreFactory } from './playlist-store';
import { SelectedBroadcastStoreFactory } from './selected-broadcast-store';
import { TicketStoreFactory } from './ticket-store';
import { UserArgsStoreFactory } from './user-args-store';
import { HighlightListStoreFactory } from './highlight-list-store';
import { SocialStoreFactory } from './social-store';
import { GeoBlockStoreFactory } from './geoblock-store';
import { PasswordBlockStoreFactory } from './passwordblock-store';
import { SlotStore } from './slot-store';

// Use the factory pattern so that we can support multiple embedded
// widgets per page. Each Reflux Action/Store is singleton by nature.
export var ModelFactory = (channelId, args, contextType) => {
  let m = {};
  m.contextType = contextType;
  m.Actions = ActionsFactory();
  m.UserArgsStore = UserArgsStoreFactory(m.Actions, channelId, args);
  m.CurrentChannelStore = CurrentChannelStoreFactory(m.Actions);
  m.PlaylistStore = PlaylistStoreFactory(m.Actions, m.UserArgsStore, m.CurrentChannelStore);
  m.TicketStore = TicketStoreFactory(m.Actions, m.CurrentChannelStore, m.UserArgsStore);
  m.SelectedBroadcastStore = SelectedBroadcastStoreFactory(
    m.contextType,
    m.Actions,
    m.CurrentChannelStore,
    m.TicketStore,
    m.UserArgsStore
  );
  m.HighlightListStore = HighlightListStoreFactory(
    m.Actions,
    m.SelectedBroadcastStore,
    m.UserArgsStore
  );
  m.PlaybackStore = PlaybackStoreFactory(m.Actions, m.SelectedBroadcastStore);
  m.FreePreviewStore = FreePreviewStoreFactory(
    m.Actions,
    m.SelectedBroadcastStore,
    m.UserArgsStore
  );
  m.SocialStore = SocialStoreFactory(m.Actions, m.UserArgsStore);
  m.GeoBlockStore = GeoBlockStoreFactory(m.Actions, m.UserArgsStore, m.CurrentChannelStore);
  m.PasswordBlockStore = PasswordBlockStoreFactory(
    m.Actions,
    m.UserArgsStore,
    m.CurrentChannelStore
  );
  m.SlotStore = SlotStore(m.Actions, m.UserArgsStore);
  return m;
};
