// Support for the redirect workflow needed by the following business rules:
//
//  * Host restrictions
//  * Non-SSL ticket purchases

export default class Redirects {
  static applyRedirectRulesOnLoad(channelId, args) {
    // Some sites utilize pieces of the window location hash for their own application
    // which might interfere with our own redirect rules. You can disable it if you're
    // certain you don't depend on it e.g. host restrictions
    if (args.disableRedirectRulesOnLoad) {
      return { channelId, args };
    }

    if (Redirects.needsToPurchaseTicketNow(args)) {
      args.ticketId = null;
      args.autoOpenTicketWindow = 'ticket';
    } else if (Redirects.needsToDonateNow(args)) {
      args.ticketId = null;
      args.autoOpenTicketWindow = 'donate';
    }

    if (Redirects.channelFromRedirect()) {
      channelId = Redirects.channelFromRedirect();
    }

    if (Redirects.ticketFromRedirect()) {
      args.ticketId = Redirects.ticketFromRedirect();
    }

    return { channelId, args };
  }

  // Force a redirect to boxcast.tv for non-SSL credit card transactions
  static redirectNowToPurchase(args) {
    const url = `${args.ticketPurchaseUrl}/${args.channelId}/${
      args.ticketId
    }?referrer=${encodeURIComponent(window.location.href)}`;
    window.location.href = url;
    throw 'halt';
  }

  // If we've been redirected with "~pay" pseudo-ticket (intention to purchase after leaving
  // non-ssl hosting site), make sure to automatically kick off the purchase flow
  static needsToPurchaseTicketNow(args) {
    return args.ticketId == '~pay';
  }
  static needsToDonateNow(args) {
    return args.ticketId == '~donate';
  }

  // If we've been redirected back here due to host restrictions, make sure to load the proper
  // broadcast (i.e. switch from playlist to single-event mode)
  static channelFromRedirect() {
    var channelViaRedirect = window.location.hash.match(/#\/([\w-]+)/);
    if (channelViaRedirect) {
      return channelViaRedirect[1];
    } else {
      return null;
    }
  }

  // If we've been redirected back here with a provided ticket, make sure to use it
  // (i.e. host restrictions + ticketed + non-ssl hosting site)
  static ticketFromRedirect() {
    var ticketViaRedirect = window.location.hash.match(/#\/[\w-]+\/\~tkt\~([\w-]+)/);
    if (ticketViaRedirect) {
      return ticketViaRedirect[1];
    } else {
      return null;
    }
  }
}
