import React from 'react';
import { Logger } from '../../utils';

const logger = Logger.getInstance('player');

export default class SettingsMenu extends React.Component {
  componentDidMount() {
    // on first mounting of this component, it's come into view so we should focus on it for
    // improved keyboard accessibility
    var btn = this.refs.qualityButton || this.refs.rateButton || this.refs.ccButton;
    if (btn) {
      btn.focus();
    }
  }

  render() {
    const { showQualitySelector, showRateSelector } = this.props;
    return (
      <ul className="boxcast-clappr-menu">
        <li>
          <span className="boxcast-clappr-menu-title">Playback Settings</span>
        </li>
        {showQualitySelector ? this.renderQualityRow() : ''}
        {showRateSelector ? this.renderRateRow() : ''}
      </ul>
    );
  }

  renderQualityRow() {
    const { currentLevel, levels, nextLevelIndex } = this.props;
    return (
      <li className="link">
        <button
          type="button"
          ref="qualityButton"
          aria-label="Show Quality Level Options"
          className="boxcast-unstyled-button"
          onClick={() => this.props.onChangeMenu('quality')}
        >
          <span className="boxcast-clappr-menu-label">Quality</span>
          <span className="boxcast-clappr-menu-value">
            {getLevelText(levels, currentLevel, nextLevelIndex)}
          </span>
          <span className="boxcast-clappr-menu-icon-right">
            <i className="boxcast-icon-chevron-right"></i>
          </span>
        </button>
      </li>
    );
  }

  renderRateRow() {
    const { currentRate, rates } = this.props;
    return (
      <li className="link">
        <button
          type="button"
          ref="rateButton"
          aria-label="Show Playback Speed Options"
          className="boxcast-unstyled-button"
          onClick={() => this.props.onChangeMenu('speed')}
        >
          <span className="boxcast-clappr-menu-label">Speed</span>
          <span className="boxcast-clappr-menu-value">{getRateText(rates, currentRate)}</span>
          <span className="boxcast-clappr-menu-icon-right">
            <i className="boxcast-icon-chevron-right"></i>
          </span>
        </button>
      </li>
    );
  }
}

function getLevelText(levels, currentLevel, nextLevelIndex) {
  if (currentLevel == -1) {
    let levelText = 'Auto';
    levels.forEach((l) => {
      if (l.id == nextLevelIndex && l.label != 'Auto') {
        levelText = `Auto (${l.label})`;
      }
    });
    return levelText;
  } else {
    let levelText = 'Auto';
    levels.forEach((l) => {
      if (l.id == currentLevel) {
        levelText = l.label;
      }
    });
    return levelText;
  }
}

function getRateText(rates, currentRate) {
  let rateText = '1x';
  rates.forEach((r) => {
    if (r.id == currentRate) {
      rateText = r.label;
    }
  });
  return rateText;
}
