import React from 'react';

export default function PaymentComplete(props) {
  const { description, cta, onDismiss } = props;
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="boxcast-payment-title" style={{ margin: '60px 0 30px' }}>
        Payment Complete
      </div>
      <div className="boxcast-payment-details">{description}</div>
      <div className="boxcast-form-field" style={{ marginTop: '60px' }}>
        <button className="boxcast-ticket-button-solid" type="button" onClick={onDismiss}>
          {cta}
        </button>
      </div>
    </div>
  );
}
