import Config from '../config';
import Promise from 'bluebird';
import { dom, fetch } from '../utils';

const HTML5_TIMEOUT = 15000; //if use hasn't responded in 15 seconds to HTML5 prompt, kill it

class GeoBlock {
  constructor() {
    this._lastFound = {
      lat: null,
      lon: null,
      zip: null,
      country: null,
      region: null,
    };
    this._options = {};
  }

  initGA(gaEvent) {
    // XXX: avoid circular dep by having it passed in from outside
    this.gaEvent = gaEvent;
  }

  // Run a geo-blocker
  isCurrentUserAllowed(options) {
    // options is a dictionary:
    //  - is_whitelist (bool)
    //  - center_lat
    //  - center_lon
    //  - radius
    // other config options can be passed in
    //  - strategy (string), one of "html5", "ip", or "both" (default)
    this._options = options;
    if (this.zipBased) {
      return this.getCurrentPosition().then((obj) => {
        if (!obj.zip) {
          this.gaEvent('zip:denied', '', 'geoblock');
          return false;
        }
        let zips = zipListFromString(this.zipBased);
        if (options.is_whitelist && intersect(zips, ['' + obj.zip])) {
          this.gaEvent('zip:allowed', '', 'geoblock');
          return true;
        } else if (!options.is_whitelist && !intersect(zips, ['' + obj.zip])) {
          this.gaEvent('zip:allowed', '', 'geoblock');
          return true;
        } else {
          this.gaEvent('zip:denied', '', 'geoblock');
          return false;
        }
      });
    } else {
      return this.currentDistanceFrom(options.center_lat, options.center_lon).then((dist) => {
        if (options.is_whitelist && dist <= options.radius) {
          this.gaEvent('latlon:allowed', '', 'geoblock');
          return true;
        } else if (!options.is_whitelist && dist >= options.radius) {
          this.gaEvent('latlon:allowed', '', 'geoblock');
          return true;
        } else {
          this.gaEvent('latlon:denied', '', 'geoblock');
          return false;
        }
      });
    }
  }

  get lastFound() {
    const r = {};
    for (let p in this._lastFound) r[p] = this._lastFound[p];
    return r;
  }

  get zipBased() {
    // Bug in boxcast web dashboard had attribute stored as "zip" instead of "zips"
    return this._options.zips || this._options.zip;
  }

  currentDistanceFrom(lat, lon) {
    return this.getCurrentPosition().then((obj) =>
      getDistanceFromLatLonInMi(obj.lat, obj.lon, lat, lon)
    );
  }

  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      if (this._lastFound.lat && this._lastFound.lon) {
        return resolve(this._lastFound);
      }

      if (this._options.strategy == 'ip') {
        // IP address only lookup strategy
        this.tryFreeIpLookup()
          .then((obj) => {
            this._lastFound = obj;
            resolve(obj);
          })
          .catch((err) => reject(err));
      } else if (this._options.strategy == 'html5') {
        // HTML5 preferred lookup strategy (do HTML5 first then fall back to IP)
        this.tryHTML5Lookup()
          .then((obj) => {
            this._lastFound = obj;
            resolve(obj);
          })
          .catch((err) => {
            this.tryFreeIpLookup()
              .then((obj) => {
                this._lastFound = obj;
                resolve(obj);
              })
              .catch((err) => reject(err));
          });
      } else {
        // Default strategy is to look-up IP first then fall back to HTML5
        this.tryFreeIpLookup()
          .then((obj) => {
            this._lastFound = obj;
            resolve(obj);
          })
          .catch((err) => {
            this.tryHTML5Lookup()
              .then((obj) => {
                this._lastFound = obj;
                resolve(obj);
              })
              .catch((err) => reject(err));
          });
      }
    });
  }

  tryFreeIpLookup() {
    return fetchWithTimeout(Config.get('geoServiceUrl'), 3000)
      .then((res) => res.json())
      .then((obj) => ({
        lat: obj.latitude,
        lon: obj.longitude,
        zip: obj.zip_code,
        country: obj.country_code,
        region: obj.region_code,
      }));
  }

  tryHTML5Lookup() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation || !navigator.geolocation.getCurrentPosition) {
        return reject('navigator.geolocation not supported');
      }
      setTimeout(() => {
        this.gaEvent('error:html5:timeout', '', 'geoblock');
        reject('timed out waiting for geolocation');
      }, HTML5_TIMEOUT);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.ensureGMapsLoaded()
            .then(() => this.metaFromGoogleMaps(position))
            .then((meta) =>
              resolve({
                lat: position.coords.latitude,
                lon: position.coords.longitude,
                zip: meta.zip,
                country: meta.country,
                region: meta.region,
              })
            )
            .catch((error) => {
              this.gaEvent('error:html5:gmapmeta', error, 'geoblock');
              reject(`unable to compute geo meta for lat/lon: ${error}`);
            });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  metaFromGoogleMaps(position) {
    return new Promise((resolve, reject) => {
      try {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        geocoder.geocode({ latLng: latlng }, (results, status) => {
          if (status != google.maps.GeocoderStatus.OK) {
            return reject(status);
          }
          const ret = {};
          let mostSpecificResult = results[0];
          for (let i = 0; i < mostSpecificResult.address_components.length; i++) {
            const c = mostSpecificResult.address_components[i];
            const t = c.types;
            if (t.indexOf('postal_code') > -1) {
              ret.zip = c.short_name;
            } else if (t.indexOf('country') > -1) {
              ret.country = c.short_name;
            } else if (
              t.indexOf('administrative_area_level_1') > -1 &&
              t.indexOf('political') > -1
            ) {
              ret.region = c.short_name;
            }
          }
          if (ret.zip || ret.country || ret.region) {
            return resolve(ret);
          }
          reject('Unable to find location meta');
        });
      } catch (err) {
        return reject(err.message);
      }
    });
  }

  ensureGMapsLoaded() {
    if (window.google && window.google.maps && window.google.maps.Geocoder) {
      return new Promise((resolve, _) => resolve());
    } else {
      return dom.loadScript(
        `//maps.googleapis.com/maps/api/js?key=${Config.get('googleMapsAPIKey')}`
      );
    }
  }
}

function fetchWithTimeout(input, timeout, opts) {
  return new Promise((resolve, reject) => {
    setTimeout(reject, timeout);
    fetch(input, opts).then(resolve, reject);
  });
}

function getDistanceFromLatLonInMi(lat1, lon1, lat2, lon2) {
  // implementation of Haversine formula
  const R = 3961; // Radius of the earth in miles optimized for 39deg around equator (~Wash DC)
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function intersect(array1, array2) {
  return array1.filter(function (n) {
    return array2.indexOf(n) !== -1;
  }).length;
}

export function zipListFromString(zips) {
  // split on any whitespace, newlines, or commas
  return zips
    .split(/\s*[\s,]\s*/)
    .map((s) => s.trim())
    .filter((x) => !!x);
}

export function polygonListFromString(str) {
  // split on newlines first then on commas, e.g.:
  //    -73.8963272,40.85153204
  //    -73.8964878,40.85124765
  //    -73.8968799,40.85137592
  //    -73.8967188,40.85166015
  //    -73.8963272,40.85153204
  const lines = str
    .match(/[^\r\n]+/g)
    .map((l) => l.trim())
    .filter((l) => l);
  return lines.map((line) =>
    line
      .split(/\s*[\s,]\s*/)
      .map((s) => parseFloat(s.trim(), 10))
      .filter((x) => !isNaN(x))
  );
}

export function insidePolygon(point, vectors) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point[0],
    y = point[1];

  let inside = false;
  for (let i = 0, j = vectors.length - 1; i < vectors.length; j = i++) {
    const xi = vectors[i][0],
      yi = vectors[i][1];
    const xj = vectors[j][0],
      yj = vectors[j][1];

    const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export default new GeoBlock();
