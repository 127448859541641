import React from 'react';
import Reflux from 'reflux';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';

import BuyTicketButton from '../monetization/buy-ticket-button.jsx';
import NonBreakingSpace from '../library/nbsp.jsx';

export default createClass({
  displayName: 'TicketedSecondsRemaining',

  propTypes: {
    broadcast: PropTypes.object.isRequired,
  },

  contextTypes: {
    model: PropTypes.object,
    m: PropTypes.func,
  },

  refluxModelListeners: {
    FreePreviewStore: 'onStoreChanged',
  },

  mixins: [RefluxModelListenerMixin],

  getInitialState: function () {
    return { secondsRemaining: 0 };
  },

  onStoreChanged: function () {
    const { FreePreviewStore } = this.context.model;
    this.setState({
      secondsRemaining: FreePreviewStore.getTimeRemaining(this.props.broadcast),
    });
  },

  render: function () {
    const { SelectedBroadcastStore } = this.context.model;
    const { m } = this.context;
    var flex = SelectedBroadcastStore.isFlex();
    return (
      <div>
        {this.state.secondsRemaining} Seconds Remaining.
        <NonBreakingSpace />
        <BuyTicketButton>
          <a>{m(flex ? 'payment_ticker_flex_subtitle' : 'payment_ticker_subtitle')}</a>
        </BuyTicketButton>
        .
      </div>
    );
  },

  componentDidMount: function () {
    const { Actions } = this.context.model;
    Actions.PreviewVideoTimerTick(this.props.broadcast, 0);
    this.interval = setInterval(() => {
      Actions.PreviewVideoTimerTick(this.props.broadcast, 1);
    }, 1000);
  },

  componentWillUnmount: function () {
    clearInterval(this.interval);
  },
});
