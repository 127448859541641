// DOM helper utilities
import Promise from 'bluebird';

export function loadScript(src) {
  // Wrap dynamic script loading into Promise API
  return new Promise(function (resolve, reject) {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.addEventListener(
      'load',
      function () {
        resolve(script);
      },
      false
    );
    script.addEventListener(
      'error',
      function () {
        reject(script);
      },
      false
    );
    head.appendChild(script);
  });
}

export function animateIntoView(element, duration) {
  if (!element || elementInViewport(element)) {
    // No need to scroll; element is already visible;
    return;
  }

  var scrollEl = document.documentElement; //prefer scrolling against documentElement but allow fallback to body
  if (!scrollEl.scrollTop && document.body.scrollTop) {
    scrollEl = document.body;
  }

  var started = Date.now();
  var ends = started + duration;
  var y = Math.min(
    scrollEl.scrollTop + element.getBoundingClientRect().top,
    scrollEl.scrollHeight - window.innerHeight
  );

  function tick() {
    var distanceLeft, dt, duration, t, travel;
    t = Date.now();
    if (t < ends) {
      dt = t - started;
      duration = ends - started;
      distanceLeft = y - scrollEl.scrollTop;
      travel = distanceLeft * (dt / duration);
      scrollEl.scrollTop += travel;
      window.requestAnimationFrame(tick);
    } else {
      scrollEl.scrollTop = y;
    }
  }

  window.requestAnimationFrame(tick);
}

export function elementInViewport(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  );
}
