import Reflux from 'reflux';

import API from '../api';
import Config from '../config';
import { BrowserStorage, Logger } from '../utils';
import { ga } from '../ga';

const PURCHASED_KEY = 'bx:tkt';
const logger = Logger.getInstance('components');

export var TicketStoreFactory = (Actions, CurrentChannelStore, UserArgsStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.loading = false;
      this.modalOpen = false;
      this.modalType = 'ticket';
      this.stripeLoadError = false;
      this.stripe = null;
      this.stripeCard = null;
      this.countries = [];

      if (UserArgsStore.autoOpenTicketWindow) {
        // Want to actually fire this rather than just changing initial state so listeners can react
        Actions.OpenPaymentModal(UserArgsStore.autoOpenTicketWindow);
      }
    },

    // Public methods
    getTicket: function (broadcast) {
      // If user has purchased a ticket for the specific broadcast, that takes priority.
      //
      // Try to look it up by:
      //  1. Checking if API returned a ticket (for multi-site player when given an OAuth token)
      //  2. Checking storage for broadcast.id (numeric)
      //  3. Checking storage for broadcast.channel_id (alphanumeric)
      //  4. Checking storage for channel.channel_id (alphanumeric)
      //
      // XXX: some logic duplicated in CurrentChannelStore to avoid circular dependency.
      var ticket = broadcast.admin_token;
      if (!ticket) {
        ticket = BrowserStorage.getFromRingBuffer(PURCHASED_KEY, '' + broadcast.id);
      }
      if (!ticket && broadcast.channel_id) {
        ticket = BrowserStorage.getFromRingBuffer(PURCHASED_KEY, '' + broadcast.channel_id);
      }
      return ticket || CurrentChannelStore.ticket;
    },

    clearTicket: function (broadcast) {
      if (BrowserStorage.getFromRingBuffer(PURCHASED_KEY, '' + broadcast.id)) {
        BrowserStorage.addToRingBuffer(
          PURCHASED_KEY,
          '' + broadcast.id,
          null,
          Config.dict.purchasedBufferSize
        );
      } else if (BrowserStorage.getFromRingBuffer(PURCHASED_KEY, '' + broadcast.channel_id)) {
        BrowserStorage.addToRingBuffer(
          PURCHASED_KEY,
          '' + broadcast.channel_id,
          null,
          Config.dict.purchasedBufferSize
        );
      }
    },

    // Custom action handlers
    onLoadStripeJs: function (stripeKey) {
      if (typeof window.Stripe == 'undefined' && !this.loadedStripeJs) {
        var script = document.createElement('script');
        script.setAttribute('src', 'https://js.stripe.com/v3/');
        script.onload = () => {
          this._attachStripe(stripeKey);
        };
        script.onerror = (err) => {
          logger.error('Unable to load Stripe JavaScript', err);
          this.loadedStripeJs = false;
          this.stripeLoadError = true;
        };
        document.head.appendChild(script);
        this.loadedStripeJs = true;
      } else {
        this._attachStripe(stripeKey);
      }
    },

    _attachStripe: function (stripeKey) {
      this.stripe = Stripe(stripeKey);
      const elements = this.stripe.elements();
      this.stripeCard = elements.create('card', Config.get('stripeElementsOptions'));
      this.stripeLoadError = false;
      this.trigger();
      this.loadedStripeJs = true;
    },

    onOpenPaymentModal: function (modalType = 'ticket') {
      this.modalOpen = true;
      this.modalType = modalType;
      this.trigger();
    },

    onClosePaymentModal: function () {
      this.modalOpen = false;
      this.trigger();
    },

    onStripeTokenAcquired: function (broadcast, channelTicket, paymentDetails) {
      logger.log('TicketStore#onStripeTokenAcquired', broadcast, paymentDetails);
      let args = {
        email: paymentDetails.email,
        name: paymentDetails.name,
        stripe_token: paymentDetails.id,
        postal_code: paymentDetails.postal_code,
        country: paymentDetails.country,
      };
      if (paymentDetails.amount) {
        args.amount = paymentDetails.amount;
      }
      if (paymentDetails.address) {
        args.address = paymentDetails.address;
      }
      if (paymentDetails.city) {
        args.city = paymentDetails.city;
      }
      if (paymentDetails.state) {
        args.state = paymentDetails.state;
      }
      if (paymentDetails.zip) {
        args.zip = paymentDetails.zip;
      }

      this.loading = true;
      this.trigger();

      if (this.modalType == 'ticket') {
        this._applyStripeTokenAsTicketPurchase(broadcast, channelTicket, paymentDetails, args);
      } else if (this.modalType == 'donate') {
        this._applyStripeTokenAsDonation(broadcast, channelTicket, paymentDetails, args);
      } else {
        logger.error('Invalid type specified:', this.modalType);
      }
    },

    _applyStripeTokenAsDonation: function (broadcast, channelTicket, paymentDetails, args) {
      ga('send', 'event', 'creditCard.donate', 'ok', { nonInteraction: 1 });
      let promise = API.post(`/accounts/${broadcast.account_id}/donations`, args);
      return promise
        .then((result) => {
          logger.log('TicketStore - got donation from api', result);
          if (result.error) {
            throw new Error(result.error);
          }
          ga('send', 'event', 'creditCard.donate', 'complete', {
            nonInteraction: 1,
          });
          Actions.DonationCompleted();
          this.loading = false;
          this.trigger();
        })
        .catch((error) => {
          // TODO: add better advice for how to proceed. Contact support? Try again?
          logger.error('TicketStore - error redeeming token for donation', paymentDetails, error);
          ga('send', 'event', 'creditCard.donate', 'error', JSON.stringify(error), {
            nonInteraction: 1,
          });
          setTimeout(() => alert('There was an error completing the donation.'));
          this.loading = false;
          this.trigger();
        });
    },

    _applyStripeTokenAsTicketPurchase: function (broadcast, channelTicket, paymentDetails, args) {
      ga('send', 'event', 'creditCard.pay', 'ok', { nonInteraction: 1 });
      // Apply ticket to broadcast vs channel
      let promise;
      if (channelTicket) {
        promise = API.post(`/channels/${CurrentChannelStore.id}/tickets`, args);
      } else {
        promise = API.post(`/broadcasts/${broadcast.id}/tickets`, args);
      }
      return promise
        .then((result) => {
          if (result.error) {
            throw result.error_description || result.error;
          }
          logger.log('TicketStore - got ticket from api', result);
          if (result.error) {
            throw new Error(result.error);
          }
          Actions.TicketPurchaseCompleted(broadcast, channelTicket, result.id);
          ga('send', 'event', 'creditCard.pay', 'complete', {
            nonInteraction: 1,
          });
        })
        .catch((error) => {
          logger.error('TicketStore - error redeeming token for ticket', paymentDetails, error);
          setTimeout(() => alert('There was an error completing the ticket purchase: ' + error));
          this.loading = false;
          this.trigger();
          ga('send', 'event', 'creditCard.pay', 'error', JSON.stringify(error), {
            nonInteraction: 1,
          });
        });
    },

    onTicketPurchaseCompleted: function (broadcast, channelTicket, ticket) {
      // Did user just purchase a channel ticket or a broadcast ticket?
      let ticketKey;
      if (channelTicket) {
        ticketKey = CurrentChannelStore.id;
        CurrentChannelStore.ticket = ticket;
      } else {
        ticketKey = broadcast.id;
      }
      BrowserStorage.addToRingBuffer(
        PURCHASED_KEY,
        '' + ticketKey,
        ticket,
        Config.dict.purchasedBufferSize
      );
      this.loading = false;
      this.trigger();
      if (CurrentChannelStore.callbacks.onTicketPurchaseCompleted) {
        // Call this outside our stack to avoid client exceptions bubbling into here
        setTimeout(
          () =>
            CurrentChannelStore.callbacks.onTicketPurchaseCompleted(
              broadcast,
              channelTicket,
              ticket
            ),
          0
        );
      }
    },

    onLoadCountries: function () {
      API.get(`/checkout/countries`).then((response) => {
        this.countries = response;
      });
    },

    onPreviewTicket: function (broadcast, channelTicket, postalCode) {
      const params = {
        postal_code: postalCode,
      };
      if (channelTicket) {
        params.channel_id = CurrentChannelStore.id;
      } else {
        params.broadcast_id = broadcast.id;
      }
      let promise;
      if (channelTicket) {
        promise = API.post(`/channels/${CurrentChannelStore.id}/tickets/preview`, params);
      } else {
        promise = API.post(`/broadcasts/${broadcast.id}/tickets/preview`, params);
      }
      promise
        .then((result) => {
          Actions.PreviewTicketCompleted(result.tax_amount || 0);
        })
        .catch((error) => {
          logger.error('Error getting tax amount', error);
          Actions.PreviewTicketCompleted(0);
        });
    },
  });
