// Turn a plaintext grammar into something the BoxCast API can understand
// without throwing errors.
// See inspiration from boxcast_web_dashboard/src/app/utils/search-query-builder

export function textToQuery(phrase) {
  return splitQuoted(stripReservedCharacters(phrase)).join(' ').trim();
}

function stripReservedCharacters(phrase) {
  // https://github.com/boxcast/boxcast_js/issues/104
  var specialChars = `[]<>\/()+"`;
  for (var i = 0; i < specialChars.length; i++) {
    phrase = phrase.replace(new RegExp('\\' + specialChars[i], 'gi'), '');
  }
  return phrase;
}

function splitSimple(text) {
  return text.split(' ');
}

function splitQuoted(text) {
  let result = [];
  let words = splitSimple(text);
  let phrase = null;
  words.forEach((word) => {
    word = word.trim();
    if (!phrase && word.startsWith('"')) {
      // start of phrase
      if (word.endsWith('"')) {
        // single-word phrase
        phrase = '+' + word;
        result.push(phrase);
        phrase = null;
      } else {
        // multi-word phrase (need to keep looping)
        phrase = '+' + word;
      }
    } else if (phrase && word.endsWith('"')) {
      // end of current phrase
      phrase += ' ' + word;
      result.push(phrase);
      phrase = null;
    } else if (phrase) {
      // keep building...
      phrase += ' ' + word;
    } else if (word) {
      // just a plain word
      word = `+*${word}*`;
      result.push(word);
    }
  });

  if (phrase) {
    // fix unbalanced quote
    phrase += '"';
    result.push(phrase);
  }

  return result;
}
