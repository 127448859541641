// The PlaylistStore persists the list of related broadcasts and helps
// paginate/search through them.
import Reflux from 'reflux';
import { ga } from '../ga';
import Config from '../config';
import API from '../api';
import { Logger, Clock } from '../utils';
import { textToQuery } from '../utils/search-query';
import { timeframe } from '../utils/broadcast';

const logger = Logger.getInstance('models');

export var PlaylistStoreFactory = (Actions, UserArgsStore, CurrentChannelStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      this.broadcasts = [];
      this.pagination = {};
      this.filter = '';
      this.currentPage = 1;
      this.loading = true;
      this.error = null;

      this.pageSize = Config.dict.playlistPageSize;
      if (UserArgsStore.relatedBroadcastsQuery && UserArgsStore.relatedBroadcastsQuery.l) {
        this.pageSize = UserArgsStore.relatedBroadcastsQuery.l;
      }
    },

    // Public helper methods
    pageCount: function () {
      if (!this.pagination) return 0;
      return Math.ceil(this.pagination.total / this.pageSize);
    },

    // Reflux action bindings
    onLoadChannel: function (id, args) {
      logger.log('PlaylistStore#onLoadChannel', id, args);
      // Reset state of store
      this.init();

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      // Hit API
      return this._getRelatedBroadcastsPromise()
        .then((data) => {
          if (!isThisRequestStillValid()) return null;
          if (data.broadcasts && data.broadcasts.error) {
            this.error = data.broadcasts;
            this.broadcasts = [];
            this.pagination = {};
          } else {
            // TODO: decide if there's a new broadcast in the playlist and provide
            // some action so UI can respond and notify user
            this.error = null;
            this.broadcasts = data.broadcasts;
            this.pagination = data.pagination;
          }
        })
        .then(() => {
          if (!isThisRequestStillValid()) return null;
          if (args.selectedBroadcastId) {
            // Support selecting the current broadcast by a specific ID
            var promise = API.get(`/broadcasts/${args.selectedBroadcastId}`);
            return promise.then((broadcast) => {
              Actions.SelectBroadcast(broadcast, {
                selectedHighlightId: args.selectedHighlightId,
              });
            });
          } else if (this.broadcasts && this.broadcasts.length) {
            var selectedBroadcast = getSelectedBroadcast(this.broadcasts, args);
            Actions.SelectBroadcast(selectedBroadcast, {});
          } else {
            this.error = {
              error: 'not_found',
              error_description: `There are no broadcasts in this channel.`,
            };
            Actions.LoadChannelError(this.error);
          }
        })
        .then(() => {
          if (!isThisRequestStillValid()) return null;
          this.loading = false;
          this.trigger();
        })
        .catch((error) => {
          logger.error('PlaylistStore error loading broadcasts', error);
          this.error = this.error || {
            error: 'unhandled_exception',
            error_description: 'Unhandled exception loading broadcasts.',
          };
          this.loading = false;
          this.trigger();
        });
    },
    onRefreshSelectedBroadcastMeta: function () {
      // Might as well check for an updated playlist as well!
      if (!UserArgsStore.showRelated) {
        return;
      }

      // Work around inability to cancel promises
      var requestId = Math.random();
      this.requestId = requestId;
      var isThisRequestStillValid = () => this.requestId == requestId;

      return this._getRelatedBroadcastsPromise()
        .then((data) => {
          if (!isThisRequestStillValid()) return null;
          if (data.broadcasts && data.broadcasts.error) {
            this.error = data.broadcasts;
            this.broadcasts = [];
            this.pagination = {};
          } else {
            // TODO: decide if there's a new broadcast in the playlist and provide
            // some action so UI can respond and notify user
            this.error = null;
            this.broadcasts = data.broadcasts;
            this.pagination = data.pagination;
          }
        })
        .then(() => {
          this.loading = false;
          this.trigger();
        });
    },
    onPlaylistSearch: function (phrase) {
      logger.log('PlaylistStore#onPlaylistSearch', phrase);
      if (this.filter == phrase) {
        return; //no-op
      }
      this.filter = phrase;
      this.currentPage = 1;
      this.broadcasts = [];
      this.pagination = {};
      this.loading = true;
      this.onRefreshSelectedBroadcastMeta();
      this.trigger();
      ga('send', 'event', 'playlist', 'search', phrase);
    },
    onPlaylistPaginate: function (page) {
      logger.log('PlaylistStore#onPlaylistPaginate', page);
      if (page < 1) page = 1;
      if (page > this.pageCount()) page = this.pageCount();
      if (this.currentPage == page) {
        return; //no-op
      }
      this.currentPage = page;
      this.broadcasts = [];
      this.loading = true;
      this.onRefreshSelectedBroadcastMeta();
      this.trigger();
      ga('send', 'event', 'playlist', 'paginate', page);
    },

    // Private helper methods
    _getRelatedBroadcastsPromise: function () {
      if (API.hasPreloadAvailable('broadcasts')) {
        return API.fromPreload('broadcasts', true).then((result) => ({
          broadcasts: result.data,
          pagination: result.pagination,
        }));
      }

      var relatedBroadcastsPath = `/channels/${CurrentChannelStore.id}/broadcasts`;
      var userQueryOverride = UserArgsStore.relatedBroadcastsQuery;
      var query = {
        q: 'timeframe:relevant timeframe:next',
        s: '-starts_at',
        l: this.pageSize,
        p: this.currentPage - 1,
      };
      if (UserArgsStore.defaultVideo == 'closest') {
        query.q = 'timeframe:relevant'; //relevant handles 50/50 logic server-side
      }
      if (userQueryOverride) {
        // Allow overriding the related broadcasts
        if (UserArgsStore.apiAuthToken && userQueryOverride.showPrivate) {
          relatedBroadcastsPath = `/broadcasts`;
        } else if (userQueryOverride.channelId) {
          relatedBroadcastsPath = `/channels/${userQueryOverride.channelId}/broadcasts`;
        }
        query.q = userQueryOverride.q || query.q;
        query.s = userQueryOverride.s || query.s;

        // If we have tags, we want to use a different endpoint
        // This is probably some tech debt but hopefully we'll be revamping this all soon.
        if (userQueryOverride.tags && userQueryOverride.tags.length && userQueryOverride.channelId) {
          // Use our elastic search endpoint if we have tags and the channel to search in
          // Elasticsearch is powering our tagging capability
          // And if they use tags, let's use ES instead of our channels
          relatedBroadcastsPath = `/channels/${userQueryOverride.channelId}/broadcasts/_search`;

          // Override the existing query so we can use our ElasticSearch syntax instead
          // https://www.elastic.co/guide/en/elasticsearch/reference/current/search-search.html
          query['from'] = (this.currentPage - 1) * this.pageSize;
          query['size'] = this.pageSize;

          let q = '';
          userQueryOverride.tags.forEach((tag) => {
            q += `+tags:"${tag}" `;
          });
          query['q'] = q.trim();

          // Return from our ES endpoint now so we don't hit the other endpoint
          return API.get(relatedBroadcastsPath, query).then((response) => {
            // Fake a pagination response here, since ES response differently than our API
            const pagination = {
              total: response.total,
              next: query['from'],
              last: Math.ceil(response.total / this.pageSize),
            };

            return {
              broadcasts: response.results || [],
              pagination,
            };
          });
        }
      }
      if (this.filter) {
        query.q += ` ${textToQuery(this.filter)}`;
      }

      return API.getWithPagination(relatedBroadcastsPath, query).then((response) => {
        return {
          broadcasts: response.data,
          pagination: response.pagination,
        };
      });
    },
  });

function getSelectedBroadcast(broadcasts, args) {
  // Implement logic for selecting the optimal broadcast based on the list
  // of broadcasts returned from the API
  // 1. If only 1, select that.
  // 2. Else, if anything is Live, select the one with the earliest start time.
  // 3. Else, select the first returned (furthest in future).
  if (broadcasts.length == 1) {
    return broadcasts[0];
  }

  var live = broadcasts.filter((b) => timeframe(b) == 'current');
  if (live.length) {
    live.sort((a, b) => Clock.parse(a.starts_at) - Clock.parse(b.starts_at));
    return live[0];
  }
  if (args.defaultVideo == 'closest') {
    var closest,
      closestDiff = 0;
    broadcasts.forEach((b) => {
      var diff = Math.min(
        Math.abs(Clock.diff(Clock.parse(b.starts_at), false)),
        Math.abs(Clock.diff(Clock.parse(b.stops_at), false))
      );
      if (!closest || diff < closestDiff) {
        closest = b;
        closestDiff = diff;
      }
    });
    return closest;
  }

  return broadcasts[0];
}

// NOTE: At one point, we had all broadcasts in one big list (not server paginated)
// so we could look at them in memory to decide what might be a good "next" video to
// play after one ends.  Now this will be more complicated to implement.
/*
getNextSuggestedBroadcastAfter: function(currentBroadcast) {
  // Figure out what a good "next" broadcast would be when the currently selected
  // one has ended.
  // a) Prefer the newest (by start time) live event, assuming this is a widget
  //    used for a sermon series or live sporting tourney
  // b) Otherwise walk backwards chronologically assuming this is just a channel
  //    of recorded content.
  // c) If there's nothing else to view, return null
  if (!currentBroadcast) {
    return null;
  }
  if (this.broadcasts.length <= 1) {
    return null;
  }

  // Check for fresh live event
  var newestLive = null;
  this.broadcasts.forEach((b, i) => {
    if (b.id == currentBroadcast.id) return;
    if (!newestLive && timeframe(b) == 'current') {
      newestLive = b;
    }
  });
  if (newestLive) {
    return newestLive;
  }

  // Walk backward
  var currentIdx = 0;
  this.broadcasts.forEach((b, i) => {
    if (b.id == currentBroadcast.id) currentIdx = i;
  });
  if (currentIdx != this.broadcasts.length - 1) {
    return this.broadcasts[currentIdx + 1];
  } else {
    return null;
  }
},
*/
