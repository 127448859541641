// Helper mixin to automatically bind Reflux listeners to the appropriate
// Stores and Actions, and then unbind them when the component unmounts.
//
// This is necessary because our models are bound at runtime within the
// context of the component class, as opposed to being truly global like
// the standard Reflux examples
//
// Example usage:
//
//    React.createClass({
//       mixins: [RefluxModelListenerMixin],
//       refluxModelListeners: {
//         CurrentChannelStore: 'onChange',  //bind to store events with shorthand
//         Actions: [{                       //bind to actions with long form (on/trigger)
//          on: 'SelectBroadcast',
//          trigger: 'onSelectBroadcast'
//         }]
//       },
//       onChange: function() {
//       },
//       onSelectBroadcast: function() {
//       }
//    });
//
import Reflux from 'reflux';

var RefluxModelListenerMixin = {
  UNSAFE_componentWillMount: function () {
    var model = this.props.model || this.context.model;

    try {
      this.unsubscribeHandles = this.unsubscribeHandles || [];

      for (var key in this.refluxModelListeners) {
        if (this.refluxModelListeners.hasOwnProperty(key)) {
          var value = this.refluxModelListeners[key];
          if (key == 'Actions') {
            // For the special case of Actions, we can have a list of possible
            // triggers for multiple actions
            value.forEach((v) => {
              var handle = model.Actions[v.on].listen(this[v.trigger]);
              this.unsubscribeHandles.push(handle);
            });
          } else {
            var handle = model[key].listen(this[value]);
            this.unsubscribeHandles.push(handle);
          }
        }
      }
    } catch (e) {
      console.error('Error in RefluxModelListenerMixin', e);
    }
  },

  componentWillUnmount: function () {
    this.unsubscribeHandles.forEach((handle) => handle());
  },
};

export default RefluxModelListenerMixin;
