// Like the Watchdog, the PlayerAnalytics class keeps track of the
// Clappr Player events and reports back to the logging/analytics API
//
// NOTE: Clappr events are a bit odd; see this to start:
// https://github.com/clappr/clappr/issues/838

import platform from 'platform';
import Config from '../config';
import { Clock, Logger, MonotonicClock, Util, BrowserStorage, fetch } from '../utils';
import { normalizeError } from './clappr-helper';

const logger = Logger.getInstance('analytics');
const PLAYING_STATES = 'play'.split(' ');
const STOPPED_STATES = 'pause buffer idle stop complete error'.split(' ');
const TIME_REPORT_INTERVAL_MS = 60000;

export class PlayerAnalytics {
  constructor(player, model, env) {
    this.player = player;
    this.env = env;

    // Avoid circular reference by only storing relevant objects from model.
    this.Actions = model.Actions;
    this.SelectedBroadcastStore = model.SelectedBroadcastStore;
    this.UserArgsStore = model.UserArgsStore;
    this.CurrentChannelStore = model.CurrentChannelStore;
    this.GeoBlockStore = model.GeoBlockStore;

    this.lastReportAt = null;
    this.lastBufferStart = null;
    this.isPlaying = false;
    this.isBuffering = false;
    this.durationPlaying = 0;
    this.activeBufferingDuration = 0;
    this.totalDurationBuffering = 0;
    this.currentLevelHeight = 0;
    this.dvrInUse = false;
    this.headers = {};
    this.browser = {};
    this.position = 0;

    this._queue = [];
    this.attach();
  }

  attach() {
    let player = this.player;
    let playback = player.core.getCurrentPlayback();

    // Wire up events when problem is detected
    playback.on('playback:buffering', () => {
      this.handleBufferingStart();
    });
    playback.on('playback:play', (error) => {
      this.handleBufferingEnd();
    });
    playback.on('playback:error', (error) => {
      this.handlePlaybackError(error);
    });
    playback.on('playback:bitrate', (data) => {
      this.currentLevelHeight = data.height;
      this.report('quality');
    });
    playback.on('playback:dvr', (dvrInUse) => {
      this.dvrInUse = dvrInUse;
    });

    // Wire up events to detect that all is well
    player.on('timeupdate', () => {
      this.reportTime();
    });
    player.on('play', () => {
      this.handleNormalOperation();
      this.report('play');
      this.handleBufferingEnd();
    });
    player.on('seek', (t) => {
      this.handleNormalOperation();
      this.report('seek', { offset: t });
    });
    player.on('pause', () => {
      this.handleNormalOperation();
      this.report('pause');
      this.handleBufferingEnd();
    });
    player.on('stop', () => {
      this.handleNormalOperation();
      this.stoppedHACK = true;
      this.report('stop');
      this.handleBufferingEnd();
    });
    player.on('ended', () => {
      this.handleNormalOperation();
      this.report('complete');
      this.handleBufferingEnd();
    });
  }

  detach() {
    let player = this.player;
    let playback = this.player.core.getCurrentPlayback();

    playback.off('playback:buffering');
    playback.off('playback:error');
    playback.off('playback:bitrate');
    player.off('timeupdate');
    player.off('play');
    player.off('seek');
    player.off('pause');
    player.off('stop');
    player.off('ended');
  }

  handleBufferingStart() {
    this.isBuffering = true;
    this.lastBufferStart = this.lastBufferStart || MonotonicClock.now();
    this.report('buffer');
  }

  handleNormalOperation() {
    this.stoppedHACK = false;
  }

  handleBufferingEnd() {
    this.isBuffering = false;
    this.lastBufferStart = null;

    // When done buffering, accumulate the time since it started buffering and
    // reset the active buffering timer.
    this.totalDurationBuffering += this.activeBufferingDuration;
    this.activeBufferingDuration = 0;
  }

  handlePlaybackError(error) {
    if (this.stoppedHACK) {
      logger.warn(
        'An error occurred, but playback is stopped so this should not be a problem',
        error
      );
    } else if (error === null) {
      logger.warn('An error event was fired, but the error was null'); // Ugh, Firefox
    } else {
      const additionalFields = Util.mergeDict(this.browser, {
        error_object: normalizeError(error, this.player.options.source),
      });
      this.report('error', additionalFields);
    }
  }

  setup() {
    const { UserArgsStore } = this;

    this.browser = {
      user_agent: navigator.userAgent,
      language: navigator.language,
      platform: platform.product || '',
      browser_name: platform.name,
      browser_version: platform.version,
      os: (platform.os || '').toString(),
      player_version: this.env('version'),
      host: UserArgsStore.hostname || window.location.hostname,
    };

    this.headers = Util.mergeDict(
      {
        view_id: Util.uuid().replace(/-/g, ''),
        viewer_id:
          BrowserStorage.getItem('boxcast-viewer-id', null) ||
          BrowserStorage.setItem('boxcast-viewer-id', Util.uuid().replace(/-/g, '')),
      },
      this.playerInfo
    );

    this.durationPlaying = 0;
    this.isPlaying = false;
  }

  get playerInfo() {
    const { SelectedBroadcastStore, CurrentChannelStore, GeoBlockStore } = this;

    let s = SelectedBroadcastStore.view.status || '';
    let isLive = s.indexOf('live') >= 0 || s.indexOf('stalled') >= 0 || s.indexOf('prepared') >= 0;

    let info = {
      account_id: SelectedBroadcastStore.broadcast.account_id,
      is_live: isLive,
      channel_id: CurrentChannelStore.id,
      ticket_id: SelectedBroadcastStore.ticket || CurrentChannelStore.ticket || null,
    };
    if (SelectedBroadcastStore.selectedHighlight) {
      info.highlight_id = SelectedBroadcastStore.selectedHighlight.id;
    } else {
      info.broadcast_id = SelectedBroadcastStore.broadcast.id;
    }
    const lastGeoLoc = GeoBlockStore.lastFound();
    if (Object.keys(lastGeoLoc).length > 0) {
      info.location = {};
      for (let p in lastGeoLoc) {
        if (lastGeoLoc.hasOwnProperty(p)) {
          const v = lastGeoLoc[p];
          if (!!v) {
            info.location[p] = v;
          }
        }
      }
    }
    return info;
  }

  getCurrentLevelHeight() {
    // hls.js and flash would return this, but native (iOS/Safari) need to be
    // calculated from the <video> element
    // What about audio-only?
    return this.currentLevelHeight || (this.player.core.$el.find('video').get(0) || {}).videoHeight;
  }

  reportTime() {
    const { Actions } = this;
    if (!this.isSetup || !this.isPlaying) {
      return false;
    }
    let n = MonotonicClock.now();
    if (n - this.lastReportAt > TIME_REPORT_INTERVAL_MS) {
      this.report('time');
      Actions.ReportTimeUpdated(this.durationPlaying);
    } else {
      return false;
    }
  }

  report(action, options) {
    const { UserArgsStore, SelectedBroadcastStore } = this;

    if (!SelectedBroadcastStore.view.playlist) {
      // attached to customPreroll, which shouldn't have analytics in same sense as broadcast
      logger.log('View playlist not avaiable. Not reporting metrics.', action, options);
      return;
    }
    if (UserArgsStore.disableMetrics) {
      logger.log('Metrics are disabled. Not reporting metrics.', action, options);
      return;
    } else {
      logger.log('Reporting', action, options);
    }

    if (!this.isSetup) {
      this.setup();
      this.isSetup = true; //avoid infinite loop
      this.report('setup', this.browser);
    }

    // Accumulate the playing/buffering counters
    let n = MonotonicClock.now();
    if (this.isPlaying) {
      // Accumulate the playing counter stat between report intervals
      this.durationPlaying += n - (this.lastReportAt || n);
    }
    if (this.isBuffering) {
      // The active buffering stat is absolute (*not* accumulated between report intervals)
      this.activeBufferingDuration = n - (this.lastBufferStart || n);
    }
    this.isPlaying =
      PLAYING_STATES.indexOf(action) >= 0 ||
      (this.isPlaying && !(STOPPED_STATES.indexOf(action) >= 0));
    this.lastReportAt = n;

    let c = Clock.now();
    options = options || {};
    options = Util.mergeDict(this.headers, options);
    options.timestamp = c.toISOString();
    options.hour_of_day = c.getHours(); //hour-of-day in local time
    options.day_of_week = c.getDay();
    options.action = action;
    options.position = this.player.getCurrentTime();
    options.duration = Math.round(this.durationPlaying / 1000);
    options.duration_buffering = Math.round(
      (this.totalDurationBuffering + this.activeBufferingDuration) / 1000
    );
    options.videoHeight = this.getCurrentLevelHeight();
    options.dvr = this.dvrInUse;
    options.remote_ip = Config.get('clientRemoteIP');

    this._queue.push(options);
    this._dequeue();
  }

  _dequeue() {
    // TODO: post-process the current batch to consolidate as necessary

    let requeue = [];

    this._queue.forEach((options) => {
      fetch(this.env('metricsUrl'), {
        method: 'POST',
        // XXX: Attempting to send these headers causes CORS to only send
        // the OPTIONS but not follow through to the POST
        //headers: {
        //  'Accept': 'application/json',
        //  'Content-Type': 'application/json'
        //},
        body: JSON.stringify(options),
      }).catch((error) => {
        options.__attempts = (options.__attempts || 0) + 1;
        if (options.__attempts <= 5) {
          console.error('Unable to post metrics; will retry', error, options);
          requeue.push(options);
        } else {
          console.error('Unable to post metrics; will not retry', error, options);
        }
      });
    });

    // Add any messages that failed to try to resend on next batch
    this._queue = requeue;
  }
}
