import Reflux from 'reflux';

import Config from '../config';
import { BrowserStorage, Logger } from '../utils';

const PREVIEW_KEY = 'bx:prv';
const logger = Logger.getInstance('models');

export var FreePreviewStoreFactory = (Actions, SelectedBroadcastStore, UserArgsStore) =>
  Reflux.createStore({
    // Reflux keyword: automatically binds `on*` events to each action according to its name
    listenables: Actions,

    // Reflux keyword: constructor function
    init: function () {
      logger.log('FreePreviewStore#init()');
      this.maxPreviewTime = Config.dict.previewSeconds;
      if (UserArgsStore.previewSeconds && UserArgsStore.previewSeconds < this.maxPreviewTime) {
        this.maxPreviewTime = UserArgsStore.previewSeconds;
      }
    },

    // Public methods
    getTimeRemaining: function (broadcast) {
      let time = BrowserStorage.getFromRingBuffer(PREVIEW_KEY, '' + broadcast.id);
      if (time === undefined || time === null) {
        return this.maxPreviewTime;
      } else if (isNaN(time)) {
        // tampered with? reset to 0
        return 0;
      } else {
        return time;
      }
    },

    // Custom action handlers
    onPreviewVideoTimerTick: function (broadcast, seconds) {
      //logger.log('FreePreviewStore#onPreviewVideoTimerTick');
      seconds = typeof seconds == 'undefined' ? 1 : seconds;

      let time = this.getTimeRemaining(broadcast);
      // tick off a second only while video is actually playing
      if (SelectedBroadcastStore.playing) {
        time = Math.max(0, parseInt(time, 10) - seconds);
      }

      BrowserStorage.addToRingBuffer(
        PREVIEW_KEY,
        '' + broadcast.id,
        time,
        Config.dict.previewBufferSize
      );

      if (time <= 0) {
        Actions.PreviewExpired(broadcast);
      }
      this.trigger();
    },
  });
