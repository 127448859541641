import React from 'react';
import BroadcastListSkeleton from './broadcast-list-skeleton.jsx';
import BroadcastListItem from './broadcast-list-item.jsx';

export default function BroadcastList(props) {
  const { broadcasts, loading, pageSize, selectedBroadcast, onSelect, defaultPoster } = props;

  if (loading && broadcasts.length == 0) {
    return <BroadcastListSkeleton pageSize={pageSize} />;
  }

  return (
    <div className="boxcast-playlist-list">
      {broadcasts.length == 0 ? <em>No related broadcasts were found.</em> : null}
      <ul>
        {broadcasts.map((broadcast) => (
          <BroadcastListItem
            key={broadcast.id}
            broadcast={broadcast}
            selected={broadcast.id == selectedBroadcast.id}
            onSelect={onSelect}
            defaultPoster={defaultPoster}
          />
        ))}
      </ul>
    </div>
  );
}
