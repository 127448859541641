import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import Clock from '../../utils/clock';
import { ga } from '../../ga';
import RefluxModelListenerMixin from '../../mixins/reflux-model-listener-mixin';

import PdfDocument from './pdf-document.jsx';
import VideoIndex from './video-index.jsx';

export default createClass({
  displayName: 'DocumentsAndIndex',

  contextTypes: {
    model: PropTypes.object,
    m: PropTypes.func,
    env: PropTypes.func,
  },

  refluxModelListeners: {
    SelectedBroadcastStore: 'onStoreChanged',
  },

  mixins: [RefluxModelListenerMixin],

  getInitialState: function (fromRefresh) {
    const { SelectedBroadcastStore, UserArgsStore } = this.context.model;
    const cs = SelectedBroadcastStore.getContentSettings();
    var state = {
      cues: cs.cues || [],
      hasCues: (cs.cues || []).length,
      allowCues: UserArgsStore.showIndex,
      documents: SelectedBroadcastStore.getDocuments(),
      hasDocuments: SelectedBroadcastStore.getDocuments().length,
      allowDocuments: UserArgsStore.showDocuments,
      selectedDocument: null,
    };
    // Reconcile selected tab with visible options.
    // Try to show cues if we have them, unless user has explicitly switched to documents.
    if (!fromRefresh || !this.state.userTabPref) {
      state.cuesSelected = state.hasCues;
      state.documentsSelected = !state.hasCues;
    } else {
      if (!state.hasCues && this.state.cuesSelected) {
        state.cuesSelected = false;
      } else {
        state.cuesSelected = this.state.userTabPref == 'cue';
      }
      state.documentsSelected = !state.cuesSelected;
    }
    if (!state.allowCues) state.cuesSelected = false;
    if (!state.allowDocuments) state.documentsSelected = false;
    return state;
  },

  onStoreChanged: function () {
    this.setState(this.getInitialState(true));
  },

  render: function () {
    const { allowDocuments, allowCues, cuesSelected, hasDocuments, hasCues } = this.state;
    if (allowDocuments && hasDocuments  || allowCues && hasCues) {
      return (
        <div className="boxcast-playlist boxcast-well">
          {this.renderTitle()}
          {cuesSelected ? <VideoIndex cues={this.state.cues} /> : this.renderDocumentList()}
        </div>
      );
    } else {
      return <span />;
    }
  },

  renderTitle: function () {
    const {
      hasCues,
      hasDocuments,
      cuesSelected,
      documentsSelected,
      documents,
      allowCues,
      allowDocuments,
    } = this.state;
    var cuesTitle = this.context.m('cue_title');

    var documentsTitle = this.context.m('documents_title');
    if (documents.length == 1) {
      documentsTitle = documents[0].name || this.context.m('documents_title');
    }

    if (hasCues && hasDocuments && cuesSelected && allowCues && allowDocuments) {
      return (
        <div className="boxcast-well-title boxcast-tabbed">
          <h3 className="active">{cuesTitle}</h3>
          <h3 onClick={this.changeTabDocuments}>{documentsTitle}</h3>
          <br style={{ clear: 'both' }} />
        </div>
      );
    } else if (hasCues && hasDocuments && allowCues && allowDocuments) {
      return (
        <div className="boxcast-well-title boxcast-tabbed">
          <h3 onClick={this.changeTabCue}>{cuesTitle}</h3>
          <h3 className="active">{documentsTitle}</h3>
          <br style={{ clear: 'both' }} />
        </div>
      );
    } else if (allowCues && hasCues) {
      return (
        <div className="boxcast-well-title">
          <h3>{cuesTitle}</h3>
        </div>
      );
    } else if (allowDocuments && hasDocuments) {
      return (
        <div className="boxcast-well-title">
          <h3>{documentsTitle}</h3>
        </div>
      );
    } else {
      return <span />;
    }
  },

  renderDocumentList: function () {
    const { documents, selectedDocument } = this.state;
    if (documents.length == 1) {
      return <PdfDocument url={documents[0].url} />;
    } else if (selectedDocument) {
      return (
        <div>
          <p>
            <a href="#" onClick={(e) => this.showDocument(e, null)}>
              &lt; Back
            </a>
          </p>
          <PdfDocument url={selectedDocument.url} />
        </div>
      );
    }
    var idx = 0;
    var className = 'boxcast-playlist-item boxcast-selectable';
    return (
      <div className="boxcast-playlist-list">
        <ul>
          {documents.map((d) => (
            <li key={++idx} className={className} style={{ minHeight: 'auto' }}>
              <h3>
                <a href="#" onClick={(e) => this.showDocument(e, d)}>
                  {d.name}
                </a>
              </h3>
            </li>
          ))}
        </ul>
      </div>
    );
  },

  showDocument(e, d) {
    e.preventDefault();
    this.setState({ selectedDocument: d });
  },

  changeTabCue(e) {
    e.preventDefault();
    this.setState({
      documentsSelected: false,
      cuesSelected: true,
      userTabPref: 'cue',
    });
  },

  changeTabDocuments(e) {
    e.preventDefault();
    this.setState({
      documentsSelected: true,
      cuesSelected: false,
      userTabPref: 'documents',
    });
  },
});
